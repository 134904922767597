import React, { useEffect, useState } from "react";
import BasicTable from "./MUIBasicTable";
import Parse from "parse";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Clients = (props) => {
  const theme = useTheme();
	const colors = tokens(theme.palette.mode);
  const [clientsData, setClientsData] = useState([]);

  useEffect(() => {
      getQuotationsData();
  }, []);

  const getQuotationsData = async () => {
    const clientsObj = Parse.Object.extend("ClientDetails");
    const query = new Parse.Query(clientsObj).limit(100000);
    const res = await query.find();
    setClientsData(res);
    localStorage.setItem('clients', JSON.stringify(res));
  };

  return (
    <div>
      <Typography
              variant="h5"
              fontWeight="600"
              className="text-left"
              ml='1em'
              mb='1em'
              color={colors.greenAccent[400]}
              >
                Clients
                </Typography>
      <div className="d-flex justify-content-center align-items-center">
        <BasicTable data={clientsData} clients={true} />
      </div>
    </div>
  );
};

export default Clients;
