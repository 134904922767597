import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Parse from "parse";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const AddPalletRackingPart = () => {
  const [name, setName] = useState("");
  const [finish, setFinish] = useState("Galv");
  const [dimensions, setDimensions] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [price, setPrice] = useState(0);
  const [loadingCap, setLoadingCap] = useState("");
  const [weight, setWeight] = useState("");
	const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);


	const parts =
	// {name: "Frame", price: "135.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "25.65", description: "H 2438 * 838mm  90*70*2.0" },
	// {name: "Frame", price: "147.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "31.62", description: "H 3048 * 838mm  90*70*2.0"},
	// {name: "Frame", price: "156.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "37.58", description: "H 3658 * 838mm  90*70*2.0" },
	// {name: "Frame", price: "160.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "43.53", description: "H 4267 * 838mm  90*70*2.0" },
	// {name: "Frame", price: "195.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "49.5", description: "H 4877 * 838mm  90*70*2.0"},
	// {name: "Frame", price: "215.33", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "55.45", description: "H 5486 * 838mm  90*70*2.0"},
	// {name: "Frame", price: "235.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "61.4", description: "H 6096 * 838mm  90*70*2.0"},
	// {name: "Frame", price: "255.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "67.38", description: "H 6706* 838mm  90*70*2.0"},
	// {name: "Frame", price: "265.32", loadingCap: 'STD', dimension: "90*70*2.1", finish: "Galv", weight: "73.36", description: "H 7315* 838 mm  90*70*2.0"},
	// {name: "Frame", price: "155.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "27.29", description: "H 2438 * 1219mm  90*70*2.0" },
	// {name: "Frame", price: "167.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "33.55", description: "H 3048 * 1219mm  90*70*2.0"},
	// {name: "Frame", price: "175.88", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "39.82", description: "H 3658 * 1219mm  90*70*2.0" },
	// {name: "Frame", price: "182.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "46.08", description: "H 4267 * 1219mm  90*70*2.0" },
	// {name: "Frame", price: "211.32", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "52.34", description: "H 4877 * 1219mm  90*70*2.0"},
	// {name: "Frame", price: "238.83", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "58.6", description: "H 5486 * 1219mm  90*70*2.0"},
	// {name: "Frame", price: "256.91", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "64.86", description: "H 6096 * 1219mm  90*70*2.0"},
	// {name: "Frame", price: "275.11", loadingCap: 'STD', dimension: "90*70*2.0", finish: "Galv", weight: "71.13", description: "H 6706* 1219mm  90*70*2.0"},
	// {name: "Frame", price: "286.16", loadingCap: 'STD', dimension: "90*70*2.1", finish: "Galv", weight: "77.11", description: "H 7315* 1219mm  90*70*2.0"}
	// 	[
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"41.12",
	// 			 "loadingCap":"2000Kg/ level",
	// 			 "dimension":"80*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"7.80",
	// 			 "description":"L 1372mm W   80 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"56.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80*40*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 1524mm W 80 * 40* 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"59.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80*40*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 1829mm W 80*40*1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"61.31",
	// 			 "loadingCap":"",
	// 			 "dimension":"80*40*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2134mm W 80*40*1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"67.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80*40*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2438mm W 80*40*1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"65.32",
	// 			 "loadingCap":"2230Kg/ level",
	// 			 "dimension":"100*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"14.70",
	// 			 "description":"L 2591mm W   100 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"69.32",
	// 			 "loadingCap":"2600Kg/ level",
	// 			 "dimension":"110*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"15.52",
	// 			 "description":"L 2591mm W   110 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"73.35",
	// 			 "loadingCap":"3000Kg/ level",
	// 			 "dimension":"120*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"16.30",
	// 			 "description":"L 2591mm W   120 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"58.32",
	// 			 "loadingCap":"1200Kg/ level",
	// 			 "dimension":"80*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"13.50",
	// 			 "description":"L 2591mm W   80 * 50 * 1.5.0"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"71.32",
	// 			 "loadingCap":"2490Kg/ level",
	// 			 "dimension":"110*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"16.30",
	// 			 "description":"L 2743mm W  110* 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"73.65",
	// 			 "loadingCap":"3000Kg/ level",
	// 			 "dimension":"120*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"17.20",
	// 			 "description":"L 2743mm W  120* 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"86.37",
	// 			 "loadingCap":"",
	// 			 "dimension":"140*50*1.6",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2743mm W  140* 50 * 1.6"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"61.32",
	// 			 "loadingCap":"1150Kg/ level",
	// 			 "dimension":"80*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"14.15",
	// 			 "description":"L 2743mm W  80* 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"83.31",
	// 			 "loadingCap":"1350Kg/ level",
	// 			 "dimension":"100*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"17.10",
	// 			 "description":"L 3048mm W  100 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"112.32",
	// 			 "loadingCap":"2200Kg/ level",
	// 			 "dimension":"120*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"18.90",
	// 			 "description":"L 3048mm W  120 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"115.87",
	// 			 "loadingCap":"",
	// 			 "dimension":"120 x 50 * 1.5",
	// 			 "finish":"",
	// 			 "weight":"",
	// 			 "description":"L 3353mm W 120 x 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"119.36",
	// 			 "loadingCap":"2850Kg/ level",
	// 			 "dimension":"160*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"27.60",
	// 			 "description":"L 3658mm W  160 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"126.98",
	// 			 "loadingCap":"2490Kg/ level",
	// 			 "dimension":"160*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"28.70",
	// 			 "description":"L 3810mm W  160 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"131.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"140*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 3658mm W  140 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"135.36",
	// 			 "loadingCap":"",
	// 			 "dimension":"140*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 3810mm W  140 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"145.36",
	// 			 "loadingCap":"",
	// 			 "dimension":"140*50*1.5",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 4267mm  W140 * 50 * 1.5"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"43.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 1372mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"61.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2591mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"68.93",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2591mm W 100 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"69.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2743mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"73.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2743mm W 100 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"43.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"75 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 1372mm W 75 * 50 "
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"63.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Blue",
	// 			 "weight":"",
	// 			 "description":"L 2591mm  W 100 * 50 "
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"118.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"125 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2591mm  W 125 * 50 "
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"110.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2743mm  W 100 * 50 "
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"126.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"125 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2743mm  W 125 * 50 "
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"53.36",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 1372mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"57.87",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2591mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"59.36",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2591mm W 100 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"73.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"80 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2743mm W 80 * 50 Drop weld (Reverse Beam)"
	// 		},
	// 		{
	// 			 "name":"Beam",
	// 			 "price":"83.32",
	// 			 "loadingCap":"",
	// 			 "dimension":"100 * 50 ",
	// 			 "finish":"Galv",
	// 			 "weight":"",
	// 			 "description":"L 2743mm W 100 * 50 Drop weld (Reverse Beam)"
	// 		}
	//  ]

	// 	[
	//     {
	//        "name":"Support",
	//        "price":"6.67",
	//        "loadingCap":"750 kgs UDL",
	//        "dimension":"838",
	//        "finish":"Blue",
	//        "weight":"1.63",
	//        "description":"Pallet Bar Support  838mm"
	//     },
	//     {
	//       "name":"Support",
	//       "price":"8.32",
	//       "loadingCap":"650kgs UDL",
	//       "dimension":"1219",
	//       "finish":"Blue",
	//       "weight":"2.10",
	//       "description":"Pallet Bar Support 1219mm"
	//    },
	//    {
	//       "name":"Support",
	//       "price":"1.14",
	//       "loadingCap":"",
	//       "dimension":"80*50*1.5",
	//       "finish":"Blue",
	//       "weight":"0.06",
	//       "description":"Particle Board Retaining Clip (Z Clip) 50 mm"
	//    },
	//    {
	//       "name":"Support",
	//       "price":"1.13",
	//       "loadingCap":"",
	//       "dimension":"80*50*1.5",
	//       "finish":"Blue",
	//       "weight":"0.06",
	//       "description":"Particle Board Retaining Clip (Z Clip) 40 mm"
	//    }
	// ]

	// [
	// 	{
	// 		 "name":"Row Spacer",
	// 		 "price":"4.67",
	// 		 "loadingCap":"STD",
	// 		 "dimension":"50*30*1.5",
	// 		 "finish":"Blue",
	// 		 "weight":" 1.20 ",
	// 		 "description":"L 380mm W  w/o bolts & nuts"
	// 	},
	// 	{
	// 		"name":"Row Spacer",
	// 		"price":"6.38",
	// 		"loadingCap":"STD",
	// 		"dimension":"50*30*1.5",
	// 		"finish":"Blue",
	// 		"weight":" 1.40 ",
	// 		"description":"L 430mm W  w/o bolts & nuts"
	//  },
	//  {
	// 		"name":"Row Spacer",
	// 		"price":"8.32",
	// 		"loadingCap":"STD",
	// 		"dimension":"80*50*1.50*30*1.5",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"L 1219mm W  w/o bolts & nuts"
	//  }
	// ]

	// [
	// 	{
	// 		 "name":"Accessory",
	// 		 "price":"135.65",
	// 		 "loadingCap":"STD",
	// 		 "dimension":"1000mm L x 400mm H",
	// 		 "finish":"Blue",
	// 		 "weight":"30.80",
	// 		 "description":"Single Sided End Protector "
	// 	},
	// 	{
	// 		"name":"Accessory",
	// 		"price":"211.68",
	// 		"loadingCap":"STD",
	// 		"dimension":"2490mm L x 400mm H",
	// 		"finish":"Blue",
	// 		"weight":"45.62",
	// 		"description":"Double Sided End Protector "
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"21.32",
	// 		"loadingCap":"STD",
	// 		"dimension":"",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"Post protectors"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"16.32",
	// 		"loadingCap":"STD",
	// 		"dimension":"4",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"Standard footplate (Zinc) 4mm"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"23.32",
	// 		"loadingCap":"STD",
	// 		"dimension":"6",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"Heavy duty 90mm (Zinc) 6mm"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"28.36",
	// 		"loadingCap":"STD",
	// 		"dimension":"8",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"Extra heavy duty 90mm (HDG) 8mm"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"8.19",
	// 		"loadingCap":"STD",
	// 		"dimension":"",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"Dividing Pins"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"12.13",
	// 		"loadingCap":"STD",
	// 		"dimension":"",
	// 		"finish":"Blue",
	// 		"weight":"",
	// 		"description":"D Dividers"
	//  }
	// ]

	// [
	// 	{
	// 		 "name":"Mesh Deck",
	// 		 "price":"65.32",
	// 		 "loadingCap":"1000",
	// 		 "dimension":"1250 x 840",
	// 		 "finish":"Blue",
	// 		 "weight":"10.80",
	// 		 "description":"Single Sided End Protector "
	// 	},
	// 	{
	// 		"name":"Mesh Deck",
	// 		"price":"56.98",
	// 		"loadingCap":"600",
	// 		"dimension":"1250 x 840",
	// 		"finish":"Blue",
	// 		"weight":"9.70",
	// 		"description":"Double Sided End Protector "
	//  },
	//  {
	// 		"name":"Mesh Deck",
	// 		"price":"47.32",
	// 		"loadingCap":"300",
	// 		"dimension":"1250 x 840",
	// 		"finish":"Blue",
	// 		"weight":"8.60",
	// 		"description":"Post protectors"
	//  },
	//  {
	// 		"name":"Mesh Deck",
	// 		"price":"76.32",
	// 		"loadingCap":"1000",
	// 		"dimension":"1250 x 1219",
	// 		"finish":"Blue",
	// 		"weight":"15.00",
	// 		"description":"Standard footplate (Zinc) 4mm"
	//  },
	//  {
	// 		"name":"Mesh Deck",
	// 		"price":"63.87",
	// 		"loadingCap":"300",
	// 		"dimension":"1250 x 1219",
	// 		"finish":"Blue",
	// 		"weight":"12.80",
	// 		"description":"Heavy duty 90mm (Zinc) 6mm"
	//  }
	// ]

	// [
	// 	{
	// 		 "name":"Accessory",
	// 		 "price":"1.41",
	// 		 "loadingCap":"M12 x 75",
	// 		 "dimension":"",
	// 		 "finish":"Zinc",
	// 		 "weight":"1.60",
	// 		 "description":"Dyna Bolts"
	// 	},
	// 	{
	// 		"name":"Accessory",
	// 		"price":"2.31",
	// 		"loadingCap":"M12 x 75",
	// 		"dimension":"",
	// 		"finish":"Galv",
	// 		"weight":"1.60",
	// 		"description":"Dyna Bolts"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"1.10",
	// 		"loadingCap":"STD",
	// 		"dimension":"",
	// 		"finish":"Zinc",
	// 		"weight":"0.23",
	// 		"description":"Levelling Shims"
	//  },
	//  {
	// 		"name":"Accessory",
	// 		"price":"0.85",
	// 		"loadingCap":"STD",
	// 		"dimension":"",
	// 		"finish":"Zinc",
	// 		"weight":"0.10",
	// 		"description":"Beam Safety Clips "
	//  }
	// ]

	// [
	// 	{
	// 	name: "Frame",
	// 	price: "135.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "25.65",
	// 	description: "H 2438 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "147.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "31.62",
	// 	description: "H 3048 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "156.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "37.58",
	// 	description: "H 3658 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "160.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "43.53",
	// 	description: "H 4267 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "195.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "49.5",
	// 	description: "H 4877 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "215.33",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "55.45",
	// 	description: "H 5486 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "235.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "61.4",
	// 	description: "H 6096 * 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "255.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "67.38",
	// 	description: "H 6706* 838mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "265.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.1",
	// 	finish: "Galv",
	// 	weight: "73.36",
	// 	description: "H 7315* 838 mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "155.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "27.29",
	// 	description: "H 2438 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "167.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "33.55",
	// 	description: "H 3048 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "175.88",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "39.82",
	// 	description: "H 3658 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "182.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "46.08",
	// 	description: "H 4267 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "211.32",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "52.34",
	// 	description: "H 4877 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "238.83",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "58.6",
	// 	description: "H 5486 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "256.91",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "64.86",
	// 	description: "H 6096 * 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "275.11",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.0",
	// 	finish: "Galv",
	// 	weight: "71.13",
	// 	description: "H 6706* 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Frame",
	// 	price: "286.16",
	// 	loadingCap: "STD",
	// 	dimension: "90*70*2.1",
	// 	finish: "Galv",
	// 	weight: "77.11",
	// 	description: "H 7315* 1219mm  90*70*2.0",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "41.12",
	// 	loadingCap: "2000Kg/ level",
	// 	dimension: "80*50*1.5",
	// 	finish: "Blue",
	// 	weight: "7.80",
	// 	description: "L 1372mm W   80 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "56.32",
	// 	loadingCap: "",
	// 	dimension: "80*40*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 1524mm W 80 * 40* 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "59.32",
	// 	loadingCap: "",
	// 	dimension: "80*40*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 1829mm W 80*40*1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "61.31",
	// 	loadingCap: "",
	// 	dimension: "80*40*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2134mm W 80*40*1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "67.32",
	// 	loadingCap: "",
	// 	dimension: "80*40*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2438mm W 80*40*1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "65.32",
	// 	loadingCap: "2230Kg/ level",
	// 	dimension: "100*50*1.5",
	// 	finish: "Blue",
	// 	weight: "14.70",
	// 	description: "L 2591mm W   100 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "69.32",
	// 	loadingCap: "2600Kg/ level",
	// 	dimension: "110*50*1.5",
	// 	finish: "Blue",
	// 	weight: "15.52",
	// 	description: "L 2591mm W   110 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "73.35",
	// 	loadingCap: "3000Kg/ level",
	// 	dimension: "120*50*1.5",
	// 	finish: "Blue",
	// 	weight: "16.30",
	// 	description: "L 2591mm W   120 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "58.32",
	// 	loadingCap: "1200Kg/ level",
	// 	dimension: "80*50*1.5",
	// 	finish: "Blue",
	// 	weight: "13.50",
	// 	description: "L 2591mm W   80 * 50 * 1.5.0",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "71.32",
	// 	loadingCap: "2490Kg/ level",
	// 	dimension: "110*50*1.5",
	// 	finish: "Blue",
	// 	weight: "16.30",
	// 	description: "L 2743mm W  110* 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "73.65",
	// 	loadingCap: "3000Kg/ level",
	// 	dimension: "120*50*1.5",
	// 	finish: "Blue",
	// 	weight: "17.20",
	// 	description: "L 2743mm W  120* 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "86.37",
	// 	loadingCap: "",
	// 	dimension: "140*50*1.6",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2743mm W  140* 50 * 1.6",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "61.32",
	// 	loadingCap: "1150Kg/ level",
	// 	dimension: "80*50*1.5",
	// 	finish: "Blue",
	// 	weight: "14.15",
	// 	description: "L 2743mm W  80* 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "83.31",
	// 	loadingCap: "1350Kg/ level",
	// 	dimension: "100*50*1.5",
	// 	finish: "Blue",
	// 	weight: "17.10",
	// 	description: "L 3048mm W  100 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "112.32",
	// 	loadingCap: "2200Kg/ level",
	// 	dimension: "120*50*1.5",
	// 	finish: "Blue",
	// 	weight: "18.90",
	// 	description: "L 3048mm W  120 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "115.87",
	// 	loadingCap: "",
	// 	dimension: "120 x 50 * 1.5",
	// 	finish: "",
	// 	weight: "",
	// 	description: "L 3353mm W 120 x 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "119.36",
	// 	loadingCap: "2850Kg/ level",
	// 	dimension: "160*50*1.5",
	// 	finish: "Blue",
	// 	weight: "27.60",
	// 	description: "L 3658mm W  160 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "126.98",
	// 	loadingCap: "2490Kg/ level",
	// 	dimension: "160*50*1.5",
	// 	finish: "Blue",
	// 	weight: "28.70",
	// 	description: "L 3810mm W  160 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "131.32",
	// 	loadingCap: "",
	// 	dimension: "140*50*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 3658mm W  140 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "135.36",
	// 	loadingCap: "",
	// 	dimension: "140*50*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 3810mm W  140 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "145.36",
	// 	loadingCap: "",
	// 	dimension: "140*50*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 4267mm  W140 * 50 * 1.5",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "43.32",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 1372mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "61.32",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2591mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "68.93",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2591mm W 100 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "69.32",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2743mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "73.32",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2743mm W 100 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "43.32",
	// 	loadingCap: "",
	// 	dimension: "75 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 1372mm W 75 * 50 ",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "63.32",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 2591mm  W 100 * 50 ",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "118.32",
	// 	loadingCap: "",
	// 	dimension: "125 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2591mm  W 125 * 50 ",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "110.32",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2743mm  W 100 * 50 ",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "126.32",
	// 	loadingCap: "",
	// 	dimension: "125 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2743mm  W 125 * 50 ",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "53.36",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 1372mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "57.87",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2591mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "59.36",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2591mm W 100 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "73.32",
	// 	loadingCap: "",
	// 	dimension: "80 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2743mm W 80 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Beam",
	// 	price: "83.32",
	// 	loadingCap: "",
	// 	dimension: "100 * 50 ",
	// 	finish: "Galv",
	// 	weight: "",
	// 	description: "L 2743mm W 100 * 50 Drop weld (Reverse Beam)",
	// 	},
	// 	{
	// 	name: "Support",
	// 	price: "6.67",
	// 	loadingCap: "750 kgs UDL",
	// 	dimension: "838",
	// 	finish: "Blue",
	// 	weight: "1.63",
	// 	description: "Pallet Bar Support  838mm",
	// 	},
	// 	{
	// 	name: "Support",
	// 	price: "8.32",
	// 	loadingCap: "650kgs UDL",
	// 	dimension: "1219",
	// 	finish: "Blue",
	// 	weight: "2.10",
	// 	description: "Pallet Bar Support 1219mm",
	// 	},
	// 	{
	// 	name: "Support",
	// 	price: "1.14",
	// 	loadingCap: "",
	// 	dimension: "80*50*1.5",
	// 	finish: "Blue",
	// 	weight: "0.06",
	// 	description: "Particle Board Retaining Clip (Z Clip) 50 mm",
	// 	},
	// 	{
	// 	name: "Support",
	// 	price: "1.13",
	// 	loadingCap: "",
	// 	dimension: "80*50*1.5",
	// 	finish: "Blue",
	// 	weight: "0.06",
	// 	description: "Particle Board Retaining Clip (Z Clip) 40 mm",
	// 	},
	// 	{
	// 	name: "Row Spacer",
	// 	price: "4.67",
	// 	loadingCap: "STD",
	// 	dimension: "50*30*1.5",
	// 	finish: "Blue",
	// 	weight: " 1.20 ",
	// 	description: "L 380mm W  w/o bolts & nuts",
	// 	},
	// 	{
	// 	name: "Row Spacer",
	// 	price: "6.38",
	// 	loadingCap: "STD",
	// 	dimension: "50*30*1.5",
	// 	finish: "Blue",
	// 	weight: " 1.40 ",
	// 	description: "L 430mm W  w/o bolts & nuts",
	// 	},
	// 	{
	// 	name: "Row Spacer",
	// 	price: "8.32",
	// 	loadingCap: "STD",
	// 	dimension: "80*50*1.50*30*1.5",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "L 1219mm W  w/o bolts & nuts",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "135.65",
	// 	loadingCap: "STD",
	// 	dimension: "1000mm L x 400mm H",
	// 	finish: "Blue",
	// 	weight: "30.80",
	// 	description: "Single Sided End Protector ",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "211.68",
	// 	loadingCap: "STD",
	// 	dimension: "2490mm L x 400mm H",
	// 	finish: "Blue",
	// 	weight: "45.62",
	// 	description: "Double Sided End Protector ",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "21.32",
	// 	loadingCap: "STD",
	// 	dimension: "",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "Post protectors",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "16.32",
	// 	loadingCap: "STD",
	// 	dimension: "4",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "Standard footplate (Zinc) 4mm",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "23.32",
	// 	loadingCap: "STD",
	// 	dimension: "6",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "Heavy duty 90mm (Zinc) 6mm",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "28.36",
	// 	loadingCap: "STD",
	// 	dimension: "8",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "Extra heavy duty 90mm (HDG) 8mm",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "8.19",
	// 	loadingCap: "STD",
	// 	dimension: "",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "Dividing Pins",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "12.13",
	// 	loadingCap: "STD",
	// 	dimension: "",
	// 	finish: "Blue",
	// 	weight: "",
	// 	description: "D Dividers",
	// 	},
	// 	{
	// 	name: "Mesh Deck",
	// 	price: "65.32",
	// 	loadingCap: "1000",
	// 	dimension: "1250 x 840",
	// 	finish: "Blue",
	// 	weight: "10.80",
	// 	description: "Single Sided End Protector ",
	// 	},
	// 	{
	// 	name: "Mesh Deck",
	// 	price: "56.98",
	// 	loadingCap: "600",
	// 	dimension: "1250 x 840",
	// 	finish: "Blue",
	// 	weight: "9.70",
	// 	description: "Double Sided End Protector ",
	// 	},
	// 	{
	// 	name: "Mesh Deck",
	// 	price: "47.32",
	// 	loadingCap: "300",
	// 	dimension: "1250 x 840",
	// 	finish: "Blue",
	// 	weight: "8.60",
	// 	description: "Post protectors",
	// 	},
	// 	{
	// 	name: "Mesh Deck",
	// 	price: "76.32",
	// 	loadingCap: "1000",
	// 	dimension: "1250 x 1219",
	// 	finish: "Blue",
	// 	weight: "15.00",
	// 	description: "Standard footplate (Zinc) 4mm",
	// 	},
	// 	{
	// 	name: "Mesh Deck",
	// 	price: "63.87",
	// 	loadingCap: "300",
	// 	dimension: "1250 x 1219",
	// 	finish: "Blue",
	// 	weight: "12.80",
	// 	description: "Heavy duty 90mm (Zinc) 6mm",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "1.41",
	// 	loadingCap: "M12 x 75",
	// 	dimension: "",
	// 	finish: "Zinc",
	// 	weight: "1.60",
	// 	description: "Dyna Bolts",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "2.31",
	// 	loadingCap: "M12 x 75",
	// 	dimension: "",
	// 	finish: "Galv",
	// 	weight: "1.60",
	// 	description: "Dyna Bolts",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "1.10",
	// 	loadingCap: "STD",
	// 	dimension: "",
	// 	finish: "Zinc",
	// 	weight: "0.23",
	// 	description: "Levelling Shims",
	// 	},
	// 	{
	// 	name: "Accessory",
	// 	price: "0.85",
	// 	loadingCap: "STD",
	// 	dimension: "",
	// 	finish: "Zinc",
	// 	weight: "0.10",
	// 	description: "Beam Safety Clips ",
	// 	},
	// ];
  [
		// {
		// 	name: "Support",
		// 	price: "6.67",
		// 	loadingCap: "750 kgs UDL",
		// 	dimension: "838",
		// 	finish: "Zinc",
		// 	weight: "1.63",
		// 	description: "Board Support Bar with Retainer  838mm",
		// },
		// {
		// 	name: "Support",
		// 	price: "8.32",
		// 	loadingCap: "650kgs UDL",
		// 	dimension: "1219",
		// 	finish: "Zinc",
		// 	weight: "2.10 ",
		// 	description: "Board Support Bar wiith Retainer 1219mm",
		// },
		// {
		// 	name: "Support",
		// 	price: "",
		// 	loadingCap: "",
		// 	dimension: "838",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "HD Support Bar",
		// },
		// {
		// 	name: "Support",
		// 	price: "",
		// 	loadingCap: "",
		// 	dimension: "1219",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "HD Support Bar",
		// },
		// {
		// 	name: "Support",
		// 	price: "1.13",
		// 	loadingCap: "",
		// 	dimension: "1219",
		// 	finish: "Zinc",
		// 	weight: "0.06",
		// 	description: "Particle Board Retaining Clip (Z Clip) 40 mm",
		// },

		// {
		// 	name: "Row Spacer",
		// 	price: "7.53",
		// 	loadingCap: "STD",
		// 	dimension: "50*30*1.5",
		// 	finish: "Blue",
		// 	weight: " ",
		// 	description: "Stand Off Spacer 250/300mm",
		// },
		// {
		// 	name: "Row Spacer",
		// 	price: "15.07",
		// 	loadingCap: "STD",
		// 	dimension: "50*30*1.5",
		// 	finish: "Blue",
		// 	weight: " ",
		// 	description: "Stand Off Spacer 838mm",
		// },
		// {
		// 	name: "Row Spacer",
		// 	price: "20.37",
		// 	loadingCap: "STD",
		// 	dimension: "50*30*1.5",
		// 	finish: "Blue",
		// 	weight: " ",
		// 	description: "Stand Off Spacer 1219mm ",
		// },


		// {
		// 	name: "Accessory",
		// 	price: "0.85",
		// 	loadingCap: "",
		// 	dimension: "400mm high ",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Corner Guard",
		// },
		// {
		// 	name: "Accessory",
		// 	price: "16.32",
		// 	loadingCap: "",
		// 	dimension: "4",
		// 	finish: "Zinc",
		// 	weight: "",
		// 	description: "Standard footplate (Zinc) 4mm 2 Bolts",
		// },
		// {
		// 	name: "Accessory",
		// 	price: "16.25",
		// 	loadingCap: "",
		// 	dimension: "Dexion Type",
		// 	finish: "Zinc",
		// 	weight: "",
		// 	description: "Splice Kit 90mm Post With Nuts Bolts & Tubes",
		// },
		// {
		// 	name: "Accessory",
		// 	price: "24.00",
		// 	loadingCap: "",
		// 	dimension: "838mm",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Fork Entry Bars",
		// },
		// {
		// 	name: "Accessory",
		// 	price: "33.60",
		// 	loadingCap: "",
		// 	dimension: "1219mm",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Fork Entry Bars",
		// },

		// {
		// 	name: "Mesh Deck",
		// 	price: "65.32",
		// 	loadingCap: "1000",
		// 	dimension: "1350 x 840",
		// 	finish: "Zinc",
		// 	weight: "10.80",
		// 	description: "Mesh Deck 1350x840 with 4 Supports",
		// },
		// {
		// 	name: "Mesh Deck",
		// 	price: "65.32",
		// 	loadingCap: "600",
		// 	dimension: "1350 x 840",
		// 	finish: "Zinc",
		// 	weight: "9.70",
		// 	description: "Mesh Deck 1350x840 with 3 Supports",
		// },

		// {
		// 	name: "Accessory",
		// 	price: "1.10",
		// 	loadingCap: "1.5mm",
		// 	dimension: "",
		// 	finish: "Zinc",
		// 	weight: "0.23",
		// 	description: "Levelling Shims",
		// },
		// {
		// 	name: "Accessory",
		// 	price: "2.10",
		// 	loadingCap: "3mm",
		// 	dimension: "",
		// 	finish: "Zinc",
		// 	weight: "0.23",
		// 	description: "Levelling Shims",
		// },


		// {
		// 	name: "Support",
		// 	price: "1.14",
		// 	loadingCap: "18-19mm",
		// 	dimension: "",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Particle Board 2570 x 835 NEW",
		// },

		// {
		// 	name: "Support",
		// 	price: "1.14",
		// 	loadingCap: "18-19mm",
		// 	dimension: "",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Particle Board 2720 x 835 NEW",
		// },

		// {
		// 	name: "Support",
		// 	price: "1.14",
		// 	loadingCap: "18-19mm",
		// 	dimension: "",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Particle Board 2570 x 835 NEW Seconds",
		// },

		// {
		// 	name: "Support",
		// 	price: "1.14",
		// 	loadingCap: "18-19mm",
		// 	dimension: "",
		// 	finish: "Blue",
		// 	weight: "",
		// 	description: "Particle Board 2720 x 835 NEW Seconds",
		// },

		// {
		// 	name: "Accessory",
		// 	price: "40.00",
		// 	loadingCap: "800 x 850mm x 4mm",
		// 	dimension: "",
		// 	finish: "Corflute",
		// 	weight: "",
		// 	description: "Pallet Rack Load Sign",
		// },

		{
			name: "Misc",
			price: "",
			loadingCap: "",
			dimension: "",
			finish: "",
			weight: "",
			description: "Scissor Lift Lite",
		},
		{
			name: "Misc",
			price: "",
			loadingCap: "",
			dimension: "",
			finish: "",
			weight: "",
			description: "Cage Hire",
		},
		{
			name: "Misc",
			price: "",
			loadingCap: "",
			dimension: "",
			finish: "",
			weight: "",
			description: "Fork Lift Hire",
		}
	]

	const saveParts = async () => {
		parts.map(async (item) => {
			const CantileverPart = Parse.Object.extend("PalletRackingParts");
			const part = new CantileverPart();
			part.set("name", item.name);
			part.set("finish", item.finish);
			part.set("dimensions", item.dimension);
			part.set("loadingCap", item.loadingCap);
			part.set("description", item.description);
			part.set("price", item.price);
			part.set("weight", item.weight);
			part.set("quantity", 20);
			try {
				await part.save();
			}
			catch(e) {
			}
		})
	}

  const handleFinishChange = (event) => {
    setFinish(event.target.value);
  };

  const handleDutyChange = (event) => {
    setFinish(event.target.value);
  };

  const submitPart = async () => {
		setIsLoading(true);
    const CantileverPart = Parse.Object.extend("PalletRackingParts");
    const part = new CantileverPart();
    part.set("name", name);
    part.set("finish", finish);
    part.set("dimensions", dimensions);
    part.set("loadingCap", loadingCap);
    part.set("description", description);
    part.set("price", price);
    part.set("weight", weight);
    part.set("quantity", parseInt(quantity));
    try {
			await part.save();
			setIsLoading(false);
			setFinish("Galv");
			setPrice(0);
			setWeight("");
			setQuantity(20);
			toast.success('Stock Item Successfully Saved')
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
  };

  return (
    <>
			<ToastContainer />
      <div className="text-center mb-3">
      </div>
      <div className="d-flex">
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Name</label>
					<input value={name} onChange={(e) => setName(e.target.value)}></input>
				</div>
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">price</label>
					<input
						type="number"
						value={price}
						onChange={(e) => setPrice(e.target.value)}
					></input>
				</div>
			</div>
      
			<div className="d-flex">
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">dimensions</label>
								<input value={dimensions} onChange={(e) => setDimensions(e.target.value)}></input>
				</div>
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">description</label>
					<input value={description} onChange={(e) => setDescription(e.target.value)}></input>
				</div>
			</div>


      <div className="d-flex">
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1">loadingCap</label>
								<input value={loadingCap} onChange={(e) => setLoadingCap(e.target.value)}></input>
							</div>
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1">
									Weight
								</label>
								<input value={weight} onChange={(e) => setWeight(e.target.value)}></input>
							</div>
			</div>

			<div className="d-flex">
				{/* <div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1 my-3">
									Part Image
								</label>
								<input
					type="file"
					id="imageFile"
					name='imageFile'
					onChange={imageUpload} />
				</div> */}
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Quantity</label>
					<input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)}></input>
				</div>
			</div>

      <div className="d-flex">
				{/* <div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1 my-4">Duty</label>
								<FormControl style={{width: '20%'}} className=" my-4 text-center">
									<InputLabel id="demo-simple-select-label">Duty</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={duty}
										label="Finish"
										onChange={handleDutyChange}
									>
										<MenuItem value={"Light"}>Light</MenuItem>
										<MenuItem value={"Medium"}>Medium</MenuItem>
										<MenuItem value={"Heavy"}>Heavy</MenuItem>
									</Select>
								</FormControl>
							</div> */}
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1 my-4">Finish</label>
								<FormControl style={{width: '20%'}} className=" my-4 text-center">
									<InputLabel id="demo-simple-select-label">Finish</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={finish}
										label="Finish"
										onChange={handleFinishChange}
									>
										<MenuItem value={"Blue"}>Powder Coated</MenuItem>
										<MenuItem value={"Galv"}>Hot dip Galvanized</MenuItem>
									</Select>
								</FormControl>
							</div>
			</div>

      <div className="text-center">
        <button
          className="btn btn-primary p-3"
          disabled={
            name.length === 0 ||
            finish.length === 0 ||
            dimensions.length === 0 ||
            price.length === 0 ||
            weight.length === 0 ||
            quantity.length === 0
          }
          onClick={() => submitPart()}
        >
          {!isLoading ? 'Save' : <Spinner animation="border" role="status" size='sm' variant="primary"/>}
        </button>
				<button onClick={() => saveParts()}>SAVE</button>
      </div>
    </>
  );
};

export default AddPalletRackingPart;
