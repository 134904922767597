export const FRAMES = {

    "3658": [
      {name: "Upright", dims: "3658", quantity: 2}, 
      {name: "H bracing", dims: "758", quantity: 3}, 
      {name: "D bracing", dims:	"980", quantity: 5}, 
      {name: "Base plate",	dims: "130*155", quantity: 2, finish: ["Galv", "Blue"]}, 
      {name: "Bolt & Nut",	dims: "M10 x 65", quantity: 10, finish: ["Galv", "Zinc"]}, 
      {name: "Bolt & Nut",	dims: "M10 x 25", quantity: 8, finish: ["Galv", "Zinc"]}, 
      {name: "Dyna bolt", dims: "M10 x 80", quantity: 2, finish: ["Galv", "Zinc"]}, 
      {name: "Bracing block", dims: "Φ14*46", quantity: 4},
    ],
    
    "4267": [
      {name: "Upright", dims: "4267", quantity: 2},
      {name: "H bracing", dims: "758", quantity: 3},
      {name: "D bracing", dims:	"980", quantity: 6},
      {name: "Base plate",	dims: "130*155", quantity: 2, finish: ["Galv", "Blue"]},
      {name: "Bolt & Nut",	dims: "M10 x 65", quantity: 11, finish: ["Galv", "Zinc"]},
      {name: "Bolt & Nut",	dims: "M10 x 25", quantity: 8, finish: ["Galv", "Zinc"]},
      {name: "Dyna bolt", dims: "M10 x 80", quantity: 2, finish: ["Galv", "Zinc"]},
      {name: "Bracing block", dims: "Φ14*46", quantity: 4},
    ],
    
    "4877":	[
      {name: "Upright", dims: "4877", quantity: 2},
      {name: "H bracing", dims: "758", quantity: 3},
      {name: "D bracing", dims:	"980", quantity: 7},
      {name: "Base plate",	dims: "130*155", quantity: 2, finish: ["Galv", "Blue"]},
      {name: "Bolt & Nut",	dims: "M10 x 65", quantity: 12, finish: ["Galv", "Zinc"]},
      {name: "Bolt & Nut",	dims: "M10 x 25", quantity: 8, finish: ["Galv", "Zinc"]},
      {name: "Dyna bolt", dims: "M10 x 80", quantity: 2, finish: ["Galv", "Zinc"]},
      {name: "Bracing block", dims: "Φ14*46", quantity: 4},
    ],
    
    "6096": [
      {name: "Upright", dims: "6096", quantity: 2},
      {name: "H bracing", dims: "758", quantity: 3},
      {name: "D bracing", dims:	"980", quantity: 9},
      {name: "Base plate",	dims: "130*155", quantity: 2, finish: ["Galv", "Blue"]},
      {name: "Bolt & Nut",	dims: "M10 x 65", quantity: 14, finish: ["Galv", "Zinc"]},
      {name: "Bolt & Nut",	dims: "M10 x 25", quantity: 8, finish: ["Galv", "Zinc"]},
      {name: "Dyna bolt", dims: "M10 x 80", quantity: 4, finish: ["Galv", "Zinc"]},
      {name: "Bracing block", dims: "Φ14*46", quantity: 4},
    ],
    
    }