import { useNavigate, useParams } from "react-router-dom";
import Parse from "parse";
import { useEffect, useRef, useState } from "react";
import { Box, FormControl, MenuItem, Select, useTheme, Typography, InputBase, Grid } from "@mui/material";
import { tokens } from "../theme";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import SimpleAccordion from "./AccordianComponent";
import DownloadQuotation from "./DownloadQuotationDocument";
import Tab from "./TabComponent";
import { Spinner } from "react-bootstrap";
import Picklist from "./PickListDocument";
import { ParseObject } from "./ExistingQuotation";
import backArrowGreen from '../assets/images/arrowGreen.png'
import { addToCart, addToMiscCartItems, patchQuotationNumber, setRevisionInProgress, setRevisionQuotation, setSelectedClient, setSelectedPercentageToWin, setSelectedQuotationType } from "../features/cart/cartSlice";

export const quotationStatuses = ['in-process', 'open', 'won', 'hold', 'deposit', 'cancelled',];
export const quotationStatusesOperations = ['open', 'won', 'hold', 'deposit', 'cancelled', 'confirm'];


const QuotationItemDetailComponent = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { id } = useParams();
  const [quotation, setQuotation] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(1);
  const [totalPriceRelativeToSection, setTotalPriceRelativeToSection] = useState({});
  const [totalPriceRelativeToSectionQuotation, setTotalPriceRelativeToSectionQuotation] = useState({});
  const [quotationRevisionSections, setQuotationRevisionSections] = useState([]);
  const [quotationRevisionParts, setQuotationRevisionParts] = useState();
  const [quotationRevisionMiscParts, setQuotationRevisionMiscParts] = useState();
  const [latestQuotationRevisionNumber, setLatestQuotationRevisionNumber] = useState("");
  const [quotationRevisions, setQuotationRevisions] = useState([]);
  const [marginEnabled, setMarginEnabled] = useState({1: false});
  const [discountEnabled, setDiscountEnabled] = useState({1: false});
  const [quotationStatus, setQuotationStatus] = useState("");
  const [quotationStatusSpinning, setQuotationStatusSpinning] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [quotationComments, setQuotationComments] = useState();
  const [quotaitonSections, setQuotationSections] = useState([]);
  const [savingComments, setSavingComments] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState({ 1: '' });
  const [marginPercentage, setMarginPercentage] = useState({ 1: '' });
  const [sections, setSections] = useState([]);
  const [totalDiscountedPriceRelativeToSectionQuotation, setTotalDiscountedPriceRelativeToSectionQuotation] = useState({});
  const [totalMarginalizedPriceRelativeToSectionQuotation, setTotalMarginalizedPriceRelativeToSectionQuotation] = useState({});
  const dispatch = useDispatch();
  const [revisionFinalized, setRevisionFinalized] = useState(false);
  const navigate = useNavigate();
  const revisionRef = useRef(null);

  useEffect(() => {
    getQuotationItemParseObject(id);
  }, [id]);

  useEffect(() => {
    // console.log('Delillah was here: ', quotation?.get('quotationParts'));
    getQuotationRevisions();
    const currentUser = Parse.User.current();
    setCurrentUser(currentUser);
  }, [quotation]);

  const scrollToRevisions = () => {
    revisionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const totalPricePerSection = {};
    if (quotation) {
      setQuotationSections(quotation.get('sections') ?? []);
      quotation.get('quotationParts').map((part) => {
          return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.price * part.quantity) : (part.price * part.quantity)
      })
      quotation.get('miscParts').map((part) => {
        return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.part.price * part.quantity) : (part.part.price * part.quantity)
      })
      setTotalPriceRelativeToSectionQuotation(totalPricePerSection);
      if (quotation.get('quotationMargin') === undefined || quotation.get('quotationMargin') === null || quotation.get('quotationMargin') === '') {
      }
      else {
        const totalMarginPricePerSection = {};
        Object.keys(totalPriceRelativeToSection).map((k) => {
          return k === activeSection ? totalMarginPricePerSection[k] = (totalPriceRelativeToSection[k] + (totalPriceRelativeToSection[k] / 100) * (quotation.get('quotationMargin'))) : totalMarginPricePerSection[k] = totalPriceRelativeToSection[k]
        });
        totalMarginPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] + (totalPriceRelativeToSection[activeSection] / 100) * (quotation.get('quotationMargin')[activeSection]))
        setTotalMarginalizedPriceRelativeToSectionQuotation(totalMarginPricePerSection);
      }
      if (quotation.get('quotationDiscount') === undefined || quotation.get('quotationDiscount') === null || quotation.get('quotationDiscount') === '') {
      }
      else {
        const totalDiscountedPricePerSection = {};
        Object.keys(totalPriceRelativeToSection).map((k) => {
          return k === activeSection ? totalDiscountedPricePerSection[k] = (totalPriceRelativeToSection[k] - (totalPriceRelativeToSection[k] / 100) * (quotation.get('quotationDiscount'))) : totalDiscountedPricePerSection[k] = totalPriceRelativeToSection[k]
        });
        totalDiscountedPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] - (totalPriceRelativeToSection[activeSection] / 100) * (quotation.get('quotationDiscount')[activeSection]))
        setTotalDiscountedPriceRelativeToSectionQuotation(totalDiscountedPricePerSection);
      }
    }
  }, [quotation]);

  useEffect(() => {
    if (quotationRevisionParts || quotationRevisionMiscParts) {
      const totalPricePerSection = {};
      const productsRelativeToSection = {};
      quotationRevisionParts.map((part) => {
        productsRelativeToSection[part?.section]?.length > 0 ? productsRelativeToSection[part?.section].push(part) : productsRelativeToSection[part?.section] = [part]  
        return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.price * part.quantity) : (part.price * part.quantity)
          
      })
      quotationRevisionMiscParts?.map((part) => {
        productsRelativeToSection[part.section] ? productsRelativeToSection[part.section].push(part) : productsRelativeToSection[part.section] = [part]
        return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.part.price * part.quantity) : (part.part.price * part.quantity)
      })
      const sectionsArr = [];
      Object.keys(productsRelativeToSection).map((k) => {
        return sectionsArr.push({ products: productsRelativeToSection[k], marginPercentage: null, discountPercentage: null})
      })
      if (sections.length === 0) {
        setSections(sectionsArr);
      } else {
        const newSectionsArr = sections.map((section, index) => {
          const updatedProducts = productsRelativeToSection[index + 1] || [];
          return { ...section, products: updatedProducts };
        });
        setSections(newSectionsArr);
      }
      setTotalPriceRelativeToSection(totalPricePerSection);
      setMarginPercentage(quotation?.get("quotationMargin"));
      setDiscountPercentage(quotation?.get("quotationDiscount"));
    }
  }, [quotationRevisionParts, quotationRevisionMiscParts]);

  const getQuotationItemParseObject = async (id) => {
    setIsLoading(true);
    const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations')).include(['client', 'updatedBy']);
    const res = await quotationsQuery.equalTo('objectId', id).first();
    setIsLoading(false);
    setQuotation(res);
    setMarginEnabled(res.get('marginEnabled'));
    setDiscountEnabled(res.get('discountEnabled') ?? {1: false});
    setMarginPercentage(res?.get("quotationMargin") ?? {1: false});
    setDiscountPercentage(res?.get("quotationDiscount"));
    setQuotationStatus(res.get('status'));
    setQuotationRevisionParts(res.get('quotationParts'));
    setQuotationRevisionMiscParts(res.get('miscParts'))
    setQuotationRevisionSections(res.get('state')[0].sectionCount);
    setQuotationComments(res?.get('quotationComments'));
  }

  useEffect(() => {
    if (revisionFinalized) {
      scrollToRevisions();
    }
  }, [revisionFinalized])

  const getQuotationRevisions = async () => {
    if (quotation) {
      const quotationRevisions = await quotation
        .relation("revisions")
        .query()
        .descending("createdAt")
        .find();
      if (quotationRevisions.filter((item) => item.get('finalized')).length > 0){
        setRevisionFinalized(true);
      }
      setQuotationRevisions(quotationRevisions);
      if (quotationRevisions.length > 0) {
        setLatestQuotationRevisionNumber(
          quotationRevisions[0]?.get("quotationNumber")
        );
      }
    }
  };
  
  const handleTabClick = (tabIndex) => {
    setActiveSection(tabIndex);
  };

  const handleQuotationStatusChange = async (event) => {
    setQuotationStatus(event.target.value);
    setQuotationStatusSpinning(true);
    setIsLoading(true);
    quotation.set('status', event.target.value);
    quotation.set("updatedBy", Parse.User.current());
    const res = await quotation.save();
    setQuotation(res);
    setQuotationStatusSpinning(false);
    setIsLoading(false);
    const quotationParts = quotation.get('quotationParts');
    const cantileverPartsQuery = new Parse.Query(Parse.Object.extend('CantileverParts')).limit(1000);
    const palletRackingPartsQuery = new Parse.Query(Parse.Object.extend('PalletRackingParts'));
    const palletRackingPartsUsedQuery = new Parse.Query(Parse.Object.extend('PalletRackingPartsUsed'));
    const cantileverParts = await cantileverPartsQuery.find();
    const palletRackingParts = await palletRackingPartsQuery.find();
    const palletRackingUsedParts = await palletRackingPartsUsedQuery.find();

    const allPartsArray = [...cantileverParts, ...palletRackingParts, ...palletRackingUsedParts];
    if ((quotationStatus === 'open') && (quotationStatus !== 'deposit' || quotationStatus !== 'won' || quotationStatus !== 'confirmed') && (event.target.value === 'won')) {
      
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            const value = {quantity: part.quantity};
            const partHoldStatusUpdated = Object.assign(partHoldStatus, {[quotationNumber]: value});
            partToUpdate.set('partHoldStatus', partHoldStatusUpdated);
            await partToUpdate.save();
        }
      });
    }
    else if (event.target.value !== 'won' && (quotationStatus === 'won' || quotationStatus === 'confirm') && (event.target.value === 'open')) {

      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }
    else if ((event.target.value === 'deposit')) {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity - part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
      await Parse.Cloud.run('sendCustomEmailWithoutAttachmenets', {
        sender: Parse.User.current().get('email'),
        recepient: ['admin@storemax.com.au', 'operations@storemax.com.au'],
        emailBodyText: `Hi, \nThis email is an update to inform you that the quotation ${quotation.get('quotationNumber')}'s status has been updated to state 'DEPOSIT'.\nPlease take the relevant steps to process the quotation pipeline.`,
        emailCCList: [Parse.User.current().get('email')],
      });
      const result = await Parse.Cloud.run('sendSMTPMail', {
        to: ['admin@storemax.com.au', 'operations@storemax.com.au'],
        subject: 'Storemax Quotation [DEPOSIT STAGE UPDATE]',
        text: `Hi, \nThis email is an update to inform you that the quotation ${quotation.get('quotationNumber')}'s status has been updated to state 'DEPOSIT'.\nPlease take the relevant steps to process the quotation pipeline.`,
      });
      console.log('Email sent successfully:', result);
    }
    else if ((event.target.value === 'confirm')) {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity - part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }
    else if (quotationStatus === 'deposit' && event.target.value === 'cancelled') {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity + part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    } else if (quotationStatus === 'won' && event.target.value === 'cancelled') {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = quotation.get('quotationNumber');
            partToUpdate.set('quantity', partToUpdate.quantity + partHoldStatus[quotationNumber].quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }
  }

  const saveComments = async () => {
    try {
      setSavingComments(true);
      quotation.set('quotationComments', quotationComments);
      await quotation.save();
      toast.success('Comments updated!');
      setSavingComments(false);
    } catch (error) {
      console.log('error: ', error);
      toast.success("Something went wrong!");
      setSavingComments(false);
    }
  }

  const getQuotation = async () => {
    setIsLoading(true);
    const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations')).include("client");
    quotationsQuery.equalTo('quotationNumber', quotation.get('quotationNumber'));
    try {
        const res = await quotationsQuery.find();
        setIsLoading(false);
        if (res.length > 0) {
            const quotationItems = JSON.parse(res[0].get('cartItemsState')[0]);
            quotationItems.map((item) => {
                const parseClone = new ParseObject(item.part.data ? item.part.data : item.part, item.part.data ? item.part.data.partID.includes('canti') ? "CantileverParts" : "PalletRackingParts" : item.part.partID.includes('canti') ? "CantileverParts" : "PalletRackingParts", item.id);
                const part = { id: item.id, part: parseClone, quantity: item.quantity, price: item.price, section: item.section };
                return dispatch(addToCart(part));
            });
            if (res[0].get('selectedClient')?.data) {
            const cloneParse = Object.assign({}, res[0].get('selectedClient')?.data);
            cloneParse.parseObject = res[0].get('selectedClient')?.data;
            cloneParse.email = res[0].get('selectedClient')?.data?.email;
            cloneParse.label = cloneParse?.parseObject?.companyName + ' - ' + cloneParse?.firstName + ' ' + cloneParse?.lastName + ' - ' + cloneParse?.email + ' - ' + cloneParse?.phoneNumber;
            dispatch(setSelectedClient(cloneParse));}
            const selectedQuotationType = res[0].get("quotationType");
            const miscParts = res[0].get('miscParts');
            if (miscParts.length > 0) {
                miscParts.map((item) => {
                    dispatch(addToMiscCartItems(item));
                });
            };
            if (res[0].get('winProbability')) {
                dispatch(setSelectedPercentageToWin(res[0].get('winProbability')));
            };
            dispatch(setSelectedQuotationType(selectedQuotationType.length > 0 ? selectedQuotationType : []))
            dispatch(setRevisionInProgress(true));
            dispatch(patchQuotationNumber(quotation.get('quotationNumber')));
            dispatch(setRevisionQuotation(quotation))
            navigate('/cart');
        }
    } catch (error) {
        toast.error('Something went wrong');
        console.log('error: ', error);
        setIsLoading(false);
    }
};

  return (
    <Box m="0px 20px 20px 20px">
      <ToastContainer />

      {!isLoading && quotation &&
      <Box>
        <Box>
        <img src={backArrowGreen} alt="back-arrow" width={30} className="cursor-pointer" onClick={() => navigate('/quotations')}/>
          </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="1em"
        >
          <Typography variant="h5" fontWeight="600">
            Quotation - {quotation?.get("quotationNumber")} - {quotation?.get("quotationType")}
          </Typography>
          <Box display="flex" justifyContent='flex-end' alignItems='baseline'>
          {!quotationStatusSpinning ? <FormControl sx={{margin: '0px 1em'}} size="small">
            {!revisionFinalized && <Select
              value={quotationStatus || ''} onChange={handleQuotationStatusChange} 
            >
              {currentUser.get('email').includes('operations') ? quotationStatusesOperations.map((status, index) => (
                <MenuItem key={index} value={status}>{status}</MenuItem>
              )) : quotationStatuses.map((status, index) => (
                <MenuItem key={index} value={status}>{status}</MenuItem>
              ))}
            </Select>}
          </FormControl> : <Spinner animation="border" role="status" variant="primary" />}
            <Picklist
                quotationItem={quotation}
                disableDownloadButton={quotationStatus !== "deposit"}
                quotationParts = {quotation.get('quotationParts')}
                quotationSections = {quotation.get('state')}
            />
            <DownloadQuotation
              quotationItem={quotation}
              quotationParts = {quotation.get('quotationParts')}
              quotationSections = {quotation.get('state')}
              totalPriceRelativeToSection={marginEnabled ? totalMarginalizedPriceRelativeToSectionQuotation : discountEnabled ? totalDiscountedPriceRelativeToSectionQuotation : totalPriceRelativeToSectionQuotation}
              miscCantileverItems={quotation.get('miscParts')}
            />
          </Box>
        </Box>


        <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={
            colors.primary[400]
          }
          p={"30px"}
        >

          <Box display="flex">
            {Array.apply(null, {length: (quotation.get('state')[0].sectionCount)}).map(
              (section, index) => (
                <Tab
                  enableDeletion = {false}
                  key={index}
                  label={`Section ${index + 1}`}
                  active={activeSection === index + 1}
                  onClick={() => handleTabClick(index + 1)}
                />
              )
            )}
          </Box>

          <Box display="flex" alignItems="center" mt="25px" mx="1em">
            <Typography variant="h6" fontWeight="400">
              For
            </Typography>
            <Typography
              ml={1}
              variant="h5"
              fontWeight="700"
              style={{ textDecoration: "underline" }}
            >
              {quotation?.get("client") ? quotation?.get("client")?.get("firstName") : quotation?.get('selectedClient')?.data?.firstName}{" "}
              {quotation?.get("client") ? quotation?.get("client")?.get("lastName") : quotation?.get("selectedClient")?.parseObject?.lastName}
            </Typography>
            <Typography ml={2}>{quotation?.get("client") ? quotation?.get("client")?.get("email") : quotation?.get('selectedClient')?.data?.email}</Typography>
          </Box>

          <Grid item container justifyContent={'flex-end'} xs={12}>
            {/* <Grid itemxs={4} display={'flex'}>
              <Typography variant="h6" style={{ textDecoration: "underline", textAlign: 'center' }} fontWeight="400">Part</Typography>
            </Grid> */}
            <Grid item xs={4} justifyContent={'flex-end'}>
              <Typography className="mx-2" variant="h6" style={{ textDecoration: "underline", textAlign: 'right' }} fontWeight="400">Unit Quantity</Typography>
            </Grid>
            <Grid item xs={1.2} justifyContent={'flex-end'}>
              <Typography className="mx-2" variant="h6" style={{ textDecoration: "underline", textAlign: 'right' }} fontWeight="400">Unit Price</Typography>
            </Grid>
          </Grid>

          {quotation?.get('quotationParts').map((part, index) => (
            <Box key={index}>
              {part.section === activeSection && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mt="25px"
                  mx="1em"
                >
                  {(part?.width || part?.height) ? <Typography variant="h6" fontWeight="400">
                    {part.name} - {part?.finish} - {part?.width} wide -{" "}
                    {part?.height} high -{" "}
                    {part?.depth} deep
                  </Typography> : <Typography variant="h6" fontWeight="400">
                    {part.name} {part.description}
                  </Typography>}
                  
                  <Grid container textAlign={'right'} justifyContent={'flex-end'} xs={6}>
                    <Grid item xs={3}>
                      <Typography className="mx-2" variant="h6" fontWeight="400">
                        {part.quantity}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className="mx-2" variant="h6" fontWeight="400">
                        ${part.price}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          ))}

          {quotation.get('miscParts')?.length > 0  &&
            <Typography mx={'0.5em'} variant="h6" fontWeight="700" my='1em' style={{textDecoration: 'underline'}}>
              Miscellaneous Parts:
            </Typography>
          }
          {quotation.get('miscParts')?.length > 0 &&
            quotation.get('miscParts').map((part, index) => {
              return <Box key={index}>
                {part.section === activeSection && (
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mx="1em"
                    >
                      <Typography variant="h6" fontWeight="400">
                      {part?.part.name} - {part?.part.dimensions ? part?.part.dimensions : null} - {part?.part.duty ? part?.part.duty : null} - {part?.part.finish?.length > 0 ? part?.part.finish : null}
                      </Typography>
                      <Grid item container textAlign={'right'} justifyContent={'flex-end'} xs={6}>
                        <Typography variant="h6" fontWeight="400" mr={2}>
                          ${part?.price}
                        </Typography>
                      </Grid>
                    </Box>
                    <Box>
                    {part.part?.comments?.length > 0 && <Box mx="1em">
                      <Box
                        style={{
                          marginTop: '3px'
                        }}>
                        {part.part.comments}
                      </Box>
                    </Box>}
                    </Box>
                  </Box>
                )}
              </Box>
            })
          }

          <Box>
            <Typography mx={'0.5em'} variant="h6" fontWeight="700" my='1em' color={colors.greenAccent[500]}>{(marginEnabled && marginEnabled[activeSection] && quotaitonSections.length > 0) ? `Margin applied - ${quotaitonSections[activeSection-1]?.marginPercentage}%` : (discountEnabled && discountEnabled[activeSection] && quotaitonSections.length > 0) ? `Discount Applied - ${quotaitonSections[activeSection-1]?.discountPercentage}%`: null }</Typography>
          </Box>

          <Box
            mt="25px"
            mx="1em"
            border="1px solid lightgray"
            p="1em"
            borderRadius="4px"
          >
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="400">
                Total Amount
              </Typography>
              <Typography variant="h6" fontWeight="400">
                ${!quotation.get('emptyQuotation') ? (quotation.get('totalPrice') / (1 + 0.1)).toFixed(2)  : quotation.get('emptyQuotationTotal')}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="400">
                GST
              </Typography>
              <Typography variant="h6" fontWeight="400">
                $
                {!quotation.get('emptyQuotation') ? ((quotation.get('totalPrice') / (1 + 0.1)) * 0.1).toFixed(2) : Math.round(quotation.get('emptyQuotationTotal') * 10)/100}
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" fontWeight="400">
                Net Amount
              </Typography>
              <Typography variant="h6" fontWeight="400">
                $
                {!quotation.get('emptyQuotation') ? parseFloat(quotation.get('totalPrice')).toFixed(2) : parseFloat(parseFloat(quotation.get('emptyQuotationTotal')).toFixed(2) + (parseFloat(quotation.get('emptyQuotationTotal') * 10) / 100)).toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            mt="25px"
            mx="1em"
          >
            <Box
              backgroundColor={colors.greenAccent[500]}
              p="5px 10px"
              borderRadius="4px"
              mt="15px"
              className="cursor-pointer"
              onClick={getQuotation}
            >
              Create a revision
            </Box>
          </Box>
        </Box>

      </Box>}

      {!isLoading && <Typography variant="h5" fontWeight="600" my="1em">
        Revisions
      </Typography>}
      {!isLoading && <Box
        ref={revisionRef}
        gridColumn="span 12"
        gridRow="span 6"
        backgroundColor={colors.primary[400]}
        p="30px"
      >
        <SimpleAccordion data={quotationRevisions}/>
      </Box>}

      {!isLoading && <Box>
        <Typography variant="h5" fontWeight="600" my="1em">
          Comments
        </Typography>
        <Box
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <InputBase
            data-tip
            fullWidth
            sx={{width:'95% !important'}}
            multiline
            rows={10}
            data-for="title"
            placeholder="Comments"
            className="outlined border rounded px-2 mx-3 my-3"
            name="title"
            value={quotationComments}
            onChange={(e) => setQuotationComments(e.target.value)}
            onBlur={() => {}}
          />
          <Box display='flex' justifyContent={'flex-end'}>
            {!savingComments ?  <Box
              backgroundColor={colors.greenAccent[500]}
              p="5px 10px"
              borderRadius="4px"
              mt="15px"
              className="cursor-pointer"
              onClick={saveComments}
            >
              Save
            </Box> : <Spinner animation="border" role="status" size='sm' variant="primary"/>}
          </Box>
        </Box>
      </Box>}

      {isLoading && 
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" variant="primary" />
        </div>}

    </Box>
  );
};

export default QuotationItemDetailComponent;
