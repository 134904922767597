import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useState } from "react";
import backArrow from '../assets/images/arrow.png'
import backArrowGreen from '../assets/images/arrowGreen.png'
import CreateQuotationNewOrExisting from "./CreateQuotationNewOrExisting";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emptyCart, patchQuotationNumber, setSelectedClient } from "../features/cart/cartSlice";
import Header from "./Header";


const CreateQuotation = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isNewQuotation, setIsNewQuotation] = useState({new: false, existing: false});
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <>
            <Box ml={4}>
                <Header title="" subtitle="Welcome Back" />
            </Box>
                <hr color="white"/>
            {(isNewQuotation.new || isNewQuotation.existing) && <Box ml={5} display={'flex'} alignItems={'center'}>
              <img src={backArrowGreen} alt="back-arrow" className="cursor-pointer" onClick={() => setIsNewQuotation({new: false, existing: false})}/>
              <Typography
                variant="h4"
                fontWeight="600"
                mx="2em"
                color={colors.greenAccent[400]}
            >
                {isNewQuotation?.new ? 'New' : isNewQuotation?.existing ? 'Existing' : null} Quotation
            </Typography>
            </Box>}
            {!isNewQuotation.new && !isNewQuotation.existing && <>
                <Typography
                    variant="h4"
                    fontWeight="600"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Select Quotation Variant
                </Typography>
                <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'} mt={5}>
                <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="10px 20px"
                    borderRadius="4px"
                    mt="15px"
                    className="cursor-pointer"
                    onClick={() => {dispatch(emptyCart()); dispatch(patchQuotationNumber('')); dispatch(setSelectedClient(null)); navigate('/cart')}}
                >
                    New Quotation
                </Box>

                <Box
                    backgroundColor={colors.greenAccent[500]}
                    p="10px 20px"
                    borderRadius="4px"
                    mt="15px"
                    className="cursor-pointer"
                    onClick={() => navigate('/existing')}
                >
                    Existing Quotation
                </Box>
            </Box>
            </>}
            {(isNewQuotation.new || isNewQuotation.existing) && <CreateQuotationNewOrExisting isNewQuotation={isNewQuotation}/>}
        </>
    )
}

export default CreateQuotation;