import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Row, Col, Modal } from "react-bootstrap";
import Parse from 'parse';
import { toast } from "react-toastify";
import { Button, InputBase, Switch, alpha, styled, useTheme } from "@mui/material";
import { tokens } from "../theme";

const RegisterClient = (props) => {
  const theme = useTheme();
	const colors = tokens(theme.palette.mode);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    company: Yup.string().required("Company Name is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.string().required("Phone number is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });
  const setRegisterClient = props.setRegisterClient;
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colors.greenAccent[600],
      '&:hover': {
        backgroundColor: alpha(colors.greenAccent[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.greenAccent[600],
    },
  }));

  const [responseMessage, setResponseMessage] = useState(undefined);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [marginEnabled, setMarginEnabled] = useState(false);
  const [discountEnabled, setDiscountEnabled] = useState(false);
  const [margin, setMargin] = useState('');
  const [discount, setDiscount] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
		const clientDetails = Parse.Object.extend('ClientDetails');
    const client = new clientDetails();
    client.set('firstName', data.firstName);
    client.set('lastName', data.lastName);
    client.set('address', data.address);
    client.set('companyName', data.company);
    client.set('phoneNumber', data.phone);
    client.set('email', data.email);
    client.set('margin', marginEnabled ? margin : undefined);
    client.set('discount', discountEnabled ? discount : undefined);
    try {
      await client.save();
      toast.success('Client Successfully Created');
    } catch (error) {
      console.log(error)
      toast.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="register-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <strong>
              {responseMessage !== undefined && (
                <div className="small-body-text">{`${responseMessage?.message}`}</div>
              )}
            </strong>
          </div>
          <div className="form-group">
            <Row className="g-2 p-2">
              <Col md>
                <input
                  type="text"
                  {...register("firstName")}
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                  placeholder="First Name"
                />
                <div className="invalid-feedback">
                  {errors.firstName?.message}
                </div>
              </Col>
              <Col md>
                <input
                  type="text"
                  {...register("lastName")}
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                  placeholder="Last Name"
                />
                <div className="invalid-feedback">
                  {errors.lastName?.message}
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row className="g-2 p-2">
              <Col md>
                <input
                  type="text"
                  {...register("company")}
                  className={`form-control ${
                    errors.company ? "is-invalid" : ""
                  }`}
                  placeholder="Company"
                />
                <div className="invalid-feedback">
                  {errors.company?.message}
                </div>
              </Col>
              <Col md>
                <input
                  type="text"
                  {...register("email")}
                  className={`form-control ${
                    errors.email != null ? "is-invalid" : ""
                  }`}
                  placeholder="Email"
                />
                <div className="invalid-feedback">{errors.email?.message}</div>
              </Col>
            </Row>
          </div>

          <div className="form-group" style={{ width: "inherit" }}>
            <Row className="g-2 p-2">
              <Col md>
                <input
                  type="text"
                  {...register("address")}
                  className={`form-control ${
                    errors.address ? "is-invalid" : ""
                  }`}
                  placeholder="Address"
                />
                <div className="invalid-feedback">
                  {errors.address?.message}
                </div>
              </Col>
            </Row>
          </div>

          <div className="form-group" style={{ width: "inherit" }}>
            <Row className="g-2 p-2">
              <Col>
                <input
                  type="tel"
                  {...register("phone")}
                  className={`form-control ${errors.phone ? "is-invalid" : ""}`}
                  placeholder="Phone Number"
                />
                <div className="invalid-feedback">{errors.phone?.message}</div>
              </Col>
            </Row>
          </div>

          <div className="d-flex justify-content-between align-items-center px-3">
            <div className="d-flex align-items-center">
              <GreenSwitch
                checked={marginEnabled}
                onChange={(e) => { setMarginEnabled(e.target.checked); e.target.checked ? setDiscountEnabled(false) : setDiscountEnabled(discountEnabled) }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <label>MARGIN</label>
            </div>
            {marginEnabled && <InputBase
              type="number"
              placeholder="margin %"
              className="outlined border rounded px-2 my-2"
              name="title" value={margin}
              onChange={(event) => setMargin(event.target.value)}
            />}
          </div>

          <div className="d-flex justify-content-between align-items-center px-3">
            <div className="d-flex align-items-center">
              <GreenSwitch
                checked={discountEnabled}
                onChange={(e) => { setDiscountEnabled(e.target.checked); e.target.checked ? setMarginEnabled(false) : setMarginEnabled(marginEnabled) }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <label>DISCOUNT</label>
            </div>
            {discountEnabled && <InputBase
              type="number"
              placeholder="discount %"
              className="outlined border rounded px-2 my-2"
              name="title" value={discount}
              onChange={(event) => setDiscount(event.target.value)}
            />}
          </div>

          <div className="form-group">
            <Row className="g-2 p-2">
              <Col>
                <div className="text-left">
                  <Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "5px 20px",
                    }}
                    onClick={() => reset()}
                  >
                    Reset
                  </Button>
                  
                </div>
              </Col>
              <Col>
                <div className="text-right">

<Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "5px 20px",
                    }}
                    type="submit"
                  >
                    Create
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <div className="text-center">
            <p className="primary-color my-3 ">The client already exists?</p>
            <Row className="g-2 p-2 text-center">
              <Col md>
                <Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "5px 20px",
                    }}
                    onClick={() => setRegisterClient(false)}
                  >
                    Select Client
                  </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterClient;
