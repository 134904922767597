import React, { useState, useRef, useEffect, useMemo } from "react";
import * as Parse from "parse";
import frame from "../assets/images/palletFrame.png";
import beam from "../assets/images/palletBeam.png";
import meshDeck from '../assets/images/meshDeck.png';
import { useSelector, useDispatch } from 'react-redux'
import { prop } from "ramda";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Backdrop, Box, Button, Fade, IconButton, InputBase, List, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { addToCart } from "../features/cart/cartSlice";
import accessories from '../assets/images/settings.png';
import rowSpacer from '../assets/images/rowSpacers.png';
import cogs from '../assets/images/adjust.png';
import barSupport from '../assets/images/palletBarSupport.png';
import particleBoard from '../assets/images/pallettParticleBoard.png';
import supportBarWithRetainer from '../assets/images/supportBarWithRetainer.png';
import hdSupportBar from '../assets/images/hdSupportBar.png';
import forkEntryBars from '../assets/images/forkEntryBars.png';
import spliceKit from '../assets/images/spliceKit.png';
import cornerGuard from '../assets/images/cornerGuard.png';
import singleSidedProtector from '../assets/images/singleSidedProtector.png';
import doubleSidedProtector from '../assets/images/doubleSidedProtector.png';
import footplateHD from '../assets/images/footplateHD.png';
import footplateSTD from '../assets/images/footplateSTD.png';
import dynaBolt from '../assets/images/dynaBolt.png';
import levellingShim from '../assets/images/levellingShim.png';
import DDivider from '../assets/images/DDividers.png';
import palletRackLoadSign from '../assets/images/palletRackLoadSign.png';
import postProtector from '../assets/images/postProtector.png';
import beamSafetyClip from '../assets/images/beamSafetyClip.png';
import forkLift from '../assets/images/forkLift.png';
import cageHire from '../assets/images/cageHire.png';
import scissorLift from '../assets/images/scissorLift.png';
import particleBoardZClip from '../assets/images/particleBoardZClip.png';
import dividingPins from '../assets/images/dividingPins.png';
import installation from '../assets/images/installation.jpeg';
import truck from '../assets/images/truck.png';
import { ParseObject } from "./ExistingQuotation";

export const FRAME = "Frame";
export const BEAM = "Beam";
export const SUPPORT = "Support";
export const ROW_SPACER = "Row Spacer";
export const MESH_DECK = "Mesh Deck";
export const ACCESSORY = "Accessory";
export const MISC = "Misc";

const PalletRackingPart = (props) => {
  const condtion = props.condtion;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [quantity, setQuantity] = useState(0);
	const [price] = useState(props.part.get('price'));
	const [cartItems, setCartItems] = useState();
	const [itemQuantityAvailable, setItemQuantityAvailable] = useState();
	const dispatch = useDispatch();
	const cartItemsGlobal = useSelector((state) => state.cart.cartItems)
	const currentActiveSection = useSelector((state) => state.cart.sections.currentActive)
	const toastId = React.useRef(null);
	const cartSection = useSelector((state) => state.cart.sections);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const incremet = () => { setQuantity(quantity + 1); setItemQuantityAvailable(itemQuantityAvailable - 1) };
	const decrement = () => { setQuantity(quantity - 1); setItemQuantityAvailable(itemQuantityAvailable + 1) };

  useEffect(() => {
		if (cartItemsGlobal?.length >= 1) {
			cartItemsGlobal.forEach(element => {
				if (element.part.id === props.part.id && element.section === cartSection.currentActive) {
          setItemQuantityAvailable(props.part.get('quantity') - element.quantity);
					setQuantity(element.quantity)
				}
				else {
          setQuantity(props.part.get('quantity'))
					setItemQuantityAvailable(props.part.get('quantity'));
				}
			});
		}
		else {
			setItemQuantityAvailable(props.part.get('quantity'));
		}
	}, [cartItemsGlobal, props.part]);

	const totalPrice = useMemo(() => {
		return price * quantity;
	}, [quantity]);

	const addToCart1 = () => {
		// const item = { id: props.part.id, part: props.part, quantity: quantity, price: totalPrice, section: currentActiveSection, condtion: condtion };
    const item = { id: props.part.id, part: new ParseObject(JSON.parse(JSON.stringify(props.part)), props.part.className, props.part.id), quantity: quantity, price: totalPrice, section: currentActiveSection }
		dispatch(addToCart(item));
    toast.success("Part Successfully Added To Cart!");
    handleClose()
	}


	return (
    <Box
      gridColumn="span 4"
      gridRow="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb="0.5em"
    >
      <Box className="d-flex justify-content-between">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "10px 10px 0 10px" }}
          color={colors.greenAccent[400]}
        >
          {props.part.get("name")}{" "}
          {props.part.get("name") === "Arm" &&
          props.part.get('finish') === "Blue"
            ? "Orange"
            : props.part.get('finish')}
        </Typography>
        <Typography
          variant="h4"
          fontWeight="800"
          sx={{ padding: "10px 10px 0 10px" }}
        >
          Available - {itemQuantityAvailable}
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my="0.5rem"
      >
        <img
          alt="item_image"
          width="200px"
          height="200px"
          objectFit="cover"
          src={
            props.part.get("name") === FRAME
						? frame
						: props.part.get("name") === BEAM
						? beam
						: props.part.get("name") === SUPPORT && 
						props.part.get('description').includes('Pallet Bar Support')
						? barSupport
            : props.part.get("name") === SUPPORT && 
						props.part.get('description').includes('Particle Board Retaining Clip (Z Clip)')
						? particleBoardZClip
						: props.part.get("name") === SUPPORT && 
						props.part.get('description').includes('Particle Board')
						? particleBoard
            : props.part.get("name") === SUPPORT && 
						props.part.get('description').includes('HD')
            ? hdSupportBar
            : props.part.get("name") === SUPPORT && 
						props.part.get('description').includes('Retainer')
            ? supportBarWithRetainer
						: props.part.get("name") === ROW_SPACER
						? rowSpacer
						: props.part.get("name") === MESH_DECK
						? meshDeck
						: props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Fork Entry Bars')
						? forkEntryBars
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Splice')
            ? spliceKit
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Corner')
            ? cornerGuard
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Single Sided')
            ? singleSidedProtector
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Double Sided')
            ? doubleSidedProtector
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Standard footplate')
            ? footplateSTD
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Dyna')
            ? dynaBolt
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Levelling')
            ? levellingShim
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('D Dividers')
            ? DDivider
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Pallet Rack Load Sign')
            ? palletRackLoadSign
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Post protectors')
            ? postProtector
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Beam Safety Clips')
            ? beamSafetyClip
            : props.part.get("name") === ACCESSORY && 
						props.part.get('description').includes('Dividing Pins')
            ? dividingPins
            : props.part.get("name") === MISC && 
						props.part.get('description').includes('Fork Lift')
            ? forkLift
            : props.part.get("name") === MISC && 
						props.part.get('description').includes('Scissor Lift')
            ? scissorLift
            : props.part.get("name") === MISC && 
						props.part.get('description').includes('Cage')
            ? cageHire
            : props.part.get("name") === MISC && 
						props.part.get('description').includes('Installation')
            ? installation
            : props.part.get("name") === MISC && 
						props.part.get('description').includes('Delivery')
            ? truck
						: cogs
          }
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <Typography
        variant="h6"
        fontWeight="600"
        mt="5px"
        mb="0"
        display="flex"
        justifyContent="center"
      >
        {props.part.get('description')}
      </Typography>
      <Typography
        variant="h6"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
      >
        {props.part.get("name") !== MISC && <strong>Loading Cap: {props.part.get("loadingCap")}</strong>}
      </Typography>

      <Typography
        variant="h5"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
        color={colors.blueAccent[400]}
      >
        ${props.part.get("price")}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
      >
        <div className="d-flex align-items-center justify-content-between position-relative border rounded-pill">
          <IconButton onClick={decrement} disabled={quantity <= 0}>
            <RemoveCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
          <InputBase
            value={quantity}
            onChange={(e) => {}}
            onBlur={() => {}}
            sx={{ flex: 1 }}
          />
          <IconButton onClick={incremet}>
            <AddCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
        </div>
        <Box mx="0.2em">
          Net Price:{" "}
          <strong>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</strong>
        </Box>
        <ReactTooltip id="title required" effect="solid" place="top">
          {"Add to Cart"}
        </ReactTooltip>
        <IconButton
          className="cursor-pointer"
          disabled={quantity <= 0}
          onClick={() => addToCart1()}
          data-tip
          data-for="title required"
        >
          <ShoppingCartIcon
            sx={{
              fontSize: "26px",
              color: quantity > 0 ? colors.greenAccent[500] : colors.grey[500],
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
export default PalletRackingPart;
