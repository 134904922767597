import React, { useState, useRef, useEffect, useMemo } from "react";
import * as Parse from "parse";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Backdrop, Box, Button, Fade, IconButton, InputBase, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { addToCart, addToMiscCartItems } from "../features/cart/cartSlice";
import { generatedUniqueId } from "./utils";

export const COLUMN_NAME = "Column";
export const ARM_NAME = "Arm";
export const BASE_NAME = "Base";
export const BRACE_NAME = "Bracing";

const CreateMiscPart = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
  const [partName, setPartName] = useState('');
  const [partDimensions, setPartDimensions] = useState('');
  const [partFinish, setPartFinish] = useState('');
  const [partPrice, setPartPrice] = useState('');
  const [partComments, setPartComments] = useState('');
  const [partDuty, setPartDuty] = useState('');
	const [quantity, setQuantity] = useState(0);
	const [itemQuantityAvailable, setItemQuantityAvailable] = useState();
	const dispatch = useDispatch();
	const currentActiveSection = useSelector((state) => state.cart.sections.currentActive)
	const incremet = () => { setQuantity(quantity + 1); setItemQuantityAvailable(itemQuantityAvailable - 1) };
	const decrement = () => { setQuantity(quantity - 1); setItemQuantityAvailable(itemQuantityAvailable + 1) };

	const totalPrice = useMemo(() => {
		return partPrice * quantity;
	}, [quantity]);

	const addToCart = () => {
		const item = {
      id: generatedUniqueId(),
      part: {
      name: partName,
      dimensions: partDimensions,
      duty: partDuty,
      finish: partFinish,
      price: partPrice,
      comments: partComments
    },
    quantity: quantity,
    price: totalPrice,
    section: currentActiveSection
  }
		dispatch(addToMiscCartItems(item));
    setPartName('');
    setPartDimensions('');
    setPartDuty('');
    setPartFinish('');
    setPartPrice('');
    setPartComments('');
    setQuantity(0);
    toast.success("Part Successfully Added To Cart!");
	}

	return (
    <Box
      gridColumn="span 4"
      gridRow="span 3"
      backgroundColor={colors.blueAccent[800]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb="0.5em"
    >
      <Typography
        variant="p"
        fontWeight="700"
        my='0.25em'
        mx='0.25em'
        color={colors.blueAccent[200]}
      >
        Create a Custom Misc Item
      </Typography>
      <Box className="d-flex flex-column justify-content-center h-100">
          <InputBase
            data-tip
            data-for="title"
            placeholder="Name"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partName}
            onChange={(e) => setPartName(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Dimensions"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partDimensions}
            onChange={(e) => setPartDimensions(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Duty"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partDuty}
            onChange={(e) => setPartDuty(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Finish"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partFinish}
            onChange={(e) => setPartFinish(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            type="number"
            data-for="title"
            placeholder="Price"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partPrice}
            onChange={(e) => setPartPrice(e.target.value)}
            onBlur={() => {}}
            />

          <InputBase
            data-tip
            data-for="title"
            placeholder="Comments"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partComments}
            onChange={(e) => setPartComments(e.target.value)}
            onBlur={() => {}}
            />
      </Box>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
      >
        <div className="d-flex align-items-center justify-content-between position-relative border rounded-pill">
          <IconButton onClick={decrement} disabled={partName.length === 0 || partPrice.length === 0 || quantity <= 0}>
            <RemoveCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
          <InputBase
            value={quantity}
            onChange={(e) => {}}
            onBlur={() => {}}
            sx={{ flex: 1 }}
          />
          <IconButton disabled={partName.length === 0 || partPrice.length === 0} onClick={incremet}>
            <AddCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
        </div>
        <Box mx="0.2em">
          Net Price:{" "}
          <strong>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</strong>
        </Box>
        <ReactTooltip id="title required" effect="solid" place="top">
          {"Add to Cart"}
        </ReactTooltip>
        <IconButton
          className="cursor-pointer"
          disabled={quantity <= 0}
          onClick={addToCart}
          data-tip
          data-for="title required"
        >
          <ShoppingCartIcon
            sx={{
              fontSize: "26px",
              color: quantity > 0 ? colors.greenAccent[500] : colors.grey[500],
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
export default CreateMiscPart;
