import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
// import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import EmailIcon from '@mui/icons-material/Email';
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useSelector } from "react-redux";
import { tokens } from "../theme";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [displaySidebar, setDisplaySidebar] = useState(true);
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems)
  const miscParts = useSelector((state) => state.cart.miscCantileverItems);
  const userInfo = useSelector((state) => state.cart.user)

  useEffect(() => {
    if (userInfo?.loggedIn) {
      setDisplaySidebar(true);
    }
    else {
      setDisplaySidebar(false);
    }
  }, [userInfo]);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 15px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        height: '100vh'
      }}
    >
      {displaySidebar && <ProSidebar style={{height: "100%", overflow: 'auto'}} collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                ml="15px"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"} >
            <Item
              title="Home"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Create Quotation"
              to="/create-quotation"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Box className='position-relative'>
              <Item
                  title="Cart"
                  to="/cart"
                  icon={<ShoppingCartOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              <div className="cart-quantity position-absolute" style={{background: colors.redAccent[600]}}>
                    {cartItems?.length + miscParts.length}
              </div>
            </Box>
            <Item
              title="Quotations"
              to="/quotations"
              icon={<FormatQuoteIcon/>}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Clients"
              to="/clients"
              icon={<EmojiPeopleIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Box>
            </Box>
            <Item
              title="Send Custom Email"
              to="/sendEmail"
              icon={<EmailIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Metics"
              to="/metrics"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>}
    </Box>
  );
};

export default Sidebar;
