import React, { useState, useRef, useEffect, useMemo } from "react";
import * as Parse from "parse";
import frame from "../assets/images/palletFrame.png";
import beam from "../assets/images/palletBeam.png";
import meshDeck from '../assets/images/palletMeshDeck.png';
import armGalv from '../assets/images/arm-galv.png';
import base from '../assets/images/base-blue.png';
import baseGalv from '../assets/images/base-galv.png';
import baseDouble from '../assets/images/base-double-blue.png';
import baseDoubleGalv from '../assets/images/base-double-galv.png';
import braceDiagonal from '../assets/images/brace-diagonal.png';
import braceDiagonalGalv from '../assets/images/brace-diagonal-galv.png';
import braceHorizontal from '../assets/images/brace-horizontal.png';
import braceHorizontalGalv from '../assets/images/brace-horizontal-galv.png';
import { useSelector, useDispatch } from 'react-redux'
import { prop } from "ramda";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Backdrop, Box, Button, Fade, IconButton, InputBase, List, MenuItem, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { addToCart } from "../features/cart/cartSlice";
import accessories from '../assets/images/settings.png';
import rowSpacer from '../assets/images/palletRowSpacer.png';
import cogs from '../assets/images/adjust.png';
import barSupport from '../assets/images/palletBarSupport.png';
import particleBoard from '../assets/images/pallettParticleBoard.png';
import Select from "@mui/material/Select";
import { ParseObject } from "./ExistingQuotation";

export const FRAME = "Frame";
export const BEAM = "Beam";
export const SUPPORT = "Support";
export const ROW_SPACER = "Row Spacer";
export const MESH_DECK = "Mesh Deck";
export const ACCESSORY = "Accessory";

const FramePalletRacking = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
  const condtion = props.condtion;
	const [quantity, setQuantity] = useState(0);
	const [brand, setBrand] = useState("");
	const [finish, setFinish] = useState("");
  const frameDimensions = Object.values(props.frameDimensions);
	const [itemQuantityAvailable, setItemQuantityAvailable] = useState();
	const dispatch = useDispatch();
	const cartItemsGlobal = useSelector((state) => state.cart.cartItems);
	const currentActiveSection = useSelector((state) => state.cart.sections.currentActive)
	const cartSection = useSelector((state) => state.cart.sections);

  const [selectedPart, setSelectedPart] = useState(frameDimensions[0][0]);
  const [selectedPartPrice, setSelectedPartPrice] = useState(frameDimensions[0][0].get('price'));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const incremet = () => { setQuantity(quantity + 1); setItemQuantityAvailable(itemQuantityAvailable - 1) };
	const decrement = () => { setQuantity(quantity - 1); setItemQuantityAvailable(itemQuantityAvailable + 1) };

	const totalPrice = useMemo(() => {
		return selectedPartPrice * quantity;
	}, [quantity]);

  useEffect(() => {
		if (cartItemsGlobal?.length >= 1) {
			cartItemsGlobal.forEach(element => {
				if (element.part.id === selectedPart.id && element.section === cartSection.currentActive) {
          setItemQuantityAvailable(selectedPart.get('quantity') - element.quantity);
					setQuantity(element.quantity)
				}
				else {
          setQuantity(selectedPart.get('quantity'))
					setItemQuantityAvailable(selectedPart.get('quantity'));
				}
			});
		}
		else {
			setItemQuantityAvailable(selectedPart.get('quantity'));
		}
	}, [cartItemsGlobal, selectedPart]);

	const addToCart1 = () => {
		// const item = { id: selectedPart.id, part: selectedPart, quantity: quantity, price: totalPrice, section: currentActiveSection, condtion: condtion };
    const item = { id: selectedPart.id, part: new ParseObject(JSON.parse(JSON.stringify(selectedPart)), selectedPart.className, selectedPart.id), quantity: quantity, price: totalPrice, section: currentActiveSection }
		dispatch(addToCart(item));
    toast.success("Part Successfully Added To Cart!");
    handleClose()
	}

    const handleSelectedDimensionChange = (event) => {
        setBrand("");
        setFinish("");
        setSelectedPart(event.target.value);
        setSelectedPartPrice(event.target.value.get('price'));
    };

    useEffect(() => {
      const frameObj = selectedPart;
      frameObj.set('finish', finish);
      frameObj.set('brand', brand);
    }, [selectedPart, finish, brand]);


	return (
    <Box
      gridColumn="span 4"
      gridRow="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb="0.5em"
    >
      <Box className="d-flex justify-content-between">
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "10px 10px 0 10px" }}
          color={colors.greenAccent[400]}
        >
          Frame
        </Typography>

        <Typography
          variant="h4"
          fontWeight="800"
          sx={{ padding: "10px 10px 0 10px" }}
        >
          Available - {itemQuantityAvailable}
        </Typography>

      </Box>
      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        mx={2}
        my="0.5rem">
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Typography
            variant="p"
            display="flex"
            justifyContent="center"
          >Brand</Typography>
          <InputBase
            value={brand}
            onChange={(e) => setBrand(e.target.value)}
            onBlur={() => { }}
            sx={{ flex: 1, width: '100%', border: "1px solid gray", borderRadius: '4px' }}
          />
        </Box>

        <img
          alt="item_image"
          width="200px"
          height="200px"
          src={frame}
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
          <Typography
            variant="p"
            display="flex"
            justifyContent="center"
          >Finish</Typography>
          <InputBase
            value={finish}
            onChange={(e) => setFinish(e.target.value)}
            onBlur={() => { }}
            sx={{ flex: 1, width: '100%', border: "1px solid gray", borderRadius: '4px' }}
          />
        </Box>
      </Box>

        <Typography
        variant="p"
        fontWeight="300"
        sx={{ padding: "10px 10px 0 10px" }}
        color={colors.greenAccent[400]}
        >
            Select Required Dimension
        </Typography>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedPart}
            label="Dimension"
            onChange={handleSelectedDimensionChange}
        >
            {frameDimensions.map((frame) => (
                frame.map((item, i) => (
                    <MenuItem key={i} value={item}>{item.get('description')}</MenuItem>
                ))
            ))}
        </Select>

        <Typography
        variant="h6"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
      >
        <strong>Loading Cap: {selectedPart?.get("loadingCap")}</strong>
      </Typography>

      <Typography
        variant="h5"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
        color={colors.blueAccent[400]}
      >
        ${selectedPart?.get("price")}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
      >
        <div className="d-flex align-items-center justify-content-between position-relative border rounded-pill">
          <IconButton onClick={decrement} disabled={quantity <= 0}>
            <RemoveCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
          <InputBase
            value={quantity}
            onChange={(e) => {}}
            onBlur={() => {}}
            sx={{ flex: 1 }}
          />
          <IconButton onClick={incremet}>
            <AddCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
        </div>
        <Box mx="0.2em">
          Net Price:{" "}
          <strong>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</strong>
        </Box>
        <ReactTooltip id="title required" effect="solid" place="top">
          {"Add to Cart"}
        </ReactTooltip>
        <IconButton
          className="cursor-pointer"
          disabled={quantity <= 0}
          onClick={() => addToCart1()}
          data-tip
          data-for="title required"
        >
          <ShoppingCartIcon
            sx={{
              fontSize: "26px",
              color: quantity > 0 ? colors.greenAccent[500] : colors.grey[500],
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
export default FramePalletRacking;
