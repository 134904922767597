import React, { useState, useRef, useEffect, useMemo } from "react";
import * as Parse from "parse";
import { ToastContainer } from 'react-toastify';
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, Button } from "@mui/material";
import CantileverPart from "../../components/CantileverPart";
import Spinner from 'react-bootstrap/Spinner';
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import column from "../../assets/images/column.png";
import armRed from '../../assets/images/arm-red.png';
import base from '../../assets/images/base-blue.png';
import braceDiagonal from '../../assets/images/brace-diagonal.png';
import cogs from '../../assets/images/adjust.png';
import accessories from '../../assets/images/settings.png';
import MiscPart from "../../components/MiscPart";
import CreateMiscPart from "../../components/CreateMiscPart";
import PalletRackingPart, { MISC } from "../../components/PalletRackingPart";




const FAQ = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cantileverParts, setCantileverParts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [duty, setDuty] = React.useState('Medium');
  const [currentActive, setCurrentActive] = useState(1);
  const [palletMiscParts, setPalletMiscParts] = useState([]);
  const navigate = useNavigate();
  const cartSection = useSelector((state) => state.cart.sections);
  const miscParts = useSelector((state) => state.cart.miscCantileverItems);

  useEffect(() => {
    getCantileverParts();
  }, [duty, currentActive]);

  const customSortHeight = (a, b) => {
    if (a?.get('height') === b?.get('height')) {
      if (a.get('finish') === 'Blue' && b.get('finish') !== 'Blue') {
        return -1; // 'a' comes before 'b'
      } else if (a.get('finish') !== 'Blue' && b.get('finish') === 'Blue') {
        return 1; // 'b' comes before 'a'
      } else {
        // If both are Blue or both are not Blue, maintain the original order.
        return 0;
      }
    }
  }

  const customSortForArms = (a, b) => {
    if (a?.get('depth') === b?.get('depth')) {
      if (a.get('finish') === 'Blue' && b.get('finish') !== 'Blue') {
        return -1; // 'a' comes before 'b'
      } else if (a.get('finish') !== 'Blue' && b.get('finish') === 'Blue') {
        return 1; // 'b' comes before 'a'
      } else {
        // If both are Blue or both are not Blue, maintain the original order.
        return 0;
      }
    }
  }

  const customSortForArmToSuitBase = (a, b) => {
    if (a?.get('armToSuitBase') === b?.get('armToSuitBase') && a?.get('sides') === b?.get('sides')) {
      if (a.get('finish') === 'Blue' && b.get('finish') !== 'Blue') {
        return -1; // 'a' comes before 'b'
      } else if (a.get('finish') !== 'Blue' && b.get('finish') === 'Blue') {
        return 1; // 'b' comes before 'a'
      } else {
        // If both are Blue or both are not Blue, maintain the original order.
        return 0;
      }
    }
  }

  const customSortForBaseSides = (a, b) => {
    // Compare the colors
    if (a.get('sides') === "1" && b.get('sides') !== "1") {
      return -1; // 'a' comes before 'b'
    } else if (a.get('sides') !== "1" && b.get('sides') === "1") {
      return 1; // 'b' comes before 'a'
    } else {
      // If both are Blue or both are not Blue, maintain the original order.
      return 0;
    }
  }

  const customSortForBracing = (a, b) => {
    if (parseInt(a.get('bayCenter').replace('mm', '').replace('Horizontal ', '').replace('Diagonal ', '').replace(' bay', '')) === parseInt(b.get('bayCenter').replace('mm', '').replace('Horizontal ', '').replace('Diagonal ', '').replace(' bay', '')) && a?.get("bayCenter").split(" ")[0] === b?.get("bayCenter").split(" ")[0]) {
      if (a.get('finish') === "Blue" && b.get('finish') !== "Blue") {
        return -1; // 'a' comes before 'b'
      } else if (a.get('finish') !== "Blue" && b.get('finish') === "Blue") {
        return 1; // 'b' comes before 'a'
      } else {
        // If both are Blue or both are not Blue, maintain the original order.
        return 0;
      }
    }

  }

  const getCantileverParts = async () => {
    setIsLoading(true);
    try {
      const query = new Parse.Query(Parse.Object.extend("CantileverParts"));
      if (currentActive === 1) {
        query.equalTo("name", "Column")
          .equalTo('duty', duty);
        const data = await query.find();
        data.sort(function (a, b) {
          return parseInt(a.get('height').replace('mm', '')) - parseInt(b.get('height').replace('mm', ''));
        });
        data.sort(customSortHeight);
        setCantileverParts(data);
        setIsLoading(false);
      } else if (currentActive === 2) {
        query.equalTo("name", "Base")
          .equalTo('duty', duty);
        const data = await query.find();
        setIsLoading(true);
        data.sort(function (a, b) {
          return parseInt(a.get('armToSuitBase').replace('mm', '')) - parseInt(b.get('armToSuitBase').replace('mm', ''));
        });
        data.sort(customSortForBaseSides);
        data.sort(customSortForArmToSuitBase);
        setCantileverParts(data);
        setIsLoading(false);
      } else if (currentActive === 3) {
        query.equalTo("name", "Arm")
          .equalTo('duty', duty === "Heavy" ? "Medium" : duty);
        const data = await query.find();
        setIsLoading(true);
        data.sort(function (a, b) {
          return parseInt(a.get('depth').replace('mm', '')) - parseInt(b.get('depth').replace('mm', ''));
        });
        data.sort(customSortForArms);
        setCantileverParts(data);
        setIsLoading(false);
      } else if (currentActive === 4) {
        query.equalTo("name", "Bracing")
          .equalTo('duty', duty === "Heavy" ? "Medium" : duty);
        const data = await query.find();
        setIsLoading(true);
        data.sort(function (a, b) {
          return parseInt(a.get('bayCenter').replace('mm', '').replace('Horizontal ', '').replace('Diagonal ', '').replace(' bay', '')) - parseInt(b.get('bayCenter').replace('mm', '').replace('Horizontal ', '').replace('Diagonal ', '').replace(' bay', ''));
        });
        data.sort(customSortForBracing);
        setCantileverParts(data);
        setIsLoading(false);
      } else if (currentActive === 5) {
        query.equalTo("category", "Accessory")
        const data = await query.find();
        setIsLoading(true);
        setCantileverParts(data);
        setIsLoading(false);
      } else if (currentActive === 6) {
        setIsLoading(true);
        setCantileverParts([]);
        let palletQuery;
        palletQuery = new Parse.Query(Parse.Object.extend("PalletRackingPartsUsed"));
        palletQuery.equalTo("name", MISC);
        const data = await palletQuery.find();
        setIsLoading(true);
        setPalletMiscParts(data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleDutyChange = (event) => {
    setDuty(event.target.value);
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <div>
        <ToastContainer />
      </div>

      <Box
        display='flex'
        justifyContent='center'
      >
        <img className="cantilever-part-navigate" src={column} alt='cantilever-part' onClick={() => {
          setCurrentActive(1);
          if (currentActive > 7) {
            setCurrentActive(7);
          }
          window.scrollTo(0, 0)
        }} />
        <img className="cantilever-part-navigate" src={base} alt='cantilever-part' onClick={() => {
          setCurrentActive(2);
          if (currentActive > 7) {
            setCurrentActive(7);
          }
          window.scrollTo(0, 0)
        }} />
        <img className="cantilever-part-navigate" src={armRed} alt='cantilever-part' onClick={() => {
          setCurrentActive(3);
          if (currentActive > 7) {
            setCurrentActive(7);
          }
          window.scrollTo(0, 0)
        }} />
        <img className="cantilever-part-navigate" src={braceDiagonal} alt='cantilever-part' onClick={() => {
          setCurrentActive(4);
          if (currentActive > 7) {
            setCurrentActive(7);
          }
          window.scrollTo(0, 0)
        }} />
        <div className="cantilever-part-navigate" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="cogs" src={accessories} alt='cantilever-part' onClick={() => {
            setIsLoading(true);
            setCurrentActive(5);
            if (currentActive > 7) {
              setCurrentActive(7);
            }
            window.scrollTo(0, 0)
          }} />
        </div>
        <div className="cantilever-part-navigate" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img className="cogs" src={cogs} alt='cantilever-part' onClick={() => {
            setCurrentActive(6);
            if (currentActive > 7) {
              setCurrentActive(7);
            }
            window.scrollTo(0, 0)
          }} />
        </div>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <FormControl sx={{ m: 1, minWidth: 800 }}>
          <InputLabel id="demo-simple-select-label">Duty</InputLabel>
          <Select
            className="font-weight-bolder"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={duty}
            label="Duty"
            onChange={handleDutyChange}
            variant='outlined'
          >
            <MenuItem value={'Light'}>Light</MenuItem>
            <MenuItem value={'Medium'}>Medium</MenuItem>
            <MenuItem value={'Heavy'}>Heavy</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <p className="p-0 m-0">Active Section - {cartSection.currentActive}</p>


      <Box width='100%'>
        {
          !isLoading ? <Box display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
            m="15px">
            {cantileverParts?.map((part, i) => (
              <CantileverPart key={i} part={part} quantity={0} updateCart={setCartItems} currentCart={cartItems}></CantileverPart>
            ))}
            {cantileverParts?.length === 0 && currentActive === 6 &&
              <>
                {palletMiscParts?.map((part, i) => {
                  return <PalletRackingPart key={i} part={part} quantity={0} updateCart={setCartItems} currentCart={cartItems} condition={'new'} />
                })}
                {miscParts.map((part) => {
                  return part.section === cartSection.currentActive ? <MiscPart key={part.id} id={part.id} part={part.part} quantity={part.quantity} /> : null
                })}
                <CreateMiscPart />

              </>
            }
          </Box>
            :
            <div className="d-flex justify-content-center align-items-center" style={{ height: '70vh' }}>
              <Spinner animation="border" role="status" variant="secondary" />
            </div>
        }


        {cantileverParts?.length === 0 && currentActive === 5 && <div className="d-flex justify-content-center">
          <p>No Accessory item found</p>
        </div>
        }

      </Box>
      <Box display='flex' mb='1em'>
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "5px"
          }}
          disabled={currentActive === 1}
          id="prev"
          onClick={() => {
            setCurrentActive(currentActive-1);
            if (currentActive < 1) setCurrentActive(1);
            window.scrollTo(0, 0)
          }}
        >
          Prev
        </Button>

        {currentActive !== 6 ? <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginLeft: "5px"
          }}
          id="next"
          onClick={() => {
            setCurrentActive(currentActive+1);
            if (currentActive > 7) {
              setCurrentActive(7);
            }
            window.scrollTo(0, 0)
          }}
        >
          Next
        </Button>
          :
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "5px"
            }}
            onClick={() => navigate('/cart')}
          >
            View and Edit Cart
          </Button>
        }
      </Box>
    </Box>
  );
};
export default FAQ;
