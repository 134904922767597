import { useEffect, useState } from "react"
import Parse from 'parse';
import { useDispatch } from "react-redux";
import { addToCart, addToMiscCartItems, patchQuotationNumber, setSelectedClient, setSelectedPercentageToWin, setSelectedQuotationType } from "../features/cart/cartSlice";
import { Box, Button, InputBase, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

export class ParseObject {
    constructor(data, className, id) {
      this.data = data;
      this.className = className;
      this.id = id
    }
  
    get(key) {
      return this.data[key];
    }
  }

const ExistingQuotation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [quotationNumber, setQuotationNumber] = useState("");
    const [quotationItems, setQuotationItems] = useState();
    const [quotationNumberFound, setQuotationNumberFound] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getQuotation = async () => {
        setIsLoading(true);
        const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations')).include("client");
        quotationsQuery.equalTo('quotationNumber', quotationNumber);
        quotationsQuery.equalTo('quotationStatus', "in-process");
        try {
            const res = await quotationsQuery.find();
            setIsLoading(false);
            if (res.length > 0) {
                setQuotationItems(JSON.parse(res[0].get('cartItemsState')[0]));
                const quotationItems = JSON.parse(res[0].get('cartItemsState')[0]);
                quotationItems.map((item) => {
                    const parseClone = new ParseObject(item.part.data, item.part.data.partID.includes('canti') ? "CantileverParts" : "PalletRackingParts", item.id);
                    // const parseCloneClone = Object.assign(parseClone, {className: parseClone.get('partID').includes('canti') ? "CantileverParts" : "PalletRackingParts", id: item.id})
                    const part = { id: item.id, part: parseClone, quantity: item.quantity, price: item.price, section: item.section };
                    return dispatch(addToCart(part));
                });
                const quoteNumber = res[0].get('quotationNumber');
                if (res[0].get('selectedClient')?.data) {
                const cloneParse = Object.assign({}, res[0].get('selectedClient')?.data);
                cloneParse.parseObject = res[0].get('selectedClient')?.data;
                cloneParse.email = res[0].get('selectedClient')?.data?.email;
                cloneParse.label = cloneParse?.parseObject?.companyName + ' - ' + cloneParse?.firstName + ' ' + cloneParse?.lastName + ' - ' + cloneParse?.email + ' - ' + cloneParse?.phoneNumber;
                dispatch(setSelectedClient(cloneParse));}
                const selectedQuotationType = res[0].get("quotationType");
                const miscParts = res[0].get('miscParts');
                if (miscParts.length > 0) {
                    miscParts.map((item) => {
                        dispatch(addToMiscCartItems(item));
                    });
                };
                if (res[0].get('winProbability')) {
                    dispatch(setSelectedPercentageToWin(res[0].get('winProbability')));
                };
                dispatch(setSelectedQuotationType(selectedQuotationType.length > 0 ? selectedQuotationType : []))
                setQuotationNumberFound(quoteNumber);
                navigate('/cart');
            } else if (res.length === 0) {
                toast.warning(`No in-process quotation found with number ${quotationNumber}`);
            }
        } catch (error) {
            toast.error('Something went wrong');
            console.log('error: ', error);
            setIsLoading(false);
        }
    };

    const handleQuotaionNumberInputChange = (event) => {
        setQuotationNumber(event.target.value);
    }

    useEffect(() => {
        if (quotationNumberFound.length > 0) {
            dispatch(patchQuotationNumber(quotationNumberFound));
        }
    }, [quotationNumberFound])

    return (
        <>
            <ToastContainer />
            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Quotation Number
                </Typography>
                <InputBase
                    sx={{ width: 300 }}
                    placeholder="Quotation Number"
                    className="outlined border rounded p-1 mx-1"
                    name="quoteNumber"
                    value={quotationNumber}
                    disabled={isLoading}
                    onChange={handleQuotaionNumberInputChange}
                />
            </Box>
            {!isLoading ?
                <Box display={'flex'} justifyContent={'center'} alignContent={'center'} mt={2}>
                    <Button
                        sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        }}
                        onClick={getQuotation}
                    >
                        Look for Existing Quotation
                    </Button>
                </Box>
            : 
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mt={4}>
                <Typography
                    variant="p"
                    fontWeight="200"
                    color={colors.greenAccent[400]}
                >
                    Looking for quotation {quotationNumber}
                </Typography>
                <Spinner animation="border" role="status" size='sm' variant="primary"/>
            </Box>
            }
        </>
    )
}

export default ExistingQuotation