import React, { useEffect, useState } from "react";
import BasicTable from "./MUIBasicTable";
import Parse from "parse";
import { Typography, useTheme, Box, Button, Stack, Paper } from "@mui/material";
import { tokens } from "../theme";
import BasicDatePicker from "./BasicDatePicker";
import dayjs from 'dayjs';
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setQuotations } from "../features/cart/cartSlice";

const Quotations = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const currentDate = dayjs(new Date());
  const last14DaysDate = currentDate.subtract(7, 'day');
  const [selectedFromDate, setSelectedFromDate] = useState(last14DaysDate);
  const [selectedToDate, setSelectedToDate] = useState(dayjs());
  const quotationsData = useSelector((state) => state.cart.quotationsData);
  const [totalQuotationRowsPrice, setTotalQuotationRowsPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!quotationsData?.length > 0){
      getQuotationsData();
    }
  }, []);

  const getQuotationsData = async () => {
    try {
      setIsLoading(true);
      const quotationsObj = Parse.Object.extend("Quotations");
      const query = new Parse.Query(quotationsObj)
        .include("client")
        .include("createdBy")
        .include("updatedBy")
        .descending("createdAt");
        query.limit(250);
        query.lessThanOrEqualTo("createdAt", new Date(selectedToDate));
        query.greaterThanOrEqualTo("createdAt", new Date(selectedFromDate));
      const res = await query.find();
      dispatch(setQuotations(res));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Typography
        variant="h5"
        fontWeight="600"
        className="text-left"
        ml="1em"
        color={colors.greenAccent[400]}
      >
        Quotations
      </Typography>

      <Box display={'flex'} alignItems={'center'} ml={5} mb={3}>
        <Box mx={1}>
            <BasicDatePicker date={selectedFromDate} setDate={setSelectedFromDate} label={"From Date"}/>
        </Box>
        <Box mx={1}>
            <BasicDatePicker date={selectedToDate} setDate={setSelectedToDate} label={"To Date"}/>
        </Box>
        <Box mx={1}>
            <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  mt: '1em'
                }}
                onClick={getQuotationsData}
              >
                Search
              </Button>
        </Box>
    </Box>

      {!isLoading ? <Box className="d-flex justify-content-center align-items-center mb-5">
        <Paper elevation={3}>
          <BasicTable data={quotationsData} setTotalQuotationRowsPrice={setTotalQuotationRowsPrice}/>
        </Paper>
      </Box> : <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'70vh'}>
      <Spinner animation="border" role="status" size='sm' variant="primary"/>
        </Box>}
    </div>
  );
};

export default Quotations;
