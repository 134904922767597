export function getNextStringId(str) {
  let index = str.length - 1;
  let baseCode = str.charCodeAt(index);
  do {
    baseCode = str.charCodeAt(index);
    let strArr = str.split("");
    if (strArr[index] == "Z") {
      strArr[index] = "A";
      if (index == 0) {
        strArr.unshift("A");
      }
    } else {
      strArr[index] = String.fromCharCode(baseCode + 1);
    }
    str = strArr.join("");
    index--;
  } while (baseCode == 90);
  return str;
}

// getNextStringId("A") // B
// getNextStringId("Z") // AA
// getNextStringId("ABZZ") // ACAA
