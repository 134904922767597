export const COLUMN_TO_BAY_DIMENSTIONS = [
  {
    height: 2000,
    horizontal: 2,
    diagonal: 1,
    duty: "Light",
  },
  {
    height: 3000,
    horizontal: 3,
    diagonal: 1,
    duty: "Light",
  },
  {
    height: 4000,
    horizontal: 4,
    diagonal: 3,
    duty: "Light",
  },

  {
    height: 2500,
    horizontal: 3,
    diagonal: 1,
    duty: "Medium",
  },
  {
    height: 3000,
    horizontal: 3,
    diagonal: 1,
    duty: "Medium",
  },
  {
    height: 3500,
    horizontal: 4,
    diagonal: 2,
    duty: "Medium",
  },
  {
    height: 3600,
    horizontal: 3,
    diagonal: 2,
    duty: "Medium",
  },
  {
    height: 4000,
    horizontal: 4,
    diagonal: 3,
    duty: "Medium",
  },
  {
    height: 4500,
    horizontal: 4,
    diagonal: 3,
    duty: "Medium",
  },
  {
    height: 5000,
    horizontal: 5,
    diagonal: 3,
    duty: "Medium",
  },
  {
    height: 5020,
    horizontal: 5,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 5058,
    horizontal: 5,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 5500,
    horizontal: 5,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 5800,
    horizontal: 6,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 6000,
    horizontal: 6,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 6058,
    horizontal: 6,
    diagonal: 4,
    duty: "Medium",
  },
  {
    height: 6500,
    horizontal: 6,
    diagonal: 5,
    duty: "Medium",
  },
  {
    height: 7000,
    horizontal: 7,
    diagonal: 5,
    duty: "Medium",
  },
  {
    height: 7500,
    horizontal: 7,
    diagonal: 6,
    duty: "Medium",
  },
  {
    height: 8000,
    horizontal: 8,
    diagonal: 6,
    duty: "Medium",
  },
  {
    height: 8500,
    horizontal: 8,
    diagonal: 7,
    duty: "Medium",
  },
  {
    height: 9000,
    horizontal: 9,
    diagonal: 7,
    duty: "Medium",
  },
];
