import { InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";

const EmailCC = (props) => {
  const emailCC = props.emailCC;
  const setEmailCC = props.setEmailCC;

  return (

    <InputBase
        rows={1}
        sx={{ 'width': 300, padding: '0.25em' }}
        placeholder="abc@gmail.com, def@gmail.com, ..."
        name="email"
        value={emailCC}
        onChange={(e) => setEmailCC(e.target.value)}
    />

  );
};

export default EmailCC;
