import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, useTheme, InputBase, Typography } from "@mui/material";
import { tokens } from '../theme';
import { COLUMN_TO_BAY_DIMENSTIONS } from "../Constants/ColumnCenters";
import { useDispatch } from "react-redux";
import { addToCart } from '../features/cart/cartSlice';
import { ToastContainer, toast } from 'react-toastify';

const AutomateCantileverSet = () => {
  const [formData, setFormData] = useState({
    duty: '',
    baySides: '',
    finish: '',
    columnHeight: '',
    bayWidth: '',
    armsPerCol: 0,
    armLength: '',
    starterBays: '',
    addOnBays: ''
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [cantileverParts, setCantileverParts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getCantileverParts();
  }, []);


  const getCantileverParts = async () => {
    try {
      setIsLoading(true);
      const query = new Parse.Query(Parse.Object.extend("CantileverParts"));
      query.limit(1000);
      const res = await query.find();
      setCantileverParts(res);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log('error: ', error);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleArmsChange = (e) => {
    const { value } = e.target;
    const cloneFormData = Object.assign({}, formData)
    cloneFormData.armsPerCol = value;
    setFormData(cloneFormData);
  }

  const getClosestHeightToBayDimensions = (arr, height) => {
    console.log(arr, height);
    const sortedArr = arr.map(d => d.height).sort((a, b) => Math.abs(a - height) - Math.abs(b - height));
    return arr.filter((x) => x.height === sortedArr[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      duty,
      baySides,
      finish,
      columnHeight,
      bayWidth,
      armsPerCol,
      armLength,
      starterBays,
      addOnBays
    } = formData;
    // Computations
    const filteredWithSideAndBase = cantileverParts.filter((part) => part.get('name') === 'Base' && part.get('finish') === finish && part.get('duty') === duty && part.get('sides') === (baySides === 'Double' ? '2' : '1') && (part.get('armToSuitBase')).replace(/\s+/g, '') === armLength);
    const arm = cantileverParts.filter((part) => part.get('name') === 'Arm' && part.get('finish') === finish && part.get('duty') === duty && part.get('depth') === armLength);
    const finalBaseAndArm = {base: filteredWithSideAndBase[0], arm: arm[0]};

    const dutyFiltered = COLUMN_TO_BAY_DIMENSTIONS.filter((x) => x.duty === duty);
    const res  = getClosestHeightToBayDimensions(dutyFiltered, parseInt(columnHeight.substring(0, columnHeight.length - 2)));
    const bracingsForSelectedBayWidth = cantileverParts.filter((part) => part.get('name') === 'Bracing' && part.get('finish') === finish && part.get('duty') === duty && part.get('bayCenter').split(' ')[1] === bayWidth);
    const finalBracings = {
      partDiagonal: bracingsForSelectedBayWidth.filter(
        (p) => p?.get("bayCenter").split(" ")[0] === "Diagonal"
      )[0],
      partHorizontal: bracingsForSelectedBayWidth.filter(
        (p) => p?.get("bayCenter").split(" ")[0] === "Horizontal"
      )[0],
      quantityDiagonal: res[0].diagonal,
      quantityHorizontal: res[0].horizontal,
      totalPrice:
        bracingsForSelectedBayWidth
          .filter((p) => p?.get("bayCenter").split(" ")[0] === "Diagonal")[0]
          .get("price") *
          res[0].diagonal +
        bracingsForSelectedBayWidth
          .filter(
            (p) => p?.get("bayCenter").split(" ")[0] === "Horizontal"
          )[0]
          .get("price") *
          res[0].horizontal,
    };


    const finalAutomatedSet = {
      totalStarterBays: parseInt(starterBays),
      totalAddOnBays: parseInt(addOnBays), 
      base: finalBaseAndArm.base,
      baseQuantity: parseInt(parseInt(starterBays) + parseInt(starterBays) + (parseInt(starterBays)*(parseInt(addOnBays)))),
      arm: finalBaseAndArm.arm,
      column: (cantileverParts.filter((part) => part.get('name') === "Column" && part.get('finish') === finish && part.get('duty') === duty && part.get('height') === columnHeight)[0]),
      columnQuantity: parseInt(parseInt(starterBays) + parseInt(starterBays) + (parseInt(starterBays)*(parseInt(addOnBays)))),
      horizontalBracing: finalBracings.partHorizontal, 
      diagonalBracing: finalBracings.partDiagonal, 
      horizontalQuantity: (finalBracings.quantityHorizontal * parseInt(starterBays)) + (parseInt(addOnBays) * finalBracings.quantityHorizontal), 
      diagonalQuantity:  (finalBracings.quantityDiagonal * parseInt(starterBays)) + (parseInt(addOnBays) * finalBracings.quantityDiagonal), 
      due: finalBracings.quantityDiagonal,
      totalPrice: ( (parseFloat(finalBracings.partDiagonal.get('price') * (finalBracings.quantityDiagonal * parseInt(starterBays)) + (parseInt(addOnBays) * finalBracings.quantityDiagonal))) + (parseFloat(finalBracings.partHorizontal.get('price') * (finalBracings.quantityHorizontal * parseInt(starterBays)) + (parseInt(addOnBays) * finalBracings.quantityHorizontal))) + (parseInt(parseInt(starterBays) + 1 + parseInt(addOnBays)) * parseFloat(cantileverParts.filter((part) => part.get('name') === "Column" && part.get('finish') === finish && part.get('duty') === duty && part.get('height') === columnHeight)[0].get('price')))) 
    };


    automateAndAddToCart(finalAutomatedSet)
  };

  const automateAndAddToCart = (finalAutomatedSet) => {
    dispatch(
      addToCart({
        id: finalAutomatedSet["column"].id,
        part: finalAutomatedSet["column"],
        price: parseFloat(finalAutomatedSet["column"].get("price")),
        quantity: finalAutomatedSet["columnQuantity"],
        section: 1,
      })
    );
    dispatch(
      addToCart({
        id: finalAutomatedSet["horizontalBracing"].id,
        part: finalAutomatedSet["horizontalBracing"],
        price: parseFloat(finalAutomatedSet["horizontalBracing"].get("price")) * finalAutomatedSet["horizontalQuantity"],
        quantity: finalAutomatedSet["horizontalQuantity"],
        section: 1,
      })
    );
    dispatch(
      addToCart({
        id: finalAutomatedSet["diagonalBracing"].id,
        part: finalAutomatedSet["diagonalBracing"],
        price: parseFloat(finalAutomatedSet["diagonalBracing"].get("price")) * finalAutomatedSet["diagonalQuantity"],
        quantity: finalAutomatedSet["diagonalQuantity"],
        section: 1,
      })
    );
    dispatch(
      addToCart({
        id: finalAutomatedSet["base"].id,
        part: finalAutomatedSet["base"],
        price: parseFloat(finalAutomatedSet["base"].get("price")) * finalAutomatedSet["baseQuantity"],
        quantity: finalAutomatedSet["baseQuantity"],
        section: 1,
      })
    );
    dispatch(
      addToCart({
        id: finalAutomatedSet["arm"].id,
        part: finalAutomatedSet["arm"],
        price: parseFloat(finalAutomatedSet["arm"].get("price")) * parseInt(armsPerCol) * finalAutomatedSet["columnQuantity"],
        quantity: parseInt(armsPerCol) * finalAutomatedSet["columnQuantity"],
        section: 1,
      })
    );
    toast.success("Successfully automated & added to cart");
  };

  const {
    duty,
    baySides,
    finish,
    columnHeight,
    bayWidth,
    armsPerCol,
    armLength,
    starterBays,
    addOnBays
  } = formData;

  return (
    <Box sx={{ maxWidth: 400, margin: "auto", padding: 2 }}>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <InputLabel id="duty-label">Duty:</InputLabel>
          <Select
            labelId="duty-label"
            id="duty"
            name="duty"
            value={formData.duty}
            label="Duty"
            onChange={handleChange}
          >
            <MenuItem value={"Light"}>Light</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"Heavy"}>Heavy</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="baySides-label">Bay Sides:</InputLabel>
          <Select
            labelId="baySides-label"
            id="baySides"
            name="baySides"
            value={baySides}
            label="Bay Sides"
            onChange={handleChange}
            disabled={!duty}
          >
            <MenuItem value={"Single"}>Single</MenuItem>
            <MenuItem value={"Double"}>Double</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="finish-label">Finish:</InputLabel>
          <Select
            labelId="finish-label"
            id="finish"
            name="finish"
            value={finish}
            label="Finish"
            onChange={handleChange}
            disabled={!baySides}
          >
            <MenuItem value={"Blue"}>Blue Powder Coated</MenuItem>
            <MenuItem value={"Galv"}>Hot Dip Galvanized</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="columnHeight-label">Column Height:</InputLabel>
          <Select
            labelId="columnHeight-label"
            id="columnHeight"
            name="columnHeight"
            value={columnHeight}
            label="Column Height"
            onChange={handleChange}
            disabled={!finish}
          >
            {cantileverParts
              .filter(
                (part) =>
                  part.get("name") === "Column" &&
                  part.get("finish") === finish &&
                  part.get("duty") === duty
              ).sort((a, b) => {
                const heightA = parseInt(a.get("height").replace('mm', ''), 10);
                const heightB = parseInt(b.get("height").replace('mm', ''), 10);
                return heightA - heightB;
            })
              .map((part, i) => (
                <MenuItem key={i} value={part?.get("height")}>
                  {part?.get("height")}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="bayWidth-label">Bay Width:</InputLabel>
          <Select
            labelId="bayWidth-label"
            id="bayWidth"
            name="bayWidth"
            value={bayWidth}
            label="Bay Width"
            onChange={handleChange}
            disabled={!columnHeight}
          >
            {cantileverParts.filter((part) => part.get('name') === 'Bracing' && part.get('finish') === finish && part.get('duty') === duty)?.reduce((acc, item) => {
              const exists = acc.some(x => x.get('bayCenter').split(' ')[1] === item.get('bayCenter').split(' ')[1]);
              if (!exists) {
                acc.push(item);
              }
              return acc;
            }, []).map((part, i) => (
              <MenuItem key={i} value={part.get('bayCenter').split(' ')[1]}>{part.get('bayCenter').split(' ')[1]}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1 }}>
          <Typography>Arms per Column:</Typography>
          <InputBase placeholder='Arms per Column:' value={formData.armsPerCol} onChange={handleArmsChange} type='number' sx={{ flex: 1, width: '100%', border: "1px solid gray", borderRadius: '4px', p: 1 }}/>
        </FormControl>


        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="armLength-label">Arm Length:</InputLabel>
          <Select
            labelId="armLength-label"
            id="armLength"
            name="armLength"
            value={armLength}
            label="Arm Length"
            onChange={handleChange}
            disabled={!armsPerCol}
          >
            {cantileverParts.filter((part) => part.get('name') === 'Arm' && part.get('finish') === finish && part.get('duty') === duty).map((part, i) => (
              <MenuItem key={i} value={part.get("depth")}>
                {part.get("depth")}
              </MenuItem>
            ))
            }
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="starterBays-label">Starter Bays:</InputLabel>
          <Select
            labelId="starterBays-label"
            id="starterBays"
            name="starterBays"
            value={starterBays}
            label="Starter Bays"
            onChange={handleChange}
            disabled={!armLength}
          >
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"6"}>6</MenuItem>
            <MenuItem value={"7"}>7</MenuItem>
            <MenuItem value={"8"}>8</MenuItem>
            <MenuItem value={"9"}>9</MenuItem>
            <MenuItem value={"10"}>10</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="addOnBays-label">Add-On Bays:</InputLabel>
          <Select
            labelId="addOnBays-label"
            id="addOnBays"
            name="addOnBays"
            value={addOnBays}
            label="Add-On Bays"
            onChange={handleChange}
            disabled={!starterBays}
          >
            <MenuItem value={"1"}>1</MenuItem>
            <MenuItem value={"2"}>2</MenuItem>
            <MenuItem value={"3"}>3</MenuItem>
            <MenuItem value={"4"}>4</MenuItem>
            <MenuItem value={"5"}>5</MenuItem>
            <MenuItem value={"6"}>6</MenuItem>
            <MenuItem value={"7"}>7</MenuItem>
            <MenuItem value={"8"}>8</MenuItem>
            <MenuItem value={"9"}>9</MenuItem>
            <MenuItem value={"10"}>10</MenuItem>
          </Select>
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            mt: "1em",
          }}
          disabled={isLoading || (Object.values(formData).some(value => value === ''))}
        >
          Automate and Add to Cart
        </Button>
      </form>
    </Box>
  );
};

export default AutomateCantileverSet;
