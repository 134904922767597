import { Box, Grid, InputBase, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useState, useEffect, useRef } from "react";
import Parse from 'parse'
import { Spinner } from "react-bootstrap";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SelectClients from "./selectClient";
import EmailCC from "./EmailCC";
import SelectQuotationType from "./SelectquotationType";
import { patchQuotationNumber, setSelectedPercentageToWin } from "../features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import ReplayIcon from '@mui/icons-material/Replay';
import { useSearchParams } from "react-router-dom";

const CreateQuotationNewOrExisting = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const quotationNumber = useSelector((state) => state.cart.quotationNumber);
    const revisionInProgress = useSelector((state) => state.cart.revisionInProgress);
    const emailCC = props.emailCC;
    const selectedPercentageToWin = useSelector((state) => state.cart.quotationPercentageToWin);
    const percentagesToWin = [{label: "less than or equal to 50%", value: 50}, {label: "within 80% - 90%", value: 75}, {label: "Certain - 100%", value: 100}];
    const setEmailCC = props.setEmailCC;
    const quotationStatus = props.quotationStatus;
    const setQuotationStatus = props.setQuotationStatus;
    const selectedClient = props.selectedClient;
    const setValidateQuotationNumber = props.setValidateQuotationNumber;
    const [fetchingClients, setFetchingClients] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get("type")

    const [checkingQuotationAvailbility, setCheckingQuotationAvailbility] = useState(false);
    const [quotationIfFound, setQuotationIfFound] = useState();
    const timerRef = useRef(null);
    const [validateOnInitialSet, setValidateOnInitialSet] = useState(false);

    const [requestForClientsReload, setRequestForClientsReload] = useState(false);

    const quotationStatusesOperations = ['in-process', 'open', 'won', 'hold', 'deposit', 'cancelled', 'confirm'];

    useEffect(() => {
        return () => {
            clearTimeout(timerRef.current);
        };
    }, []);

    const handleQuotationStatusChange = (event) => {
        setQuotationStatus(event.target.value);
    }
    const handleQuotaionNumberInputChange = (event) => {
        const { value } = event.target;
        dispatch(patchQuotationNumber(value));
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            validateQuotationNumber(value);
        }, 1500);
    };

    const validateQuotationNumber = async (value) => {
        setQuotationIfFound();
        if (value) {
            const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations'));
            quotationsQuery.fullText('quotationNumber', value);
            try {
                setCheckingQuotationAvailbility(true);
                const res = await quotationsQuery.find();
                setCheckingQuotationAvailbility(false);
                if (res.length > 0) {
                    setQuotationIfFound(res);
                    return true;
                } else {
                    setQuotationIfFound(res);
                    return false;
                }
            } catch (error) {
                setCheckingQuotationAvailbility(false);
                console.log('error: ', error);
                return false;
            }
        }
    }

    useEffect(() => {
        if (quotationNumber.length > 0 && !validateOnInitialSet && !revisionInProgress) {
            validateQuotationNumber(quotationNumber);
            setValidateOnInitialSet(true);
        }
    }, [quotationNumber]);

    const reloadClients = () => {
        setRequestForClientsReload(true);
    };

    useEffect(() => {
        setValidateQuotationNumber(quotationIfFound?.length !== 0 || quotationNumber.length === 0)
    }, [quotationIfFound, quotationNumber]);

    return (
        <>
        <hr color="white" />
        <Box my={2}>
            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Quotation Number
                </Typography>
                <InputBase
                    sx={{ width: 300 }}
                    placeholder="Quotation Number"
                    className="outlined border rounded p-1 mx-1"
                    name="quoteNumber"
                    value={quotationNumber}
                    disabled={checkingQuotationAvailbility || revisionInProgress}
                    onChange={handleQuotaionNumberInputChange}
                />
                {checkingQuotationAvailbility && <Spinner animation="border" role="status" size='sm' variant="primary"/>}
                {(quotationIfFound?.length === 0 && quotationNumber.length > 0) ? <CheckCircleIcon /> : (quotationIfFound?.length > 0 && !revisionInProgress) ? <Box display={'flex'} flexDirection={'column'}>
                <p className="text-danger m-0">Quotation Number Already Exists</p>
                </Box> : null}

                <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} width={'50%'}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >
                        <Typography
                        variant="h6"
                        fontWeight="400"
                        mx={1}
                        color={colors.greenAccent[400]}
                        >
                        Probability of winning the quotation?
                        </Typography>
                        <Select
                        value={selectedPercentageToWin} onChange={(e) => dispatch(setSelectedPercentageToWin(e.target.value))} 
                        >
                        {percentagesToWin.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))}
                        </Select>
                    </Box>
                </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Select Client
                </Typography>
                <Box ml={6} display={'flex'} alignItems={'center'}>
                    <SelectClients fetchingClients={fetchingClients} setFetchingClients={setFetchingClients} disabled={quotationIfFound?.length > 0} selectedClient={selectedClient} requestForClientsReload={requestForClientsReload} setRequestForClientsReload={setRequestForClientsReload}/>
                    {(!fetchingClients) ? <ReplayIcon className="cursor-pointer" onClick={reloadClients} sx={{marginLeft: 1}}/> : 
                    <Box display='flex'>
                        <Spinner className="mx-1" animation="border" role="status" size='sm' variant="primary"/>
                        <Typography fontSize={12}
                        color={colors.greenAccent[400]}>Refreshing clients, might take a moment</Typography>
                    </Box>
                    }
                </Box>
            </Box>


            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Email CC
                </Typography>
                <Box ml={6}>
                    <Box p={'10px 0px'} border='1px solid grey' borderRadius='4px' ml={3}>
                        <EmailCC emailCC={emailCC} setEmailCC={setEmailCC}/>
                    </Box>
                </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Quotation Type
                </Typography>
                <Box ml={3}>
                    <SelectQuotationType />
                </Box>
            </Box>

            <Box display={'flex'} alignItems={'center'} my={1}>
                <Typography
                    variant="h6s"
                    fontWeight="200"
                    mx="2em"
                    color={colors.greenAccent[400]}
                >
                    Quotation Status
                </Typography>
                <Box ml={2}>
                    <Select
                        sx={{ width: 300 }}
                        disabled={true}
                        value={quotationStatus || ''} onChange={handleQuotationStatusChange} 
                        >
                        {quotationStatusesOperations.map((status, index) => (
                            <MenuItem key={index} value={status}>{status}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>

            {/* <Typography
                variant="h4"
                fontWeight="800"
                mx="1em"
                mt={3}
                color={colors.greenAccent[500]}
            >
                Products on quote
            </Typography> */}
            <hr color="white" />

            {/* PRODUCT HEADER */}

            {/* <Grid container spacing={1}>
                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                    variant="p"
                    fontWeight="800"
                    color={colors.greenAccent[500]}
                    >
                        Product Name
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                    variant="p"
                    fontWeight="800"
                    color={colors.greenAccent[500]}
                    >
                       Finish
                    </Typography>
                </Grid>
                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                        variant="p"
                        fontWeight="800"
                        color={colors.greenAccent[500]}
                    >
                        Duty
                    </Typography>
                </Grid>

                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                        variant="p"
                        fontWeight="800"
                        color={colors.greenAccent[500]}
                    >
                        Rate
                    </Typography>
                </Grid>

                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                        variant="p"
                        fontWeight="800"
                        color={colors.greenAccent[500]}
                    >
                        Unit
                    </Typography>
                </Grid>

                <Grid item xs={2} textAlign={'center'}>
                    <Typography
                        variant="p"
                        fontWeight="800"
                        color={colors.greenAccent[500]}
                    >
                        Subtotal
                    </Typography>
                </Grid>
            </Grid> */}

            {/* <QuotationProduct /> */}


        </Box>
        </>
    )

}

export default CreateQuotationNewOrExisting;
