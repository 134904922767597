import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import Parse from 'parse';
import Importer from "./importer";
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import CustomInventoryAddition from "./customInventroyAddition";
import IncrementDecrementPrices from "./IncrementOrDecrementPricesInInventory";
import AddPalletRackingPart from "./addPalletRackingPart";
import AddPalletRackingPartUsed from "./addPalletRackingPartUsed";
import { Spinner } from "react-bootstrap";


const AddCantileverPart = () => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [depth, setDepth] = useState("");
  const [sides, setSides] = useState("");
  const [armToSuitBase, setArmCapacity] = useState("");
  const [BayType, setBayType] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [price, setPrice] = useState(0);
  const [duty, setDuty] = useState("");
  const [weight, setWeight] = useState("");
  const [addCantilverPartEnabled, setAddCantileverPartEnabled] = useState(false);
  const [addCantilverPartNewEnabled, setAddCantileverPartNewEnabled] = useState(false);
  const [addPalletRackingPart, setaddPalletRackingPart] = useState(false);
  const [clientImporter, setClientImporter] = useState(false);
  const [addPalletRackingPartUsed, setaddPalletRackingPartUsed] = useState(false);
  const [importerEnabled, setImporterEnabled] = useState(false);
  const [className, setClassName] = useState('');
  const [classNameLoading, setClassNameLoading] = useState(false);
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colors.greenAccent[600],
      '&:hover': {
        backgroundColor: alpha(colors.greenAccent[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.greenAccent[600],
    },
  }));
  const [incrementDecrementPrices, setIncrementDecrementPrices] = useState(false);

  const updateClassesObjects = async () => {
    setClassNameLoading(true);
    const parseQuery = new Parse.Query(Parse.Object.extend(className));
    parseQuery.limit(1000);
    try {
      const res = await parseQuery.find();
      res.map(async (part, index) => {
        part.set('partID', className === 'CantileverParts' ? 'canti-'+(index+1) : className === 'PalletRackingParts' ? 'pallet-'+(index+1) : className === 'PalletRackingPartsUsed' ? 'pallet-used-'+(index+1) : '');
        await part.save();
      });
      setClassNameLoading(false);
    } catch (error) {
      setClassNameLoading(false);
      console.log(error);
    }
  }


  const submitPart = async () => {
    console.log('submit');
    const CantileverPart = Parse.Object.extend('CantileverParts');
    const part = new CantileverPart();
    part.set('name', name);
    part.set('finish', type);
    part.set('height', height);
    part.set('width', width);
    part.set('sides', sides);
    part.set('armToSuitBase', armToSuitBase);
    part.set('bayCenter', BayType);
    part.set('depth', depth);
    part.set('price', price);
    part.set('duty', duty);
    part.set('weight', weight);
    await part.save();
    var elements = document.getElementsByTagName("input");
    for (var ii=0; ii < elements.length; ii++) {
      if (elements[ii].type == "text") {
        elements[ii].value = "";
      }
    }
  }

  return (
    <div>
      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={addCantilverPartEnabled}
          onChange={(e) => setAddCantileverPartEnabled(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={true}
        />
        <p style={{fontSize: '18px'}}>Add Cantilever Part (OLD)</p>
      </div>
      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={addCantilverPartNewEnabled}
          onChange={(e) => setAddCantileverPartNewEnabled(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={false}
        />
        <p style={{fontSize: '18px'}}>Add Cantilever Part (NEW)</p>
      </div>
      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={addPalletRackingPart}
          onChange={(e) => setaddPalletRackingPart(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={false}
        />
        <p style={{fontSize: '18px'}}>Add Pallet Racking Part</p>
      </div>

      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={addPalletRackingPartUsed}
          onChange={(e) => setaddPalletRackingPartUsed(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={false}
        />
        <p style={{fontSize: '18px'}}>Add Pallet Racking Part Used</p>
      </div>

      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={importerEnabled}
          onChange={(e) => setImporterEnabled(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={false}
        />
        <p style={{fontSize: '18px'}}>Importer</p>
      </div>
      <div className="d-flex align-items-baseline">
        <GreenSwitch
          checked={incrementDecrementPrices}
          onChange={(e) => setIncrementDecrementPrices(e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
          disabled={false}
        />
        <p style={{fontSize: '18px'}}>Increment Decrement Prices</p>
      </div>
      {addCantilverPartEnabled && <div>
        <div className="d-flex justify-content-evenly flex-wrap">
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Name
            <input value={name} onChange={(e) => setName(e.target.value)}></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Type
            <input value={type} onChange={(e) => setType(e.target.value)}></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Height
            <input
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Width
            <input
              value={width}
              onChange={(e) => setWidth(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            depth
            <input
              value={depth}
              onChange={(e) => setDepth(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Sides
            <input
              value={sides}
              onChange={(e) => setSides(e.target.value)}
            ></input>
          </label>
          {/* new cols */}
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Weight
            <input
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            Duty
            <input
              value={duty}
              onChange={(e) => setDuty(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            armToSuitBase
            <input
              value={armToSuitBase}
              onChange={(e) => setArmCapacity(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            bayCenter
            <input
              value={BayType}
              onChange={(e) => setBayType(e.target.value)}
            ></input>
          </label>
          <label className="col-md-3 col-lg-3 col-3 mx-1">
            price
            <input
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            ></input>
          </label>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => submitPart()}>
            Submit
          </button>
        </div>
      </div>}

      {!classNameLoading ?  <div>
        <input onChange={(e) => setClassName(e.target.value)}/> <button onClick={updateClassesObjects}>UPDATE</button>
      </div> : <Spinner animation="border" role="status" size='sm' variant="primary"/>}

      {addCantilverPartNewEnabled && <CustomInventoryAddition />}


      {addPalletRackingPart && <AddPalletRackingPart />}

      {/* {clientImporter && } */}

      {addPalletRackingPartUsed && <AddPalletRackingPartUsed />}


      {importerEnabled && <Importer/>}


      {incrementDecrementPrices && <IncrementDecrementPrices />}

    </div>
  );
};

export default AddCantileverPart;
