import React, { useState, useRef, useEffect, useMemo } from "react";
import * as Parse from "parse";
import column from "../assets/images/column.png";
import columnGalv from "../assets/images/galv-column.png";
import armRed from '../assets/images/arm-red.png';
import armGalv from '../assets/images/arm-galv.png';
import base from '../assets/images/base-blue.png';
import baseGalv from '../assets/images/base-galv.png';
import baseDouble from '../assets/images/base-double-blue.png';
import baseDoubleGalv from '../assets/images/base-double-galv.png';
import braceDiagonal from '../assets/images/brace-diagonal.png';
import braceDiagonalGalv from '../assets/images/brace-diagonal-galv.png';
import braceHorizontal from '../assets/images/brace-horizontal.png';
import braceHorizontalGalv from '../assets/images/brace-horizontal-galv.png';
import { useSelector, useDispatch } from 'react-redux'
import { prop } from "ramda";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Backdrop, Box, Button, Fade, IconButton, InputBase, List, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { addToCart } from "../features/cart/cartSlice";
import accessories from '../assets/images/settings.png';
import sprayCan from '../assets/images/sprayCan.jpeg';
import roofArms from '../assets/images/roofArms.jpeg';
import spacerTube from '../assets/images/spacerTube.jpeg';
import TEKScrew from '../assets/images/TEKScrew.jpeg';
import topHats from '../assets/images/topHats.jpeg';
import nut from '../assets/images/nut.jpeg';
import guidRailsCircular from '../assets/images/guidRailsCircular.jpeg';
import guideRail from '../assets/images/guideRail.jpeg';
import armBaseGlutDBars from '../assets/images/armBaseGlutDBars.jpeg';
import ArmBaseGlutDBarsGalv from '../assets/images/ArmBaseGlutDBarsGalv.jpeg';
import ArmBracket from '../assets/images/ArmBracket.png';
import armsSupport from '../assets/images/armsSupport.jpeg';
import armsSupportGalv from '../assets/images/ArmsSupportGalv.jpeg';
import baseBracket from '../assets/images/baseBracket.png';
import baseSupport from '../assets/images/baseSupport.jpeg';
import baseSupportGalv from '../assets/images/BaseSupportGalv.jpeg';
import roofSheets from '../assets/images/roofSheets.jpeg';
import { ParseObject } from "./ExistingQuotation";

export const COLUMN_NAME = "Column";
export const ARM_NAME = "Arm";
export const BASE_NAME = "Base";
export const BRACE_NAME = "Bracing";
export const ACCESSORY = "Accessory";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CantileverPart = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [quantity, setQuantity] = useState(0);
	const [price] = useState(props.part.get('price'));
	const [cartItems, setCartItems] = useState();
	const [itemQuantityAvailable, setItemQuantityAvailable] = useState();
	const dispatch = useDispatch();
	const cartItemsGlobal = useSelector((state) => state.cart.cartItems)
	const currentActiveSection = useSelector((state) => state.cart.sections.currentActive)
	const toastId = React.useRef(null);
	const cartSection = useSelector((state) => state.cart.sections);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalHeading2, setModalHeading2] = useState('');


	useEffect(() => {
		if (cartItemsGlobal?.length >=  1) {
			cartItemsGlobal.forEach(element => {
				if (element.part.id === props.part.id && element.section === cartSection.currentActive) {
          setItemQuantityAvailable(props.part.get('quantity') - element.quantity);
					setQuantity(element.quantity)
				}
				else {
					setItemQuantityAvailable(props.part.get('quantity'));
				}
			});
		}
		else {
			setItemQuantityAvailable(props.part.get('quantity'));
		}
	}, [cartItemsGlobal])

	const incremet = () => { setQuantity(quantity + 1); setItemQuantityAvailable(itemQuantityAvailable - 1) };
	const decrement = () => { setQuantity(quantity - 1); setItemQuantityAvailable(itemQuantityAvailable + 1) };

  const checkForContrary = () => {
    if (cartItemsGlobal?.length >= 1){
      const baseExists = cartItemsGlobal.find((p) => p.part.get('name') === 'Base');
      const ArmExists = cartItemsGlobal.find((p) => p.part.get('name') === 'Arm');
      const horizontalBracingExists = cartItemsGlobal.find((p) => p.part.get('layout')?.trim() === 'Horizontal');
      const horizontalBayCenter = (horizontalBracingExists?.part.get('bayCenter').split(' ')[1])?.trim();
      const diagonalBracingExists = cartItemsGlobal.find((p) => p.part.get('layout')?.trim() === 'Diagonal');
      const diagonalBayCenter = (diagonalBracingExists?.part.get('bayCenter').split(' ')[1])?.trim();
      if 
        (cartItemsGlobal[0].part.get('finish') !==
      props.part.get('finish') && cartItemsGlobal[0].section.currentActive === currentActiveSection.currentActive && baseExists !== undefined && props.part.get('name') === 'Arm' && props.part.get('depth') !== Object.values(baseExists)[1].get('armToSuitBase') )
       {
        setModalHeading2('Adding contrary polished piece');
        setModalHeading('Adding contrary Arm relative to already selected Base');
        setOpen(true);
      }
      else if (cartItemsGlobal[0].part.get('finish') !== props.part.get('finish') && cartItemsGlobal[0].section.currentActive === currentActiveSection.currentActive && horizontalBayCenter !== diagonalBayCenter ) {
        setModalHeading2('Adding contrary polished piece');
        setModalHeading('Adding contrary brace relative to already selected brace');
        setOpen(true);
      }
      else if (cartItemsGlobal[0].section.currentActive === currentActiveSection.currentActive && props.part.get('name') === 'Bracing' && props.part.get('layout').trim() === 'Diagonal' && horizontalBracingExists && horizontalBayCenter !== (props.part.get('bayCenter').split(' ')[1])?.trim()) {
        setModalHeading('Adding contrary brace relative to already selected brace');
        setOpen(true);
      }

      else if (cartItemsGlobal[0].section.currentActive === currentActiveSection.currentActive && props.part.get('name') === 'Bracing' && props.part.get('layout').trim() === 'Horizontal' && diagonalBracingExists && diagonalBayCenter !== (props.part.get('bayCenter').split(' ')[1])?.trim()) {
        setModalHeading('Adding contrary brace relative to already selected brace');
        setOpen(true);
      }

      else if (baseExists !== undefined && props.part.get('name') === 'Arm' && props.part.get('depth') !== Object.values(baseExists)[1].get('armToSuitBase').replace(/ /g,'') ) {
        setModalHeading('Adding contrary Arm relative to already selected Base');
        setOpen(true);
      }
      else if (ArmExists !== undefined && props.part.get('name') === 'Base' && props.part.get('armToSuitBase').replace(/ /g,'') !== Object.values(ArmExists)[1].get('depth') ) {
        setModalHeading('Adding contrary Arm relative to already selected Base');
        setOpen(true);
      }
      else if (cartItemsGlobal[0].part.get('finish') !==
      props.part.get('finish') && cartItemsGlobal[0].section.currentActive === currentActiveSection.currentActive) {
        setModalHeading('Adding contrary polished piece');
        setOpen(true);
        setOpen(true);
      }
      else {
        addToCart1();
      }
    } else {
      addToCart1();
    }
  }


	const totalPrice = useMemo(() => {
		return price * quantity;
	}, [quantity]);

	const addToCart1 = () => {
		const item = { id: props.part.id, part: new ParseObject(JSON.parse(JSON.stringify(props.part)), props.part.className, props.part.id), quantity: quantity, price: totalPrice, section: currentActiveSection };
		dispatch(addToCart(item));
    toast.success("Part Successfully Added To Cart!");
    handleClose()
	}

	const checkForContraryItemsInCart = () => {
		if (cartItemsGlobal?.length >= 1) {
			cartItemsGlobal.some(element => {
				if (element.part.get('finish') !== props.part.get('finish')) {
					if (!toast.isActive(toastId.current)) {
						toastId.current = toast("Adding contrary polished piece");
					}
				}
			})
		}
	}

	return (
    <Box
      gridColumn="span 4"
      gridRow="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb="0.5em"
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            {modalHeading2.length > 0 ? (
              <Typography
              variant="h5"
              fontWeight="600"
              className="text-center"
              color={colors.greenAccent[400]}
              >
                {modalHeading2}
                <br />
                <Box display="flex" justifyContent="center">
                  AND
                </Box>
              </Typography>
            ) : null}
            <Typography
              variant="h5"
              fontWeight="600"
              className="text-center"
              color={colors.greenAccent[400]}
            >
              {modalHeading}
            </Typography>
            <Typography
              id="transition-modal-description"
              className="secondary-color text-center"
              sx={{ mt: 2 }}
            >
              Do you want to continue?
            </Typography>
            <Box display="flex" justifyContent="space-between" mt="1em">
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={addToCart1}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Box className="d-flex justify-content-between">
        <Box>
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "10px 10px 0 10px" }}
            color={colors.greenAccent[400]}
          >
            {props.part.get("name")}{" "}
            {props.part.get("name") === "Arm" &&
            props.part.get('finish') === "Blue"
              ? "Orange"
              : props.part.get('finish')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="h4"
            fontWeight="800"
            sx={{ padding: "10px 10px 0 10px" }}
          >
            Available - {parseInt(itemQuantityAvailable) >= 0 ? itemQuantityAvailable : 0}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my="0.5rem"
      >
        <img
          alt="item_image"
          width="200px"
          height="200px"
          src={
            props.part.get("name") === COLUMN_NAME &&
            props.part.get('finish') === "Blue"
              ? column
              : props.part.get('finish') === "Galv" &&
                props.part.get("name") === COLUMN_NAME
              ? columnGalv
              : props.part.get("name") === ARM_NAME &&
                props.part.get('finish') === "Blue"
              ? armRed
              : props.part.get("name") === ARM_NAME &&
                props.part.get('finish') === "Galv"
              ? armGalv
              : props.part.get("name") === BASE_NAME &&
                props.part.get('finish') === "Blue" &&
                props.part.get("sides") === "1"
              ? base
              : props.part.get("name") === BASE_NAME &&
                props.part.get('finish') === "Galv" &&
                props.part.get("sides") === "1"
              ? baseGalv
              : props.part.get("name") === BASE_NAME &&
                props.part.get('finish') === "Blue" &&
                props.part.get("sides") === "2"
              ? baseDouble
              : props.part.get("name") === BASE_NAME &&
                props.part.get('finish') === "Galv" &&
                props.part.get("sides") === "2"
              ? baseDoubleGalv
              : props.part.get("name") === BRACE_NAME &&
                props.part.get("bayCenter").split(" ")[0] === "Diagonal" &&
                props.part.get('finish') === "Blue"
              ? braceDiagonal
              : props.part.get("name") === BRACE_NAME &&
                props.part.get("bayCenter").split(" ")[0] === "Diagonal" &&
                props.part.get('finish') === "Galv"
              ? braceDiagonalGalv
              : props.part.get("name") === BRACE_NAME &&
                props.part.get("bayCenter").split(" ")[0] === "Horizontal" &&
                props.part.get('finish') === "Galv"
              ? braceHorizontalGalv
              : props.part.get("name") === BRACE_NAME &&
                props.part.get("bayCenter").split(" ")[0] === "Horizontal" &&
                props.part.get('finish') === "Blue"
              ? braceHorizontal
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('SPRAY')
              ? sprayCan
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Base Bracket')
              ? baseBracket
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Arms Support Bars (H Bars)')
              ? armsSupport
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Base Support Bars')
              ? baseSupport
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Arm / Base Glut Arm (D Bars)')
              ? armBaseGlutDBars
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Arm Bracket')
              ? ArmBracket
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Arms Support Bars') && props.part.get('finish') === "Galv"
              ? armsSupportGalv
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Base Support Bars') && props.part.get('finish') === "Galv"
              ? baseSupportGalv
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Arm / Base Glut Arm (D Bars)') && props.part.get('finish') === "Galv"
              ? ArmBaseGlutDBarsGalv
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('NUT')
              ? nut
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('SPACER')
              ? spacerTube
              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('TEK SCREW')
              ? TEKScrew

              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('GUIDE RAIL (UNEQUAL ANGLE)')
              ? guideRail

              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('GUIDE RAILS (CIRCULAR UNEQAUL ANGLE)')
              ? guidRailsCircular

              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Roof Arms')
              ? roofArms

              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Top Hats')
              ? topHats

              : props.part.get("category") === ACCESSORY && 
              props.part.get('name').includes('Sheets for Roof')
              ? roofSheets
              : accessories
          }
          style={{ cursor: "pointer", borderRadius: "50%" }}
        />
      </Box>
      <Typography
        variant="h6"
        fontWeight="600"
        mt="5px"
        mb="0"
        display="flex"
        justifyContent="center"
      >
        {props.part.get("name") === COLUMN_NAME ? <ul>
          <li>{props.part.get("height")} High / {props.part.get("width")}</li>
        </ul> : props.part.get('name') === BASE_NAME ? <ul>
          <li>{props.part.get("sides") === "1" ? 'S/S' : 'D/S'} for {props.part.get("armToSuitBase")} Arms / {props.part.get("duty") === 'Light' ? '200' : props.part.get("duty") === 'Medium' ? '305' : props.part.get("duty") === 'Heavy' ? '350' : null } ({props.part.get("width")})  </li>
        </ul> : props.part.get('name') === ARM_NAME ? <ul>
          <li>H{props.part.get("height")}</li>
          <li> {props.part.get("depth")}</li>
        </ul> : props.part.get('name') === BRACE_NAME ? <ul>
          <li>{props.part.get("bayCenter").split(" ")[0] === "Horizontal" ? "H" : 'D'} {props.part.get("bayCenter").split(" ")[1]} c/c ({props.part.get("width")})</li>
        </ul> : <></>}
      </Typography>
      <Typography
        variant="h6"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
      >
        <strong>{props.part.get("duty")} Duty</strong>
      </Typography>

      <Typography
        variant="h5"
        fontWeight="600"
        display="flex"
        justifyContent="center"
        my="2px"
        color={colors.blueAccent[400]}
      >
        ${props.part.get("price")}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
      >
        <div className="d-flex align-items-center justify-content-between position-relative border rounded-pill">
          <IconButton onClick={decrement} disabled={quantity <= 0}>
            <RemoveCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
          <InputBase
            value={quantity}
            onChange={(e) => {}}
            onBlur={() => {}}
            sx={{ flex: 1 }}
          />
          <IconButton onClick={incremet}>
            <AddCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
        </div>
        <Box mx="0.2em">
          Net Price:{" "}
          <strong>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</strong>
        </Box>
        <ReactTooltip id="title required" effect="solid" place="top">
          {"Add to Cart"}
        </ReactTooltip>
        <IconButton
          className="cursor-pointer"
          disabled={quantity <= 0}
          onClick={() => checkForContrary()}
          data-tip
          data-for="title required"
        >
          <ShoppingCartIcon
            sx={{
              fontSize: "26px",
              color: quantity > 0 ? colors.greenAccent[500] : colors.grey[500],
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
export default CantileverPart;
