import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Logout, Settings } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { ColorModeContext, tokens } from "../theme";
import AuthService from "../services/auth.service";
import { addProfileInformation, addToCart, addToMiscCartItems, currentActiveSection } from "../features/cart/cartSlice";
import { ParseObject } from "../components/ExistingQuotation";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.cart.user);
  const revisionInProgress = useSelector((state) => state.cart.revisionInProgress);
  const quotationNumber = useSelector((state) => state.cart.quotationNumber);
  const [displayTopbar, setDisplayTopbar] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (userInfo?.loggedIn) {
      setDisplayTopbar(true);
    }
    else {
      setDisplayTopbar(false);
    }
  }, [userInfo]);

  const logOut = () => {
    AuthService.logout().then((res) => {
      dispatch(addProfileInformation({ id: '', loggedIn: false }))
      localStorage.clear('user');
      navigate('/login')
    })
      .catch((err) => {
        console.log(err);
      });
  };

  const seed = () => {
    const seedItems = [
      {
          "id": "DsHQyWQ3im",
          "part": {
              "data": {
                  "depth": "96mm",
                  "width": "305mm",
                  "height": "2972mm",
                  "price": "279.62",
                  "layout": "",
                  "quantity": 4,
                  "name": "Column",
                  "partID": "canti-146",
                  "bayCenter": "",
                  "finish": "Blue",
                  "armToSuitBase": "",
                  "duty": "Medium",
                  "weight": "69.01",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:35.779Z",
                  "updatedAt": "2024-08-05T05:59:17.213Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -1,
                          "newQuantity": 0,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-09T04:19:35.563Z"
                          }
                      },
                      {
                          "oldQuantity": 0,
                          "newQuantity": 4,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-03T07:52:00.001Z"
                          }
                      },
                      {
                          "oldQuantity": 1,
                          "newQuantity": 4,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:59:17.152Z"
                          }
                      }
                  ],
                  "objectId": "DsHQyWQ3im"
              },
              "className": "CantileverParts",
              "id": "DsHQyWQ3im"
          },
          "quantity": 1,
          "price": 279.62,
          "section": 1
      },
      {
          "id": "cdW6nHGQu7",
          "part": {
              "data": {
                  "depth": "192mm",
                  "width": "1386mm",
                  "height": "305mm",
                  "price": "167.99",
                  "layout": "",
                  "name": "Base",
                  "partID": "canti-52",
                  "bayCenter": " ",
                  "finish": "Blue",
                  "armToSuitBase": " 900mm",
                  "duty": "Medium",
                  "weight": "37.76",
                  "sides": "1",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:30.179Z",
                  "updatedAt": "2024-08-05T05:59:17.214Z",
                  "partHoldStatus": {
                      "8437B": {
                          "quantity": 4
                      }
                  },
                  "quantityLog": [
                      {
                          "oldQuantity": 37,
                          "newQuantity": 39,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-28T20:02:15.697Z"
                          }
                      },
                      {
                          "oldQuantity": 26,
                          "newQuantity": 28,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:59:17.157Z"
                          }
                      }
                  ],
                  "quantity": 28,
                  "objectId": "cdW6nHGQu7"
              },
              "className": "CantileverParts",
              "id": "cdW6nHGQu7"
          },
          "quantity": 1,
          "price": 167.99,
          "section": 1
      },
      {
          "id": "wiVQJyKDtK",
          "part": {
              "data": {
                  "depth": "900mm",
                  "width": "100mm",
                  "height": "120mm",
                  "price": "61.42",
                  "layout": "",
                  "quantity": 92,
                  "name": "Arm",
                  "partID": "canti-102",
                  "bayCenter": " ",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "10.14",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:33.253Z",
                  "updatedAt": "2024-08-02T02:05:17.412Z",
                  "partHoldStatus": {
                      "8437B": {
                          "quantity": 20
                      }
                  },
                  "quantityLog": [
                      {
                          "oldQuantity": 113,
                          "newQuantity": 152,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-09T04:24:22.891Z"
                          }
                      }
                  ],
                  "objectId": "wiVQJyKDtK"
              },
              "className": "CantileverParts",
              "id": "wiVQJyKDtK"
          },
          "quantity": 1,
          "price": 61.42,
          "section": 1
      },
      {
          "id": "UfesgfHxzy",
          "part": {
              "data": {
                  "depth": "45mm",
                  "width": "700mm",
                  "height": "30mm",
                  "price": "13.11",
                  "layout": "Horizontal",
                  "quantity": 40,
                  "name": "Bracing",
                  "partID": "canti-16",
                  "bayCenter": "Horizontal 900mm bay",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:28.087Z",
                  "updatedAt": "2024-08-17T07:59:53.971Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -375,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:15:04.989Z"
                          }
                      },
                      {
                          "oldQuantity": 37,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:50:34.875Z"
                          }
                      },
                      {
                          "oldQuantity": 37,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:59:53.912Z"
                          }
                      }
                  ],
                  "objectId": "UfesgfHxzy"
              },
              "className": "CantileverParts",
              "id": "UfesgfHxzy"
          },
          "quantity": 1,
          "price": 13.11,
          "section": 1
      },
      {
          "id": "Iqx22yECn4",
          "part": {
              "data": {
                  "depth": "45mm",
                  "width": "1299mm",
                  "height": "30mm",
                  "price": "7.88",
                  "layout": "Diagonal",
                  "quantity": 127,
                  "name": "Bracing",
                  "partID": "canti-20",
                  "bayCenter": "Diagonal 900mm bay",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:28.413Z",
                  "updatedAt": "2024-08-17T07:59:53.979Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -333,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-02T02:52:15.465Z"
                          }
                      },
                      {
                          "oldQuantity": 123,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:50:34.876Z"
                          }
                      },
                      {
                          "oldQuantity": 123,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:59:53.917Z"
                          }
                      }
                  ],
                  "useMasterKey": true,
                  "objectId": "Iqx22yECn4"
              },
              "className": "CantileverParts",
              "id": "Iqx22yECn4"
          },
          "quantity": 1,
          "price": 7.88,
          "section": 1
      },
      {
          "id": "DsHQyWQ3im",
          "part": {
              "data": {
                  "depth": "96mm",
                  "width": "305mm",
                  "height": "2972mm",
                  "price": "279.62",
                  "layout": "",
                  "quantity": 4,
                  "name": "Column",
                  "partID": "canti-146",
                  "bayCenter": "",
                  "finish": "Blue",
                  "armToSuitBase": "",
                  "duty": "Medium",
                  "weight": "69.01",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:35.779Z",
                  "updatedAt": "2024-08-05T05:59:17.213Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -1,
                          "newQuantity": 0,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-09T04:19:35.563Z"
                          }
                      },
                      {
                          "oldQuantity": 0,
                          "newQuantity": 4,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-03T07:52:00.001Z"
                          }
                      },
                      {
                          "oldQuantity": 1,
                          "newQuantity": 4,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:59:17.152Z"
                          }
                      }
                  ],
                  "objectId": "DsHQyWQ3im"
              },
              "className": "CantileverParts",
              "id": "DsHQyWQ3im"
          },
          "quantity": 2,
          "price": 559.24,
          "section": 2
      },
      {
          "id": "cdW6nHGQu7",
          "part": {
              "data": {
                  "depth": "192mm",
                  "width": "1386mm",
                  "height": "305mm",
                  "price": "167.99",
                  "layout": "",
                  "name": "Base",
                  "partID": "canti-52",
                  "bayCenter": " ",
                  "finish": "Blue",
                  "armToSuitBase": " 900mm",
                  "duty": "Medium",
                  "weight": "37.76",
                  "sides": "1",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:30.179Z",
                  "updatedAt": "2024-08-05T05:59:17.214Z",
                  "partHoldStatus": {
                      "8437B": {
                          "quantity": 4
                      }
                  },
                  "quantityLog": [
                      {
                          "oldQuantity": 37,
                          "newQuantity": 39,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-28T20:02:15.697Z"
                          }
                      },
                      {
                          "oldQuantity": 26,
                          "newQuantity": 28,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:59:17.157Z"
                          }
                      }
                  ],
                  "quantity": 28,
                  "objectId": "cdW6nHGQu7"
              },
              "className": "CantileverParts",
              "id": "cdW6nHGQu7"
          },
          "quantity": 2,
          "price": 335.98,
          "section": 2
      },
      {
          "id": "wiVQJyKDtK",
          "part": {
              "data": {
                  "depth": "900mm",
                  "width": "100mm",
                  "height": "120mm",
                  "price": "61.42",
                  "layout": "",
                  "quantity": 92,
                  "name": "Arm",
                  "partID": "canti-102",
                  "bayCenter": " ",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "10.14",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:33.253Z",
                  "updatedAt": "2024-08-02T02:05:17.412Z",
                  "partHoldStatus": {
                      "8437B": {
                          "quantity": 20
                      }
                  },
                  "quantityLog": [
                      {
                          "oldQuantity": 113,
                          "newQuantity": 152,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-07-09T04:24:22.891Z"
                          }
                      }
                  ],
                  "objectId": "wiVQJyKDtK"
              },
              "className": "CantileverParts",
              "id": "wiVQJyKDtK"
          },
          "quantity": 2,
          "price": 122.84,
          "section": 2
      },
      {
          "id": "UfesgfHxzy",
          "part": {
              "data": {
                  "depth": "45mm",
                  "width": "700mm",
                  "height": "30mm",
                  "price": "13.11",
                  "layout": "Horizontal",
                  "quantity": 40,
                  "name": "Bracing",
                  "partID": "canti-16",
                  "bayCenter": "Horizontal 900mm bay",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:28.087Z",
                  "updatedAt": "2024-08-17T07:59:53.971Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -375,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-05T05:15:04.989Z"
                          }
                      },
                      {
                          "oldQuantity": 37,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:50:34.875Z"
                          }
                      },
                      {
                          "oldQuantity": 37,
                          "newQuantity": 40,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:59:53.912Z"
                          }
                      }
                  ],
                  "objectId": "UfesgfHxzy"
              },
              "className": "CantileverParts",
              "id": "UfesgfHxzy"
          },
          "quantity": 2,
          "price": 26.22,
          "section": 2
      },
      {
          "id": "Iqx22yECn4",
          "part": {
              "data": {
                  "depth": "45mm",
                  "width": "1299mm",
                  "height": "30mm",
                  "price": "7.88",
                  "layout": "Diagonal",
                  "quantity": 127,
                  "name": "Bracing",
                  "partID": "canti-20",
                  "bayCenter": "Diagonal 900mm bay",
                  "finish": "Blue",
                  "armToSuitBase": " ",
                  "duty": "Medium",
                  "weight": "",
                  "sides": "",
                  "category": "",
                  "partName": "",
                  "createdAt": "2024-02-08T22:18:28.413Z",
                  "updatedAt": "2024-08-17T07:59:53.979Z",
                  "partHoldStatus": {},
                  "quantityLog": [
                      {
                          "oldQuantity": -333,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-02T02:52:15.465Z"
                          }
                      },
                      {
                          "oldQuantity": 123,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:50:34.876Z"
                          }
                      },
                      {
                          "oldQuantity": 123,
                          "newQuantity": 127,
                          "changeDate": {
                              "__type": "Date",
                              "iso": "2024-08-17T07:59:53.917Z"
                          }
                      }
                  ],
                  "useMasterKey": true,
                  "objectId": "Iqx22yECn4"
              },
              "className": "CantileverParts",
              "id": "Iqx22yECn4"
          },
          "quantity": 2,
          "price": 15.76,
          "section": 2
      },
      {
        "id": "WlgyOi6xbg",
        "part": {
            "data": {
                "partID": "pallet-1",
                "description": "H 2438 * 838mm  90*70*2.0",
                "price": "175.916",
                "name": "Frame",
                "dimensions": "90*70*2.0",
                "finish": "",
                "loadingCap": "STD",
                "weight": "25.65",
                "condition": "new",
                "createdAt": "2023-11-03T06:19:22.826Z",
                "updatedAt": "2024-01-01T01:07:22.315Z",
                "quantity": 0,
                "brand": "",
                "objectId": "WlgyOi6xbg"
            },
            "className": "PalletRackingParts",
            "id": "WlgyOi6xbg"
        },
        "quantity": 1,
        "price": 175.916,
        "section": 3
    }
  ]
      const miscParts = [
        {
            "id": "lzyr8oe4zrq9wxo4xpk",
            "part": {
                "name": "abc",
                "dimensions": "",
                "duty": "",
                "finish": "",
                "price": "120",
                "comments": ""
            },
            "quantity": 1,
            "price": 120,
            "section": 1
        },
        {
            "id": "lzyr8uf4vthkq0zz7g",
            "part": {
                "name": "def",
                "dimensions": "",
                "duty": "",
                "finish": "",
                "price": "100",
                "comments": ""
            },
            "quantity": 1,
            "price": 100,
            "section": 1
        },
        {
            "id": "lzyrd6ty9v2glupoocd",
            "part": {
                "name": "wow",
                "dimensions": "",
                "duty": "",
                "finish": "",
                "price": "400",
                "comments": ""
            },
            "quantity": 1,
            "price": 400,
            "section": 2
        },
        {
            "id": "lzyrddffdoix71d4zoj",
            "part": {
                "name": "wow2",
                "dimensions": "",
                "duty": "",
                "finish": "",
                "price": "250",
                "comments": ""
            },
            "quantity": 1,
            "price": 250,
            "section": 2
        }
    ]
      seedItems.map((item) => {
        const parseClone = new ParseObject(item.part.data ? item.part.data : item.part, item.part.data ? item.part.data.partID.includes('canti') ? "CantileverParts" : "PalletRackingParts" : item.part.partID.includes('canti') ? "CantileverParts" : "PalletRackingParts", item.id);
        const part = { id: item.id, part: parseClone, quantity: item.quantity, price: item.price, section: item.section };
        return dispatch(addToCart(part));
      });
      miscParts.map((item) => {
        return dispatch(addToMiscCartItems(item));
    });
return true

  }

  return (
    <>
      {displayTopbar && <Box display="flex" justifyContent="space-between" alignItems={'flex-start'} p={2}>
        {/* SEARCH BAR */}
        <Box
          display="flex"
          borderRadius="3px"
        >
          <Typography
            variant="h3"
            fontWeight="800"
            className="cursor-pointer"
            color={colors.greenAccent[400]}
            onClick={() => navigate('')}
          >StoreMax - Material Handling Experts</Typography>
        </Box>

        {/* <Box>
          <button onClick={seed}>SEED</button>
        </Box> */}

        <Box>
          {revisionInProgress &&
            <Typography
              variant="h4"
              fontWeight="400"
              color={colors.redAccent[500]}
              ml={1}
            >
              Revision In Progress - {quotationNumber}
            </Typography>
          }
        </Box>

        {/* ICONS */}
        <Box display="flex">
          <IconButton onClick={() => navigate('/build-cantilever')}>
            <ConstructionIcon />
          </IconButton>

          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <IconButton onClick={() => navigate('/cart')}>
            <ShoppingCartOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => navigate('settings')}>
            <SettingsOutlinedIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <PersonOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={() => logOut()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Box>}
    </>
  );
};

export default Topbar;
