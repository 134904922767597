import { Box, Input, InputBase, Switch, Typography, useTheme } from "@mui/material";
import * as React from "react";
import Parse from 'parse';
import { useParams } from "react-router-dom";
import { useState } from "react";
import { tokens } from "../theme";
import { Spinner } from "react-bootstrap";
import { alpha, styled } from '@mui/material/styles';
import { toast } from "react-toastify";

export default function ClientDetail(props) {
    const [client, setClient] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [editable, setEditable] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [margin, setMargin] = useState('');
    const [discount, setDiscount] = useState('');
    const [marginEnabled, setMarginEnabled] = useState(false);
    const [discountEnabled, setDiscountEnabled] = useState(false);

    const GreenSwitch = styled(Switch)(({ theme }) => ({
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: colors.greenAccent[600],
        '&:hover': {
          backgroundColor: alpha(colors.greenAccent[600], theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: colors.greenAccent[600],
      },
    }));

    const { id } = useParams();

    React.useEffect(() => {
        getClientItemParseObject(id);
      }, [id]);


    const getClientItemParseObject = async  (id) => {
        setIsLoading(true);
        const quotationsQuery = new Parse.Query(Parse.Object.extend('ClientDetails')).limit(10000);
        const res = await quotationsQuery.equalTo('objectId', id).first();
        setIsLoading(false);
        setClient(res);
        setFirstName(res.get('firstName'))
        setLastName(res.get('lastName'))
        setCompanyName(res.get('companyName'))
        setEmail(res.get('email'))
        setPhoneNumber(res.get('phoneNumber'))
        setAddress(res.get('address'))
        setMargin(res.get('margin') ?? "")
        setDiscount(res.get('discount') ?? "")
        if (res.get('discount').length > 0) {
          setDiscountEnabled(true);
        }
        if (res.get('margin').length > 0) {
          setMarginEnabled(true);
        }
    }

    const saveUpdatedClient = async () => {
        setIsLoading(true)
        client.set('firstName', firstName)
        client.set('lastName', lastName)
        client.set('companyName', companyName)
        client.set('email', email)
        client.set('phoneNumber', phoneNumber)
        client.set('address', address)
        client.set('margin', marginEnabled ? margin : undefined)
        client.set('discount', discountEnabled ? discount : undefined)
        try {
            const res  = await client.save();
            setClient(res);
            setEditable(false);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error('something went wrong');
        }
    }
    

  return (
      
      <Box>
            {isLoading && 
            <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" variant="primary" />
            </div>}

          <Box display='flex' justifyContent='space-between' m={4} alignItems={'baseline'}>
                <Typography variant="h5" fontWeight="600">Client Details</Typography>
                {!editable ? <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 20px"
                  borderRadius="4px"
                  mt="15px"
                  className="cursor-pointer"
                  onClick={() => {setEditable(true)}}
                >
                  Edit
                </Box> : <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 20px"
                  borderRadius="4px"
                  mt="15px"
                  className="cursor-pointer"
                  onClick={() => setEditable(false)}
                >
                  Cancel
                </Box>}
            </Box>
            <Box
              gridColumn="span 12"
              gridRow="span 6"
              backgroundColor={
                colors.primary[400]
              }
              margin={'20px'}
              p={"30px"}
            >

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>First Name</Typography>
                    {!editable ? <Typography>{client?.get('firstName')}</Typography> : <Input
                    type="text"
                    name="firstName"
                    value={firstName}
                    className="w-50"
                    onChange={(e) => setFirstName(e.target.value)}
                    />}
                </Box>

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>Last Name</Typography>
                    {!editable ? <Typography>{client?.get('lastName')}</Typography> : <Input
                        type="text"
                        name="lastName"
                        value={lastName}
                        className="w-50"
                        onChange={(e) => setLastName(e.target.value)}
                        />}
                </Box>

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>Company Name</Typography>
                    {!editable ? <Typography>{client?.get('companyName')}</Typography> : <Input
                        type="text"
                        name="companyName"
                        className="w-50"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        />}
                </Box>

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>Address</Typography>
                    {!editable ? <Typography>{client?.get('address')}</Typography> : <Input
                        type="text"
                        name="address"
                        className="w-50"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        />}
                </Box>

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>email</Typography>
                    {!editable ? <Typography>{client?.get('email')}</Typography> : <Input
                        type="text"
                        name="email"
                        className="w-50"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        />}
                </Box>

                <Box display={'flex'} my={3} justifyContent={'space-between'}>
                    <Typography>Phone Number</Typography>
                    {!editable ? <Typography>{client?.get('phoneNumber')}</Typography> : <Input
                        type="text"
                        className="w-50"
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        />}
                </Box>

                <div className="d-flex justify-content-between align-items-center px-3">
                  <div className="d-flex align-items-center">
                    <GreenSwitch
                      checked={marginEnabled}
                      disabled={!editable}
                      onChange={(e) => { setMarginEnabled(e.target.checked); e.target.checked ? setDiscountEnabled(false) : setDiscountEnabled(discountEnabled) }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <label>MARGIN</label>
                  </div>
                  {marginEnabled && <InputBase
                    type="number"
                    placeholder="margin %"
                    className="outlined border rounded px-2 my-2"
                    name="title" value={margin}
                    onChange={(event) => setMargin(event.target.value)}
                  />}
                </div>

                <div className="d-flex justify-content-between align-items-center px-3">
                  <div className="d-flex align-items-center">
                    <GreenSwitch
                      checked={discountEnabled}
                      onChange={(e) => { setDiscountEnabled(e.target.checked); e.target.checked ? setMarginEnabled(false) : setMarginEnabled(marginEnabled) }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      disabled={!editable}
                    />
                    <label>DISCOUNT</label>
                  </div>
                  {discountEnabled && <InputBase
                    type="number"
                    placeholder="discount %"
                    className="outlined border rounded px-2 my-2"
                    name="title" value={discount}
                    onChange={(event) => setDiscount(event.target.value)}
                  />}
                </div>

                {editable && <Box
                  backgroundColor={colors.greenAccent[500]}
                  p="5px 20px"
                  borderRadius="4px"
                  mt="15px"
                  textAlign={'center'}
                  className="cursor-pointer"
                  onClick={saveUpdatedClient}
                >
                  Save
                </Box>}
            </Box>
      </Box>
  );
}
