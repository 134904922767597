import * as Parse from 'parse';
import { isNil } from 'ramda';

const init = () => {
  const appId = (!isNil(process.env.REACT_APP_APPLICATION_ID)) ? process.env.REACT_APP_APPLICATION_ID : 'APP_ID';
  const jsKey = (!isNil(process.env.REACT_APP_JAVASCRIPT_KEY)) ? process.env.REACT_APP_JAVASCRIPT_KEY : 'JAVASCRIPT_KEY';
  const serverUrl = (!isNil(process.env.REACT_APP_SERVER_URL)) ? process.env.REACT_APP_SERVER_URL : 'http://142.93.250.11:1337';

  Parse.initialize(appId, jsKey);
  Parse.serverURL = serverUrl + '/parse';
  Parse.masterKey = 'MASTER_KEY';
};

const register = async (
  firstName,
  lastName,
  username,
  email,
  password) => {
  try {
    const user = new Parse.User();
    user.set('firstName', firstName);
    user.set('lastName', lastName);
    user.set('password', password);
    user.set('username', username);
    user.set('email', email);
    return await user.signUp();
  } catch (error) {
    throw error;
  }
}

const login = async (email, password) => {
  try {
    return await Parse.User.logIn(email, password);
  } catch (error) {
    throw error;
  }
}

const logout = async () => {
  await Parse.User.logOut().then(() => {
    const currentUser = Parse.User.current(); // this will now be null
    return currentUser;
  });
}

const getCurrentUser = async () => {
  init();
  return await Parse.User.current()?.fetch();
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  init
}

export default AuthService;
