import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Parse from "parse";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const CustomInventoryAddition = () => {
  const [name, setName] = useState("");
  const [finish, setFinish] = useState("Blue");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [depth, setDepth] = useState("");
  const [sides, setSides] = useState("");
  const [armToSuitBase, setArmCapacity] = useState("");
  const [BayType, setBayType] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [price, setPrice] = useState(0);
  const [duty, setDuty] = useState("Medium");
  const [weight, setWeight] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [imageFile, setImageFile] = useState();

  const [category, setCategory] = React.useState("");

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleFinishChange = (event) => {
    setFinish(event.target.value);
  };

  const handleDutyChange = (event) => {
    setFinish(event.target.value);
  };

	const imageUpload = (e) => {
		const file = e.target.files[0];
		getBase64(file).then(base64 => {
			setImageFile(base64);
		});
};

const getBase64 = (file) => {
  return new Promise((resolve,reject) => {
     const reader = new FileReader();
     reader.onload = () => resolve(reader.result);
     reader.onerror = error => reject(error);
     reader.readAsDataURL(file);
  });
}

  const submitPart = async () => {
		setIsLoading(true);
    const CantileverPart = Parse.Object.extend("CantileverParts");
    const part = new CantileverPart();
    part.set("name", name);
    part.set("category", category);
    part.set("finish", finish);
    part.set("height", height);
    part.set("width", width);
    part.set("depth", depth);
    part.set("price", price);
    part.set("duty", duty);
    part.set("weight", weight);
    part.set("quantity", parseInt(quantity));
    part.set("partImage", imageFile);
    try {
			await part.save();
			setIsLoading(false);
			setName("");
			setFinish("");
			setHeight("");
			setWidth("");
			setDepth("");
			setSides("");
			setArmCapacity("");
			setBayType("");
			setPrice(0);
			setDuty("");
			setWeight("");
			setQuantity(0);
			setCategory("");
			toast.success('Stock Item Successfully Saved')
		} catch (error) {
			setIsLoading(false);
			console.log(error);
		}
  };

  return (
    <>
			<ToastContainer />
      <div className="text-center mb-3">
        <FormControl className="w-50 text-center">
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            label="Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value={"Accessory"}>Accessory</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className="d-flex">
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Name</label>
					<input value={name} onChange={(e) => setName(e.target.value)}></input>
				</div>
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">price</label>
					<input
						type="number"
						value={price}
						onChange={(e) => setPrice(e.target.value)}
					></input>
				</div>
			</div>
      
			<div className="d-flex">
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Height in mm</label>
								<input value={height} onChange={(e) => setHeight(e.target.value)}></input>
				</div>
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Width in mm</label>
					<input value={width} onChange={(e) => setWidth(e.target.value)}></input>
				</div>
			</div>


      <div className="d-flex">
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1">depth in mm</label>
								<input value={depth} onChange={(e) => setDepth(e.target.value)}></input>
							</div>
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1">
									Weight without unit
								</label>
								<input value={weight} onChange={(e) => setWeight(e.target.value)}></input>
							</div>
			</div>

			<div className="d-flex">
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1 my-3">
									Part Image
								</label>
								<input
					type="file"
					id="imageFile"
					name='imageFile'
					onChange={imageUpload} />
				</div>
				<div className="col-6">
					<label className="col-md-3 col-lg-3 col-3 mx-1">Quantity</label>
					<input type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)}></input>
				</div>
			</div>

      <div className="d-flex">
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1 my-4">Duty</label>
								<FormControl style={{width: '20%'}} className=" my-4 text-center">
									<InputLabel id="demo-simple-select-label">Duty</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={duty}
										label="Finish"
										onChange={handleDutyChange}
									>
										<MenuItem value={"Light"}>Light</MenuItem>
										<MenuItem value={"Medium"}>Medium</MenuItem>
										<MenuItem value={"Heavy"}>Heavy</MenuItem>
									</Select>
								</FormControl>
							</div>
				<div className="col-6">
								<label className="col-md-3 col-lg-3 col-3 mx-1 my-4">Finish</label>
								<FormControl style={{width: '20%'}} className=" my-4 text-center">
									<InputLabel id="demo-simple-select-label">Finish</InputLabel>
									<Select
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										value={finish}
										label="Finish"
										onChange={handleFinishChange}
									>
										<MenuItem value={"Blue"}>Powder Coated</MenuItem>
										<MenuItem value={"Gavl"}>Hot dip Galvanized</MenuItem>
									</Select>
								</FormControl>
							</div>
			</div>

      <div className="text-center">
        <button
          className="btn btn-primary p-3"
          disabled={
            name.length === 0 ||
            finish.length === 0 ||
            height.length === 0 ||
            width.length === 0 ||
            depth.length === 0 ||
            price.length === 0 ||
            duty.length === 0 ||
            weight.length === 0 ||
            quantity.length === 0 ||
            category.length === 0
          }
          onClick={() => submitPart()}
        >
          {!isLoading ? 'Save' : <Spinner animation="border" role="status" size='sm' variant="primary"/>}
        </button>
      </div>
    </>
  );
};

export default CustomInventoryAddition;
