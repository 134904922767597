import React, { useEffect, useState } from "react";
import Parse from "parse";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from "@mui/material";
import { setSelectedClient } from "../features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";


const SelectClients = (props) => {
  const [clientsData, setClientsData] = useState([]);
  const selectedClientFromStore = useSelector((state) => state.cart.selectedClient);
  const requestForClientsReload = props.requestForClientsReload;
  const setRequestForClientsReload = props.setRequestForClientsReload;
  const dispatch = useDispatch();
  const disabled = props.disabled
  const setFetchingClients = props.setFetchingClients
  const fetchingClients = props.fetchingClients

  useEffect(() => {
    if (localStorage.getItem('clients')) {
      setClientsData(JSON.parse(localStorage.getItem('clients')));
    } else {
      getQuotationsData();
    };
  }, []);

  useEffect(() => {
    if (requestForClientsReload) {
      getQuotationsData();
    }
  }, [requestForClientsReload]);

  const getQuotationsData = async () => {
    try {
      setFetchingClients(true);
      localStorage.removeItem('clients');
      const clientsObj = Parse.Object.extend("ClientDetails");
      const query = new Parse.Query(clientsObj).limit(100000);
      const res = await query.find();
      const clients = []
      res.forEach((client) => {
          clients.push({label: client.get('companyName') + ' - ' + client.get('firstName') + ' ' + client.get('lastName') + ' - ' + client.get('email') + ' - ' + client.get('phoneNumber'), parseObject: client, email: client.get('email'), id: client.id})
      });
      localStorage.setItem('clients', JSON.stringify(clients));
      setClientsData(clients);
      setRequestForClientsReload(false);
      setTimeout(() => {
        setFetchingClients(false);
      }, 10000);
    } catch (error) {
      console.log('errpr: ', error);
    }
  };

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      sx={{ width: 300 }}
      options={clientsData}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.label}
        </Box>
      )}
      disabled={disabled || fetchingClients}
      value={selectedClientFromStore}
      onChange={(event, newValue) => {dispatch(setSelectedClient(newValue)); }}
      renderInput={(params) => <TextField className="primary-color" {...params} label="email" />}
    />
  );
};

export default SelectClients;
