import React, { useState, useEffect } from "react";
import * as Parse from "parse";
import { ToastContainer } from 'react-toastify';
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, Button, Typography } from "@mui/material";
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../theme";
import frame from "../assets/images/palletFrame.png";
import beam from "../assets/images/palletBeam.png";
import meshDeck from '../assets/images/meshDeck.png';
import PalletRackingPart, { ACCESSORY, MESH_DECK, MISC, ROW_SPACER } from "./PalletRackingPart";
import cogs from '../assets/images/adjust.png';
import barSupport from '../assets/images/palletBarSupport.png';
import FramePalletRacking from "./FramePalletRackPart";
import BeamPalletRacking from "./BeamPalletRackingPart";
import rowSpacer from '../assets/images/rowSpacers.png';

import accessories from '../assets/images/settings.png';
import MiscPart from "../components/MiscPart";
import CreateMiscPart from "../components/CreateMiscPart";


const BuildPalletRacks = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [palletRackingParts, setPalletRackingParts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
	const [currentActive, setCurrentActive] = useState(1);
  const [condition, setCondition] = React.useState('New');
  const navigate = useNavigate();
  const cartSection = useSelector((state) => state.cart.sections);
  const miscParts = useSelector((state) => state.cart.miscCantileverItems);
  const [frameDimensions, setFrameDimensions] = useState()
  const [beamDimensions, setBeamDimensions] = useState()

	useEffect(() => {
		getPalletRackingParts(currentActive)
	}, [currentActive, condition]);

	const getPalletRackingParts = async (currentActivePart) => {
		let query;
    if (condition === 'Used') {
      query = new Parse.Query(Parse.Object.extend("PalletRackingPartsUsed"));
    } else {
      query = new Parse.Query(Parse.Object.extend("PalletRackingParts"));
    }
		setIsLoading(true);
    if (currentActivePart === 1) {
      const frameDimensionsList1 = [];
      const frameDimensionsList2 = [];
      query.equalTo("name", "Frame");
      const data = await query.find();
      data.map((frame) => {
        if (frame.get("description").split(" ").includes("838mm")) {
          return frameDimensionsList1.push(frame);
        } else {
          return frameDimensionsList2.push(frame);
        }
      });
      const updatedList1 = Object.values(frameDimensionsList1).sort(function(a, b) {
        var regex = /(\d+)|(\D+)/g;
        var aParts = a.get('description').match(regex);
        var bParts = b.get('description').match(regex);
        
        for (var i = 0; i < Math.min(aParts.length, bParts.length); i++) {
          var aIsNumber = !isNaN(aParts[i]);
          var bIsNumber = !isNaN(bParts[i]);
          
          if (aIsNumber && bIsNumber) {
            var aNum = parseInt(aParts[i]);
            var bNum = parseInt(bParts[i]);
            
            if (aNum !== bNum) {
              return aNum - bNum;
            }
          } else if (aIsNumber) {
            return -1;
          } else if (bIsNumber) {
            return 1;
          } else {
            var aStr = aParts[i].toLowerCase();
            var bStr = bParts[i].toLowerCase();
            
            if (aStr !== bStr) {
              return aStr.localeCompare(bStr);
            }
          }
        }
        
        return aParts.length - bParts.length;
      });
      const updatedList2 = Object.values(frameDimensionsList2).sort(function(a, b) {
        var regex = /(\d+)|(\D+)/g;
        var aParts = a.get('description').match(regex);
        var bParts = b.get('description').match(regex);
        
        for (var i = 0; i < Math.min(aParts.length, bParts.length); i++) {
          var aIsNumber = !isNaN(aParts[i]);
          var bIsNumber = !isNaN(bParts[i]);
          
          if (aIsNumber && bIsNumber) {
            var aNum = parseInt(aParts[i]);
            var bNum = parseInt(bParts[i]);
            
            if (aNum !== bNum) {
              return aNum - bNum;
            }
          } else if (aIsNumber) {
            return -1;
          } else if (bIsNumber) {
            return 1;
          } else {
            var aStr = aParts[i].toLowerCase();
            var bStr = bParts[i].toLowerCase();
            
            if (aStr !== bStr) {
              return aStr.localeCompare(bStr);
            }
          }
        }
        
        return aParts.length - bParts.length;
      });
      setFrameDimensions([{1: updatedList1}, {2: updatedList2}]);
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 2) {
      query.equalTo("name", "Beam");
      const beamDimensionsList1 = [];
      const beamDimensionsList2 = [];
      const beamDimensionsList3 = [];
      const beamDimensionsList4 = [];
      const beamDimensionsList5 = [];
      const beamDimensionsList6 = [];
      const data = await query.find();
      data.map((beam) => {
        if (beam.get("description").split(" ").includes("80")) {
          return beamDimensionsList1.push(beam);
        } else if (beam.get("description").split(" ").includes("100")) {
          return beamDimensionsList2.push(beam);
        } else if (beam.get("description").split(" ").includes("120")) {
          return beamDimensionsList3.push(beam);
        } else if (beam.get("description").split(" ").includes("140")) {
          return beamDimensionsList4.push(beam);
        } else if (beam.get("description").split(" ").includes("160")) {
          return beamDimensionsList5.push(beam);
        } else {
          return beamDimensionsList6.push(beam);
        }
      });
      const beamUpdatedList1 = Object.values(beamDimensionsList1).sort(function(a, b) {
        var regex = /(\d+)|(\D+)/g;
        var aParts = a.get('description').match(regex);
        var bParts = b.get('description').match(regex);
        
        for (var i = 0; i < Math.min(aParts.length, bParts.length); i++) {
          var aIsNumber = !isNaN(aParts[i]);
          var bIsNumber = !isNaN(bParts[i]);
          
          if (aIsNumber && bIsNumber) {
            var aNum = parseInt(aParts[i]);
            var bNum = parseInt(bParts[i]);
            
            if (aNum !== bNum) {
              return aNum - bNum;
            }
          } else if (aIsNumber) {
            return -1;
          } else if (bIsNumber) {
            return 1;
          } else {
            var aStr = aParts[i].toLowerCase();
            var bStr = bParts[i].toLowerCase();
            
            if (aStr !== bStr) {
              return aStr.localeCompare(bStr);
            }
          }
        }
        
        return aParts.length - bParts.length;
      });

      setBeamDimensions([{1: beamUpdatedList1}, {2: beamDimensionsList2}, {3: beamDimensionsList3}, {4: beamDimensionsList4}, {5: beamDimensionsList5}, {6: beamDimensionsList6}]);
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 3) {
      query.equalTo("name", "Support");
      const data = await query.find();
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 4) {
      query.equalTo("name", ROW_SPACER);
      const data = await query.find();
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 5) {
      query.equalTo("name", MESH_DECK);
      const data = await query.find();
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 6) {
      query.equalTo("name", ACCESSORY);
      const data = await query.find();
      setPalletRackingParts(data);
      setIsLoading(false);
    } else if (currentActive === 7) {
      query.equalTo("name", MISC);
      const data = await query.find();
      setPalletRackingParts(data);
      setIsLoading(false);
    }
	};

  const handleConditionChange = (event) => {
    setCondition(event.target.value);
  };


  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
    >
      <div>
        <ToastContainer />
      </div>
			<Box
				display='flex'
				justifyContent='center'
			>
				<Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Frame</Typography>
          <img className="pallet-racking-part-navigate" src={frame} alt='pallet-racking-part' onClick={() => setCurrentActive(1)}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Beam</Typography>
				  <img className="pallet-racking-part-navigate" src={beam} alt='pallet-racking-part' onClick={() => setCurrentActive(2)}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Support</Typography>
				  <img className="pallet-racking-part-navigate" src={barSupport} alt='pallet-racking-part' onClick={() => setCurrentActive(3)}/>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>RSpacer</Typography>
				  <img className="pallet-racking-part-navigate" src={rowSpacer} alt='pallet-racking-part' onClick={() => setCurrentActive(4)}/>
          </Box>
          <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Mesh</Typography>
          <div className="pallet-racking-part-navigate" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className="cogs" src={meshDeck} alt='pallet-racking-part' onClick={() => setCurrentActive(5)}/>
          </div>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Acc</Typography>
          <div className="pallet-racking-part-navigate" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className="cogs" src={cogs} alt='pallet-racking-part' onClick={() => setCurrentActive(6)}/>
          </div>
        </Box>
        <Box display={'flex'} flexDirection={'column'} justifyContent='center' alignItems={'center'}>
          <Typography>Misc</Typography>
          <div className="pallet-racking-part-navigate" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className="cogs" src={accessories} alt='pallet-racking-part' onClick={() => setCurrentActive(7)}/>
          </div>
        </Box>
			</Box>

      <div className="d-flex justify-content-start w-50 mt-3">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Condition</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={condition}
            label="Condition"
            onChange={handleConditionChange}
          >
            <MenuItem value={'Used'}>Used</MenuItem>
            <MenuItem value={'New'}>New</MenuItem>
          </Select>
        </FormControl>
      </div>

			<Box width='100%'>
      {
        !isLoading ? <Box display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
        m="15px">
        {palletRackingParts?.map((part, i) => (
          (part.get('name') !== 'Frame' && part.get('name') !== 'Beam'  && <PalletRackingPart key={i} part={part} quantity={0} updateCart={setCartItems} currentCart={cartItems} condition={condition}></PalletRackingPart>)
        ))}
        {currentActive === 1 && frameDimensions?.map((frame, i) => (
          <FramePalletRacking key={i} frameDimensions={frame} condition={condition}/>
        ))}
        {currentActive === 2 && beamDimensions?.map((beam, i) => (
          <BeamPalletRacking key={i} beamDimensions={beam} condition={condition}/>
        ))}
        {
        miscParts && currentActive === 7 && 
          <>
            {miscParts.map((part) => {
              return part.section === cartSection.currentActive ? <MiscPart id={part.id} part={part.part} quantity={part.quantity} /> : null
            })}
            <CreateMiscPart />
          </>
        }
      </Box>
      : 
        <div className="d-flex justify-content-center align-items-center" style={{height: '70vh'}}>
          <Spinner animation="border" role="status" variant="secondary"/>
        </div>
      }
		</Box>

		<Box display='flex' mb='1em'>
      <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "5px"
          }}
          disabled={currentActive === 1}
          id="prev"
          onClick={() => {
						setCurrentActive(currentActive - 1)
					}}
        >
          Prev
        </Button>

        {currentActive !== 6 ? <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginLeft: "5px"
          }}
          id="next"
          onClick={() => {
						setCurrentActive(currentActive + 1)
					}}
        >
          Next
        </Button>
        :
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            marginRight: "5px"
          }}
          onClick={() => navigate('/cart')}
        >
          View and Edit Cart
        </Button>
        }
      </Box>


          
    </Box>
  );
};
export default BuildPalletRacks;
