import React, { useEffect } from 'react';
import { Box } from "@mui/system";
import { Button, IconButton, InputBase, Typography, useTheme } from "@mui/material";
import { tokens } from '../theme';
import { useDispatch, useSelector } from 'react-redux';
import { currentActiveSection, deleteSection } from '../features/cart/cartSlice';
import ClearIcon from '@mui/icons-material/Clear';

const Tab = ({ label, active, onClick, tabIndex, enableDeletion, revisions, removeRevisionSection }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const cartSections = useSelector((state) => state.cart.sections.sectionCount);

    const removeSection = () => {
      if (!revisions) {
        dispatch(deleteSection(tabIndex+1));
        dispatch(currentActiveSection(tabIndex));
      } else if (revisions) {
        removeRevisionSection();
      }
    }

  return (

    <Box className='position-relative'>
        <Button
        sx={{
            backgroundColor: `${active ? colors.greenAccent[700] : colors.blueAccent[700]}`,
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "5px 20px",
            mx: '2px'
        }}
        className={`tab ${!active ? 'active' : ''}`}
        onClick={onClick}
        >
        {label}
        </Button>
        {tabIndex+1 === cartSections && enableDeletion && <IconButton style={{top: '-6px', right: '0', background: colors.redAccent[500] }} className="position-absolute p-0" onClick={() => removeSection()}>
          <ClearIcon
            sx={{ fontSize: "16px", color: colors.redAccent[800] }}
          />
        </IconButton>}
    </Box>
  );
};

export default Tab;