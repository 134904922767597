import React, { useState, useEffect } from "react";
import Parse from 'parse';
import { Box, FormControl, InputLabel, MenuItem, Select, useTheme, Button, InputBase } from "@mui/material";
import { Spinner } from "react-bootstrap";

function Importer() {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [cantileverParts, setCantileverParts] = useState([]);
  const [logger, setLogger] = useState([]);
  const [allSaved, setAllSaved] = useState(false);
  const [className, setClassName] = useState('ClientDetails');
  const [isLoading, setIsLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(100);

  useEffect(() => {
    getCurrentPartsArray();
  }, [className]);

  useEffect(() => {
    console.log('array: ', array);
    if (array.length > 0 && className !== 'ClientDetails') {
      setIsLoading(true);
      setTimeout(() => {
        if (className !== 'ClientDetails'){
          updateData();
        };
      }, 1000);
    }
  }, [array]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const updatedCSVHeader = csvHeader.map((header) => header.trim());
    const updatedCSVRows = csvRows.map((row) => row.replace('\r','').trim());
    console.log('updatedCSVRows: ', updatedCSVRows);

    const array = updatedCSVRows.map(i => {
      const values = i.split(",");
      const obj = updatedCSVHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const updateData = () => {
    if (array?.length > 0) {
      let newObjectsIndex = 0;
      let newObjectBeingCreated = false;
      array.forEach(async (item, i) => {
        if (item?.objectId?.length > 0) {
          const existingPart = cantileverParts.filter((part) => part.get('partID') === item.partID);
          console.log('existingPart: ', existingPart, existingPart[0]?.get("partID"));
          const part = existingPart.length > 0 ? existingPart[0] : new Parse.Object(className);
          console.log('part1: ', part);
          Object.keys(item).forEach(async (key, index) => {
            newObjectBeingCreated = false;
            if (key === "partID" && part?.get('partID') === undefined) {
              newObjectBeingCreated = true;
              newObjectsIndex = newObjectsIndex + 1;
              console.log('came here1: ', key, existingPart, item);
              part.set("partID", className === 'CantileverParts' ? 'canti-'+(cantileverParts.length+newObjectsIndex) : className === 'PalletRackingParts' ? 'pallet-'+(cantileverParts.length+newObjectsIndex) : className === 'PalletRackingPartsUsed' ? 'pallet-used-'+(cantileverParts.length+newObjectsIndex) : '');
            } else if (key === 'partID' && existingPart[0]?.get('partID') === item[key]) {
              newObjectBeingCreated = false;
              console.log('came here2: ', key, existingPart, item);
              part.set('partID', item[key]);
              console.log('?>>>>', parseInt(part.get('quantity')));
              const upadtedQuantity = parseInt(parseInt(part.get('quantity'))+parseInt(item.quantity));
              part.set('quantity', upadtedQuantity);
              part.set('price', (item['price'])?.toString());
            } else if ( (part?.get('partID') === undefined && key !== 'objectId' && key !== 'partHoldStatus' && key !== "ACL" && key !== "createdAt" && key !== "updatedAt")) {
              console.log('came here3: ', key, existingPart, item);
              if (key === 'quantity') {
                part.set(key, parseInt(item[key]));
              } else {
                part.set(key, item[key]);
              }
            } else if ( !(part?.get('partID') === undefined) && key !== 'objectId' && key !== 'partHoldStatus' && key !== "ACL" && key !== "createdAt" && key !== "updatedAt" && key !== "quantity") {
              console.log('came here4: ', key, existingPart, item);
              part.set(key, item[key]);
            }
            else {
              <></>;
            }
          });
          const savePart = await part.save();
          console.log('savePART: ', savePart);
          setIsLoading(false);
        }
    })
    setAllSaved(true);
    }
  };

  const saveData = async (start, end) => {
    console.log('array: ', array, start, end)
    const saveArray = []
    if (array?.length > 0) {
      for (let index = start; index < end; index++) {
        const item = array[index];
          if (item['COMPANY NAME'].length > 0) {
            const part = new Parse.Object(className);
            Object.keys(item).forEach(async (key, index) => {
              
              if (key === 'COMPANY NAME') {
                part.set('companyName', item[key]);
              } else if ( key === 'PH') {
                part.set('phoneNumber', item[key]);
              } else if ( key === 'NAME') {
                part.set('firstName', item[key]);
              } else if ( key === 'LASTNAME') {
                part.set('lastName', item[key]);
              }
              else {
                part.set((key).toLowerCase(), item[key]);
              }
            })
            const savePart = part.save();
            saveArray.push(savePart);
            console.log('>> ', index);
        }
      }
    };
    // Promise.all(saveArray);
    console.log('\n\n ARRAY LENGTH: ', saveArray)
    Parse.Object.saveAll(saveArray).then((res) => console.log(res));
  }
  

  const saveItem = async () => {
    const newObj = new Parse.Object('CantileverParts');
    newObj.set('objectId', 123);
    await newObj.save()
  };

  // useEffect(() => {
  //   if (!!allSaved) {
  //       getCurrentPartsArray();
  //   }
  // }, [allSaved])

  const getCurrentPartsArray = async () => {
    console.log('classMa,e: ', className);
    const cantileverPartsObj = Parse.Object.extend(className);
    const q = new Parse.Query(cantileverPartsObj);
    q.limit(10000);
    const res = await q.find()
    setCantileverParts(res);
  }

  const handleClassName = (event) => {
    setClassName(event.target.value);
  };
  

  return (
    <div style={{ textAlign: "center" }}>
      <h1>DB FILE IMPORTER</h1>
      <div className="d-flex justify-content-center mt-3">
        <div className="w-50 mb-4">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Class</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={className}
              label="Condition"
              onChange={handleClassName}
            >
              <MenuItem value={'CantileverParts'}>Cantilever</MenuItem>
              <MenuItem value={'PalletRackingParts'}>Pallet Racking</MenuItem>
              <MenuItem value={'PalletRackingPartsUsed'}>Pallet Racking Used</MenuItem>
              <MenuItem value={'ClientDetails'}>Clients</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <form>
        <input
          className="btn btn-secondary mr-4"
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        {!isLoading ? <button
          className="btn btn-secondary"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT CSV
        </button> : <Spinner animation="border" role="status" size='sm' variant="primary"/>}

        {className === 'ClientDetails' && <Box mt={5}>
          <InputBase
          value={start}
          onChange={(e) => {setStart(e.target.value)}}
          sx={{ flex: 1, background: 'white', margin: 1 }}
          />
          <InputBase
            value={end}
            onChange={(e) => {setEnd(e.target.value)}}
            sx={{ flex: 1, background: 'white', margin: 1 }}
          />
          <Button className="btn btn-secondary" onClick={() => {
            saveData(start, end)
            }}>SAVE CLIENTS</Button>
        </Box>}

      </form>

      <br />

      <div>
          {logger.map((log) => {
            <p className="primary-color">{log}</p>
          })}
      </div>

    </div>
  );
}

export default Importer;
