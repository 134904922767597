import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Register from "./components/Register";

import * as Parse from "parse";
import { isNil } from "ramda";
import "react-toastify/dist/ReactToastify.css";
import { ColorModeContext, useMode } from "./theme";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Sidebar from "./global/Sidebar";
import Topbar from "./global/Topbar";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Calendar from "./scenes/calendar/calendar";
import { useSelector } from "react-redux";
import AutomateCantileverSet from "./components/automateCantileverSet";
import AddCantileverPart from "./components/addCantileverPart";
import Quotations from "./components/Quoatations";
import Clients from "./components/Clients";
import QuotationItemDetailComponent from "./components/QuotationItemDetailComponent";
import BuildPalletRacks from "./components/BuildPalletRacking";
import SendEmail from "./components/SendEmailComponent";
import ClientDetail from "./components/ClientDetail";
import MetricsDashboard from "./components/MetricsDashboard";
import CreateQuotation from "./components/CreateQuotation";
import ExistingQuotation from "./components/ExistingQuotation";

const App = () => {
  const [currentUser, setCurrentUser] = useState(undefined);
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const userInfo = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pickRandomNumber, setPickRandomNumber] = useState(6);
  const location = useLocation();

  useEffect(() => {
    if (userInfo?.loggedIn) {
      setCurrentUser(userInfo.id);
    } else {
      setCurrentUser(undefined);
    }
  }, [userInfo]);

  useEffect(() => {
    AuthService.init();
    const appId = !isNil(process.env.REACT_APP_APPLICATION_ID)
      ? process.env.REACT_APP_APPLICATION_ID
      : "APP_ID";
    const jsKey = !isNil(process.env.REACT_APP_JAVASCRIPT_KEY)
      ? process.env.REACT_APP_JAVASCRIPT_KEY
      : "JAVASCRIPT_KEY";
    const serverUrl = !isNil(process.env.REACT_APP_SERVER_URL)
      ? process.env.REACT_APP_SERVER_URL
      : "http://142.93.250.11:1337";

    Parse.initialize(appId, jsKey);
    Parse.serverURL = serverUrl + "/parse";
    Parse.masterKey = "MASTER_KEY";
  }, []);

  useEffect(() => {
    getCurrentUser()
      .then((user) => {
        if (user) {
          setCurrentUser(user);
        } else {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      getCurrentUser()
      .then((user) => {
        if (user) {
          setCurrentUser(user);
    }
  }).catch((err) => {
    console.log('error: ', err);
  })}
  }, [location])

  const getCurrentUser = async () => {
    try {
      setIsLoading(true);
      setPickRandomNumber(Math.floor(Math.random() * 5) + 1);
      const user = await AuthService.getCurrentUser();
      setIsLoading(false);
      return user;
    } catch (error) {
      setIsLoading(false);
      console.log("error: ", error);
    }
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {
          <>
            <div className="app">
              {currentUser && !isLoading && <Sidebar isSidebar={isSidebar} />}
              <main className="content">
                {currentUser ? <Topbar setIsSidebar={setIsSidebar} /> : null }
                <Routes>
                  {(currentUser && !isLoading) ? <>
                    <Route path="/" element={<CreateQuotation />} />
                    <Route path="/existing" element={<ExistingQuotation />} />
                    <Route path="/create-quotation" element={<Dashboard />} />
                    <Route path="/build-cantilever" element={<FAQ />} />
                    <Route
                      exact
                      path="/automate-cantilever"
                      element={<AutomateCantileverSet />}
                    />
                    <Route
                      path="/build-palletRacking"
                      element={<BuildPalletRacks />}
                    />
                    <Route path="/cart" element={<Calendar />} />
                    <Route
                      exact
                      path="/settings"
                      element={<AddCantileverPart />}
                    />
                    <Route exact path="/clients" element={<Clients />} />
                    <Route
                      exact
                      path="/quotations"
                      element={<Quotations />}
                    />
                    <Route exact path="/sendEmail" element={<SendEmail />} />
                    <Route
                      exact
                      path="/metrics"
                      element={<MetricsDashboard />}
                    />
                    <Route
                      exact
                      path="/quotation/:id/:quotationPartClass"
                      element={<QuotationItemDetailComponent />}
                    />
                    <Route
                      exact
                      path="/client/:id"
                      element={<ClientDetail />}
                    />
                  </> : "Just a sec"}
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/register" element={<Register />} />
                </Routes>
              </main>
            </div>

            {isLoading && <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90%"}
            >
              {isLoading && pickRandomNumber === 1 && (
                <div className="spinner-box">
                  <div className="blue-orbit leo"></div>
                  <div className="green-orbit leo"></div>

                  <div className="red-orbit leo"></div>

                  <div className="white-orbit w1 leo"></div>
                  <div className="white-orbit w2 leo"></div>
                  <div className="white-orbit w3 leo"></div>
                </div>
              )}

              {isLoading && pickRandomNumber === 2 && (
                <div className="spinner-box">
                  <div className="leo-border-1">
                    <div className="leo-core-1"></div>
                  </div>
                  <div className="leo-border-2">
                    <div className="leo-core-2"></div>
                  </div>
                </div>
              )}

              {isLoading && pickRandomNumber === 3 && (
                <div className="spinner-box">
                  <div className="configure-border-1">
                    <div className="configure-core"></div>
                  </div>
                  <div className="configure-border-2">
                    <div className="configure-core"></div>
                  </div>
                </div>
              )}

              {isLoading && pickRandomNumber === 4 && (
                <div className="spinner-box">
                  <div className="solar-system">
                    <div className="earth-orbit orbit">
                      <div className="planet earth"></div>
                      <div className="venus-orbit orbit">
                        <div className="planet venus"></div>
                        <div className="mercury-orbit orbit">
                          <div className="planet mercury"></div>
                          <div className="sun"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isLoading && pickRandomNumber === 5 && (
                <div className="psoload">
                  <div className="straight"></div>
                  <div className="curve"></div>
                  <div className="center"></div>
                  <div className="inner"></div>
                </div>
              )}

              {isLoading && pickRandomNumber === 6 && (
                <div id="container">
                  <div className="sphere s-gold"></div>
                  <div className="sphere s-5"></div>
                  <div className="sphere s-4"></div>
                  <div className="sphere s-3"></div>
                  <div className="sphere s-2"></div>
                  <div className="sphere s-1"></div>
                </div>
              )}
            </Box>}
          </>
        }
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
