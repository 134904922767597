import React, { useState, useEffect } from "react";
import Parse from "parse";
import { toast, ToastContainer } from "react-toastify";
import { Spinner } from "react-bootstrap";

function IncrementDecrementPrices() {
  const [incrementValue, setIncrementValue] = useState(0);
  const [decrementvalue, setDecrementValue] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setIncrementValue(e.target.value);
  };
	const handleDecChange = (e) => {
		setDecrementValue(e.target.value);
	}

	const incrementPrices = async () => {
		setIsLoading(true);
		const cantileverPartsQ = new Parse.Query(Parse.Object.extend('CantileverParts'));
		cantileverPartsQ.limit(100000000);
		const parts = await cantileverPartsQ.find();
		try {
			parts.forEach(async (item, index) => {
				console.log('updating price for: ', item.id);
				const currentPrice = item.get('price');
				item.set('price', (currentPrice * (1 + incrementValue/100)).toFixed(2));
				await item.save();
			})
			setIsLoading(false)
			toast.success("Prices successfully updated");
		} catch (error) {
			setIsLoading(false)
			toast.error('Something went wrong', error);
		}
	};

	const decrementPrices = async () => {
		setIsLoading(true);
		const cantileverPartsQ = new Parse.Query(Parse.Object.extend('CantileverParts'));
		cantileverPartsQ.limit(100000000);
		const parts = await cantileverPartsQ.find();
		try {
			parts.forEach(async (item, index) => {
				const currentPrice = item.get('price');
				item.set('price', (currentPrice * (1 - decrementvalue/100)).toFixed(2));
				await item.save();
			})
			setIsLoading(false)
			toast.success("Prices successfully updated");
		} catch (error) {
			setIsLoading(false)
			toast.error('Something went wrong', error);
		}
	};

  return (
    <>
			<ToastContainer />
      INCREMENT
			<div className="d-flex flex-column align-items-center">
				<div><input value={incrementValue} type="number" onChange={handleChange} className="mb-3"/>   %</div>
				<button className="btn btn-round btn-primary" onClick={incrementPrices} disabled={isLoading || incrementValue <= 0}>{!isLoading ? 'Increment' : <Spinner animation="border" size="sm" role="status" variant="primary"/>}</button>
			</div>
			

			DECREMENT
			<div className="d-flex flex-column align-items-center">
				<div><input value={decrementvalue} type="number" onChange={handleDecChange} className="mb-3"/>   %</div>
				<button className="btn btn-round btn-primary" onClick={decrementPrices} disabled={isLoading || decrementvalue <= 0}>{!isLoading ? 'Decrement' : <Spinner animation="border" size="sm" role="status" variant="primary"/>}</button>
			</div>
    </>
  );
}

export default IncrementDecrementPrices;
