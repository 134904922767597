import column from "../assets/images/column.png";
import columnGalv from "../assets/images/galv-column.png";
import arm from "../assets/images/arm-red.png";
import armGalv from "../assets/images/arm-galv.png";
import base from "../assets/images/base-blue.png";
import baseGalv from "../assets/images/base-galv.png";
import baseDouble from "../assets/images/base-double-blue.png";
import baseDoubleGalv from "../assets/images/base-double-galv.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import braceDiagonal from "../assets/images/brace-diagonal.png";
import braceDiagonalGalv from "../assets/images/brace-diagonal-galv.png";
import braceHorizontal from "../assets/images/brace-horizontal.png";
import braceHorizontalGalv from "../assets/images/brace-horizontal-galv.png";
import accessories from '../assets/images/settings.png';
import ReactTooltip from "react-tooltip";
import { useEffect, useState } from "react";
import { Box, IconButton, InputBase, Tooltip, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../theme";
import { initializeQuotationItems, removeFromCart, updateCartItem } from "../features/cart/cartSlice";
import { ACCESSORY } from "./CantileverPart";
import rowSpacer from '../assets/images/rowSpacers.png';
import cogs from '../assets/images/adjust.png';
import barSupport from '../assets/images/palletBarSupport.png';
import particleBoard from '../assets/images/pallettParticleBoard.png';
import supportBarWithRetainer from '../assets/images/supportBarWithRetainer.png';
import hdSupportBar from '../assets/images/hdSupportBar.png';
import forkEntryBars from '../assets/images/forkEntryBars.png';
import spliceKit from '../assets/images/spliceKit.png';
import cornerGuard from '../assets/images/cornerGuard.png';
import singleSidedProtector from '../assets/images/singleSidedProtector.png';
import doubleSidedProtector from '../assets/images/doubleSidedProtector.png';
import footplateHD from '../assets/images/footplateHD.png';
import footplateSTD from '../assets/images/footplateSTD.png';
import dynaBolt from '../assets/images/dynaBolt.png';
import levellingShim from '../assets/images/levellingShim.png';
import DDivider from '../assets/images/DDividers.png';
import palletRackLoadSign from '../assets/images/palletRackLoadSign.png';
import postProtector from '../assets/images/postProtector.png';
import beamSafetyClip from '../assets/images/beamSafetyClip.png';
import { BEAM, FRAME, MESH_DECK, MISC, ROW_SPACER, SUPPORT } from "./PalletRackingPart";
import frame from "../assets/images/palletFrame.png";
import beam from "../assets/images/palletBeam.png";
import meshDeck from '../assets/images/meshDeck.png';
import forkLift from '../assets/images/forkLift.png';
import cageHire from '../assets/images/cageHire.png';
import scissorLift from '../assets/images/scissorLift.png';
import particleBoardZClip from '../assets/images/particleBoardZClip.png';
import dividingPins from '../assets/images/dividingPins.png';
import { Threshold } from "../static/ThresholdQuantities";


export const COLUMN_NAME = "Column";
export const ARM_NAME = "Arm";
export const BASE_NAME = "Base";
export const BRACE_NAME = "Bracing";

const QuotationCartItem = (props) => {
  const parentComponent = props.parentComponent;
  const quotationParts = props.quotationParts;
  const setQuotationParts = props.setQuotationParts;
  const revisionQuotationParts = props.revisionQuotationParts;
  const quotationItems = useSelector((state) => state.cart.quotationItems);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalQuantity, setTotalQuantity] = useState(props.item.quantity);
  const [subTotalPrice, setSubTotalPrice] = useState(props.item.price);
  const [orderQuantityFrom3rdParty, setOrderQuantityFrom3rdParty] = useState(
    -(props.item.part?.get("quantity") - totalQuantity)
  );
  const [orderFrom3rdPartyPrice, setOrderFrom3rdPartyPrice] = useState(
    props.item.part?.get("price")
  );
  const [externalVendorCode, setExternalVendorCode] = useState(1001);

  const dispatch = useDispatch();

  const handleQuantity = (v) => {
    setTotalQuantity(v);
    let quotationItemsCopy;
    if (parentComponent === "quotationRevision") {
    quotationItemsCopy = [...revisionQuotationParts];
    quotationItemsCopy[quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)] = {
      id: props.item.part.id,
      part: props.item.part,
      price: quotationItemsCopy[quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)].price,quantity: parseInt(v),
      section: props.item.section 
    };}


    parentComponent !== "quotationRevision"
      ? dispatch(updateCartItem({ id: props.item.id, quantity: v, section: props.item.section }))
      : dispatch(initializeQuotationItems(quotationItemsCopy)); 
  };

  const handleDelete = (e) => {
    const updatedArrayAfterDeletion = quotationParts.filter((item) => item.id !== props.item.part.id);
    setQuotationParts(updatedArrayAfterDeletion);
    const quotationItemsCopy = [...quotationItems];
    quotationItemsCopy[
      quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)
    ] = { id: props.item.part.id, part: props.item.part, quantity: 0 };

    dispatch(initializeQuotationItems(quotationItemsCopy));
  };

  useEffect(() => {
    setOrderQuantityFrom3rdParty(
      props.item.part?.get("quantity") < 0 ? totalQuantity : totalQuantity - props.item.part?.get("quantity")
    );
  }, [totalQuantity]);

  useEffect(() => {
    if (props.item.part?.get("quantity") < 0) {
      setSubTotalPrice(orderQuantityFrom3rdParty * orderFrom3rdPartyPrice);
      dispatch(updateCartItem({ id: props.item.id, externalVendor: true, price: orderQuantityFrom3rdParty * orderFrom3rdPartyPrice, quantity: totalQuantity, section: props.item.section }));
    }
    else if (props.item.part?.get("quantity") - totalQuantity < 0) {
      setSubTotalPrice(
        props.item.part?.get("quantity") * props.item.part?.get("price") +
          orderQuantityFrom3rdParty * orderFrom3rdPartyPrice
      );
      dispatch(updateCartItem({ id: props.item.id, externalVendor: true, price: props.item.part?.get("quantity") * props.item.part?.get("price") +
      orderQuantityFrom3rdParty * orderFrom3rdPartyPrice, quantity: totalQuantity, section: props.item.section }));
    } else {
      setSubTotalPrice(totalQuantity * props.item.part?.get("price"));
    }
  }, [totalQuantity, orderFrom3rdPartyPrice, orderQuantityFrom3rdParty]);

  useEffect(() => {
    if (parentComponent === "quotationRevision") {
      const quotationItemsCopy = [...revisionQuotationParts];
      quotationItemsCopy[
        quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)
      ] = { id: props.item.part.id, price: subTotalPrice, section: props.item.section, part: props.item.part, quantity: parseInt(totalQuantity) };

      dispatch(initializeQuotationItems(quotationItemsCopy));
    }
  }, [subTotalPrice]);

  const handleThirdPartyPrice = (e) => {
    setOrderFrom3rdPartyPrice(e.target.value);
      if (parentComponent === "quotationRevision") {
        const quotationItemsCopy = [...revisionQuotationParts];
        quotationItemsCopy[
          quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)
        ] = { id: props.item.part.id, part: props.item.part, quantity: quotationItemsCopy[quotationItemsCopy.findIndex((el) => el.id === props.item.part.id)].quantity, section: props.item.section, thirdPartyPrice: e.target.value };
        dispatch(initializeQuotationItems(quotationItemsCopy));
      }
  };

  return (
    <>
      {props.item.part && (
        <Box width="100%">
          <Box display="flex" alignItems="center" mx="0.5em">
            <img
              alt="profile-user"
              width="100px"
              height="100px"
              style={{ cursor: "pointer", borderRadius: "50%" }}
              src={
                props.item.part?.get("name") === COLUMN_NAME &&
                props.item.part?.get("finish") === "Blue"
                  ? column
                  : props.item.part?.get("finish") === "Galv" &&
                    props.item.part?.get("name") === COLUMN_NAME
                  ? columnGalv
                  : props.item.part?.get("name") === ARM_NAME &&
                    props.item.part?.get("finish") === "Blue"
                  ? arm
                  : props.item.part?.get("name") === ARM_NAME &&
                    props.item.part?.get("finish") === "Galv"
                  ? armGalv
                  : props.item.part?.get("name") === BASE_NAME &&
                    props.item.part?.get("finish") === "Blue" &&
                    props.item.part?.get("sides") === "1"
                  ? base
                  : props.item.part?.get("name") === BASE_NAME &&
                    props.item.part?.get("finish") === "Galv" &&
                    props.item.part?.get("sides") === "1"
                  ? baseGalv
                  : props.item.part?.get("name") === BASE_NAME &&
                    props.item.part?.get("finish") === "Blue" &&
                    props.item.part?.get("sides") === "2"
                  ? baseDouble
                  : props.item.part?.get("name") === BASE_NAME &&
                    props.item.part?.get("finish") === "Galv" &&
                    props.item.part?.get("sides") === "2"
                  ? baseDoubleGalv
                  : props.item.part?.get("name") === BRACE_NAME &&
                    props.item.part?.get("bayCenter").split(" ")[0] ===
                      "Diagonal" &&
                    props.item.part?.get("finish") === "Blue"
                  ? braceDiagonal
                  : props.item.part?.get("name") === BRACE_NAME &&
                    props.item.part?.get("bayCenter").split(" ")[0] ===
                      "Diagonal" &&
                    props.item.part?.get("finish") === "Galv"
                  ? braceDiagonalGalv
                  : props.item.part?.get("name") === BRACE_NAME &&
                    props.item.part?.get("bayCenter").split(" ")[0] ===
                      "Horizontal" &&
                    props.item.part?.get("finish") === "Galv"
                  ? braceHorizontalGalv
                  : props.item.part?.get("name") === BRACE_NAME &&
                    props.item.part?.get("bayCenter").split(" ")[0] ===
                      "Horizontal" &&
                    props.item.part?.get("finish") === "Blue"
                  ? braceHorizontal
                  : props.item?.part?.get("category") === ACCESSORY 
                  ? props.item?.part?.get('partImage')
                  ? props.item?.part?.get('partImage')
                  : accessories
                  : props.item?.part?.get("name") === FRAME
                  ? frame
                  : props.item?.part?.get("name") === BEAM
                  ? beam
                  : props.item?.part?.get("name") === SUPPORT && 
                  props.item?.part?.get('description').includes('Pallet Bar Support')
                  ? barSupport
                  : props.item?.part?.get("name") === SUPPORT && 
                  props.item.part.get('description').includes('Particle Board Retaining Clip (Z Clip)')
                  ? particleBoardZClip
                  : props.item?.part?.get("name") === SUPPORT && 
                  props.item?.part?.get('description').includes('Particle Board')
                  ? particleBoard
                  : props.item?.part?.get("name") === SUPPORT && 
                  props.item?.part?.get('description').includes('HD')
                  ? hdSupportBar
                  : props.item?.part?.get("name") === SUPPORT && 
                  props.item?.part?.get('description').includes('Retainer')
                  ? supportBarWithRetainer
                  : props.item?.part?.get("name") === ROW_SPACER
                  ? rowSpacer
                  : props.item?.part?.get("name") === MESH_DECK
                  ? meshDeck
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Fork Entry Bars')
                  ? forkEntryBars
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Splice')
                  ? spliceKit
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Corner')
                  ? cornerGuard
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Single Sided')
                  ? singleSidedProtector
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Double Sided')
                  ? doubleSidedProtector
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Standard footplate')
                  ? footplateSTD
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Dyna')
                  ? dynaBolt
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Levelling')
                  ? levellingShim
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('D Dividers')
                  ? DDivider
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Pallet Rack Load Sign')
                  ? palletRackLoadSign
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Post protectors')
                  ? postProtector
                  : props.item?.part?.get("name") === ACCESSORY && 
                  props.item?.part?.get('description').includes('Beam Safety Clips')
                  ? beamSafetyClip
                  : props.item.part.get("name") === ACCESSORY && 
                  props.item.part.get('description').includes('Dividing Pins')
                  ? dividingPins
                  : props.item?.part?.get("name") === MISC && 
                  props.item?.part?.get('description').includes('Fork Lift')
                  ? forkLift
                  : props.item?.part?.get("name") === MISC && 
                  props.item?.part?.get('description').includes('Scissor Lift')
                  ? scissorLift
                  : props.item?.part?.get("name") === MISC && 
                  props.item?.part?.get('description').includes('Cage')
                  ? cageHire
                  : cogs
              }
            ></img>
            <div className="d-flex flex-column flex-start">
              <div className="d-flex">
                {props.item.part.className === "CantileverParts" 
                ? <p className="q-color">{props.item.part?.get("name")}</p>
                : <p className="q-color">{props.item.part?.get("name")} {props.item.part?.get("description")} <strong>( {props.item.part.get('condition')} )</strong></p>}
                {props.item.part?.get("name") === BRACE_NAME && (
                  <p className="ml-1">
                    {"(" + props.item.part?.get("layout") + ")"}
                  </p>
                )}
              </div>
              {props?.item?.part?.className === "CantileverParts" && props?.item?.part?.get('name') === COLUMN_NAME ? <ul>
              <li>{props.item.part.get("height")} High / {props.item.part.get("width")}</li>
              </ul> : props?.item.part?.get('name') === BASE_NAME ? <ul>
          <li>{props?.item.part.get("sides") === "1" ? 'S/S' : 'D/S'} for {props?.item.part.get("armToSuitBase")} Arms / {props?.item.part.get("duty") === 'Light' ? '200' : props?.item.part.get("duty") === 'Medium' ? '305' : props?.item.part.get("duty") === 'Heavy' ? '350' : null } ({props?.item.part.get("width")})  </li>
        </ul> : props?.item.part.get('name') === ARM_NAME ? <ul>
          <li>H{props?.item.part.get("height")}</li>
          <li> {props?.item.part.get("depth")}</li>
        </ul> : props?.item.part.get('name') === BRACE_NAME ? <ul>
          <li>{props?.item.part.get("bayCenter").split(" ")[0] === "Horizontal" ? "H" : 'D'} {props?.item.part.get("bayCenter").split(" ")[1]} c/c ({props?.item.part.get("width")})</li>
        </ul> : props?.item.part.get('dimensions')}
              {
                (props.item.part.className === "CantileverParts" || props.item.part.className === "PalletRackingParts") &&
                props.item.part?.get('quantity') < Threshold[props.item.part?.get('name')] &&  
                (props.item.part?.get('partHoldStatus')) &&
                Object.keys(props.item.part?.get('partHoldStatus'))?.map((key) => (
                  <Box
                  key={key}

                  display='flex'
                  justifyContent='space-between'
                  className="blink"
                  >
                    <Typography color={'red'}>On Hold - Quotation {key} - Quantity</Typography>
                    <Typography color={'red'}> {props.item.part.get('partHoldStatus')[key].quantity}</Typography>
                  </Box>
                ))
              }
            </div>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mt="0.5em"
            px="0.5em"
          >
              <ReactTooltip
                id="title"
                effect="solid"
                place="top"
                class="q-color"
              >
                Quantity
              </ReactTooltip>
              <div className="d-flex flex-column mx-2">
                <Typography>Qty.</Typography>
                <InputBase
                  data-tip
                  data-for="title"
                  placeholder="Quantity"
                  className="outlined border rounded px-2 mx-1"
                  name="title"
                  value={totalQuantity}
                  onChange={(e) => handleQuantity(e.target.value)}
                  onBlur={() => {}}
                  sx={{ flex: 1, width: "50px" }}
                />
              </div>
              {props.item.part?.get("quantity") - totalQuantity < 0 && (
                <div className="d-flex flex-column mx-2">
                  <ReactTooltip
                    id="title required"
                    effect="solid"
                    place="top"
                    class="q-color"
                  >
                    Order From External Vendor
                  </ReactTooltip>
                  <div className="d-flex flex-column">
                    <Typography>Order Ext. V</Typography>
                    <InputBase
                      data-tip
                      data-for="title required"
                      placeholder="order from external vendor"
                      className="outlined border rounded px-2 mx-1"
                      name="title"
                      value={orderQuantityFrom3rdParty}
                      onChange={(e) =>
                        setOrderQuantityFrom3rdParty(e.target.value)
                      }
                      onBlur={() => {}}
                      sx={{ flex: 1, width: "50px" }}
                    />
                  </div>
                </div>
              )}
              {props.item.part?.get("quantity") - totalQuantity < 0 && (
                <>
                  <ReactTooltip
                    id="title required price"
                    effect="solid"
                    place="top"
                    class="q-color"
                  >
                    Price For Each From External Vendor
                  </ReactTooltip>
                  <div className="d-flex flex-column mx-2">
                    <Typography>Price Ext. V</Typography>
                    <InputBase
                      data-tip
                      data-for="title required price"
                      placeholder="price for each from external vendor"
                      className="outlined border rounded px-2 mx-1"
                      name="title"
                      value={orderFrom3rdPartyPrice}
                      onChange={(e) => handleThirdPartyPrice(e)}
                      onBlur={() => {}}
                      sx={{ flex: 1, width: "50px" }}
                    />
                  </div>

                  <ReactTooltip
                    id="code"
                    effect="solid"
                    place="top"
                    class="q-color"
                  >
                    External Vendor Code
                  </ReactTooltip>
                  <div className="d-flex flex-column mx-2">
                    <Typography>Ext. V Code</Typography>
                    <InputBase
                      data-tip
                      data-for="code"
                      className="outlined border rounded px-2 mx-1"
                      placeholder="External vendor Code"
                      name="code"
                      value={externalVendorCode}
                      onChange={(e) => setExternalVendorCode(e.target.value)}
                      onBlur={() => {}}
                      sx={{ flex: 1, width: "50px" }}
                    />
                  </div>
                </>
              )}
          </Box>
          <div className="d-flex justify-content-between align-items-center px-2 mt-2">
            <strong className="q-color">Price:</strong>
            <p className="q-color">${props.item.part?.get("price")}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center px-2 mt-2">
            <strong className="q-color">Total Quantity in Inventory:</strong>
            {props.item.part?.get("quantity") - totalQuantity > 0 ?<p className="q-color">{props.item.part?.get("quantity") - totalQuantity}</p> : <p className="text-danger font-weight-bold">{props.item.part?.get("quantity") - totalQuantity}</p>}
          </div>
          {props.item.part?.get("name") === "Frame" && <div className="d-flex justify-content-between align-items-center px-2 mt-2">
            <strong className="q-color">Brand:</strong>
            {props.item.part?.get("brand")}
          </div>}
          <div className="d-flex justify-content-between align-items-center px-2 mt-2">
            <strong className="q-color">Finish:</strong>
            {props.item.part?.get("finish")}
          </div>
          {props.item.part?.get("quantity") - totalQuantity < 0 && (
            <div className="d-flex justify-content-between align-items-center px-2 mt-2">
              <strong className="q-color">
                Price for each part ordered from External Vendor:
              </strong>
              <p className="q-color">${orderFrom3rdPartyPrice}</p>
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center px-2 mt-2">
            <strong className="q-color">SubTotal:</strong>
            <p className="q-color">${subTotalPrice?.toFixed(2)}</p>
          </div>
          <Box className="text-right px-2 mt-2">
            <Tooltip title="Delete" placement="top">
              <IconButton
                className="cursor-pointer"
                tooltip="Re-ordering is disabled while filtering"
                onClick={(e) => {
                  parentComponent === "quotationRevision"
                    ? handleDelete(e)
                    : dispatch(removeFromCart(props.item));
                }}
              >
                <DeleteOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.redAccent[500] }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <hr />
        </Box>
      )}
    </>
  );
};
export default QuotationCartItem;
