import Parse from 'parse';
import { Button, InputBase, Typography, useTheme, Box } from "@mui/material";
import * as React from "react";
import { tokens } from "../theme";
import { useState } from "react";
import { Spinner } from 'react-bootstrap';
import EmailBody from './EmailBody';
import EmailCC from './EmailCC';

export default function SendEmail(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [fileName, setFileName] = useState('');
    const [file, setQuotationFile] = useState();
    const [recepientEmail, setReceipientEmail] = useState('');
    const [clientName, setClientName] = useState('');
    const [base64FileData, setBase64FileData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [emailBodyText, setEmailBodyText] = useState('');
    const [emailCC, setEmailCC] = useState('');

    React.useEffect(() => {
      setEmailBodyText(`Hi [client firstName lastName],\n\nHope you are doing well.\n\nFurther to your enquiry, Please, find attached our quote for [quotationType].\n\nI hope the above quote meets your requirements. Please do not hesitate to contact us should you have any questions.\n\nWe look forward to your kind response and hope to work with you on this project.\n\nKind Regards,\n\n${Parse.User.current().get('firstName') + ' ' + Parse.User.current().get('lastName')}\n${(Parse.User.current()).get('title')}\n${(Parse.User.current()).get('designation')}\nPh: ${(Parse.User.current()).get('phone')}\nEmail: ${Parse.User.current().get('email')}\nWebsite: www.storemax.com.au`)
    }, []);

    const fileToDataUri = (file) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result)
        };
        return reader.readAsDataURL(file);
        })

    const handleOnChange = async (e) => {
        const file = e.target.files[0];
        setFileName(e.target.files[0].name);
        var reader = new FileReader();
        reader.readAsBinaryString(file);
        let fileDATA = "";
        reader.onload = function() {
            fileDATA = btoa(reader.result);
            console.log(btoa(reader.result));
        };
        reader.onerror = function() {
            console.log('there are some problems');
        };
        const fileURI = await fileToDataUri(file);
        var base64 = fileURI;
        var parseFile = new Parse.File(`${file.name.split('.')[0].replace(/ *\([^)]*\) */g, "").trim()}`, { uri: base64 });
        setBase64FileData(fileDATA);
        setQuotationFile(parseFile);
      };

      const parseEmails = (input) => {
        // Split the input string into an array based on commas
        const emailsArray = input.split(',');
    
        // Trim white spaces from each email address and store them in a new array
        const trimmedEmailsArray = emailsArray.map(function(email) {
            return email.trim();
        });
    
        return trimmedEmailsArray;
      }

    const sendEmail = async () => {
        const customEmail = new Parse.Object("customEmail");
        customEmail.set('recepientEmail', recepientEmail);
        customEmail.set('clientName', clientName);
        customEmail.set('file', file);

        try {
            setIsLoading(true);
            await customEmail.save();
            await Parse.Cloud.run('sendCustomEmail', {
                customEmailId: customEmail.id,
                fileName: fileName.split('.')[0].replace(/ *\([^)]*\) */g, "").trim(),
                sender: Parse.User.current().get('email'),
                salesRep: Parse.User.current().get('firstName') + ' ' + Parse.User.current().get('lastName'),
                recepient: recepientEmail,
                base64FileData: base64FileData,
                clientName: clientName,
                emailBodyText: emailBodyText,
                emailCCList: parseEmails(emailCC),
              });
            setClientName('');
            setReceipientEmail('');
            setFileName('');
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    }

  return (
    <Box display="flex" justifyContent='center' alignItems="center" mt="25px" mx="1em">

      <div>
        <div className="d-flex flex-column mx-2">
            <Typography variant="h5"
            fontWeight="700"
            my="0.25em"
            color={colors.greenAccent[400]}
            >
                Recepient Email
            </Typography>
            <InputBase
                data-tip
                data-for="title"
                placeholder="Recepient Email"
                className="outlined border rounded px-2 mx-1"
                name="recepientEmail"
                value={recepientEmail}
                onChange={(e) => setReceipientEmail(e.target.value)}
                onBlur={() => {}} 
            />
        </div>
        <div className="d-flex flex-column mx-2">
            <Typography variant="h5"
            fontWeight="700"
            my="0.25em"
            color={colors.greenAccent[400]}
            >
                Email Body
            </Typography>
            <Box p={'5px'} border='1px solid grey' borderRadius='4px' mt='10px'>
              <EmailBody emailBodyText={emailBodyText} setEmailBodyText={setEmailBodyText}/>
            </Box>
        </div>
        <div className="d-flex flex-column mx-2">
            <Typography variant="h5"
            fontWeight="700"
            my="0.25em"
            color={colors.greenAccent[400]}
            >
            Email CC
            </Typography>
            <Box p={'5px'} border='1px solid grey' borderRadius='4px' mt='10px'>
              <EmailCC emailCC={emailCC} setEmailCC={setEmailCC}/>
            </Box>
        </div>
        <div>
          <Typography
            variant="h5"
            fontWeight="700"
            my="0.25em"
            color={colors.greenAccent[400]}
          >
            Send a custom email
          </Typography>
          {
            <div className="text-center">
              <Box
                p={"5px"}
                border="1px solid grey"
                borderRadius="4px"
                mt="10px"
              >
                <input
                  className="btn btn-secondary my-3"
                  type={"file"}
                  id={"pdfFileInput"}
                  accept={".pdf"}
                  onChange={handleOnChange}
                />
              </Box>
              <Button
                sx={{
                  backgroundColor: colors.greenAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  mt: "1em",
                }}
                disabled={
                  false
                }
                onClick={() => sendEmail()}
              >
                {!isLoading ? (
                  "Send Email"
                ) : (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    variant="primary"
                  />
                )}
              </Button>
            </div>
          }
        </div>
      </div>
    </Box>
  );
}
