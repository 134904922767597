import React, { useState, useEffect, useRef } from "react";
import * as Parse from "parse";
import { ToastContainer, toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import { useDispatch, useSelector } from "react-redux";
import QuotationCartItem, { ARM_NAME, BASE_NAME, BRACE_NAME, COLUMN_NAME } from "../../components/QuotationCartItem";
import { Box } from "@mui/system";
import { Accordion, AccordionDetails, AccordionSummary, Button, Fade, IconButton, InputBase, MenuItem, Modal, Select, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RegisterClient from "../../components/registerClient";
import { addAnotherSection, currentActiveSection, deleteSection, emptyCart, patchQuotationNumber, resetState, setSelectedPercentageToWin, setSelectedQuotationType } from "../../features/cart/cartSlice";
import Tab from "../../components/TabComponent";
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import DownloadQuotationBeforeSending from "../../components/DownloadQuotationDocBeforeSending";
import MiscCartItem from "../../components/MiscCartItem";
import { Spinner } from "react-bootstrap";
import SelectQuotationType from "../../components/SelectquotationType";
import EmailBody from "../../components/EmailBody";
import EmailCC from "../../components/EmailCC";
import CreateQuotationNewOrExisting from "../../components/CreateQuotationNewOrExisting";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { calculateOverallTotal, calculateSectionTotal, containsAlphabetAfterHyphen } from "../../components/utils";
import { getNextStringId } from "../../core/utils/generateRevisionAlphabets";

export const quotationTypes = ['Cantilever Racks', 'Pallet Racks', 'Light Duty Shelving', 'Conveyors', 'Mezzanine Floors', 'Installation', 'Audit', 'Special Racking', 'Miscellaneous'];
export const quotationTypesShort = { 'Cantilever Racks': "canti", 'Pallet Racks': 'pallet', 'Light Duty Shelving': "LDutyShelv", 'Conveyors': "conv", 'Mezzanine Floors': "mezz", 'Installation': "install", 'Audit': "audit", 'Special Racking': "SRacking", 'Miscellaneous': "misc" };

const formatTypes = (array) => {
  let renderedString = "";

  if (array.length === 1) {
    renderedString = array[0];
  } else if (array.length > 1) {
    renderedString = array.slice(0, -1).join(", ") + " and " + array[array.length - 1];
  }

  return renderedString
}

const Calendar = (props) => {
  const [total, setTotal] = useState(0);
  const cartSection = useSelector((state) => state.cart.sections);
  const cartSections = useSelector((state) => state.cart.sections.sectionCount);
  const cartSectionsCount = Array.from(Array(cartSections).keys());
  const dispatch = useDispatch();
  const cartItemsGlobal = useSelector((state) => state.cart.cartItems);
  const miscCartItems = useSelector((state) => state.cart.miscCantileverItems);
  const quotationNumber = useSelector((state) => state.cart.quotationNumber);
  const selectedClientFromStore = useSelector((state) => state.cart.selectedClient);
  const selectedQuotationType = useSelector((state) => state.cart.quotationType);
  const revisionInProgress = useSelector((state) => state.cart.revisionInProgress);
  const quotation = useSelector((state) => state.cart.revisionQuotation);
  const selectedPercentageToWin = useSelector((state) => state.cart.quotationPercentageToWin);
  const [selectedClient, setLocalSelectedClient] = useState();
  const [selectClient, setSelectClient] = useState(true);
  const [allQuotationPartsToSave, setAllQuotationPartsToSave] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [originalTotalPrice, setOriginalTotalPrice] = useState();
  const [discountPercentage, setDiscountPercentage] = useState({ 1: '' });
  const [marginPercentage, setMarginPercentage] = useState({ 1: '' });
  const [totalPriceRelativeToSection, setTotalPriceRelativeToSection] = useState({});
  const [totalDiscountedPriceRelativeToSection, setTotalDiscountedPriceRelativeToSection] = useState({});
  const [totalMarginalizedPriceRelativeToSection, setTotalMarginalizedPriceRelativeToSection] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [file, setQuotationFile] = useState();
  const [fileName, setFileName] = useState('');
  const [waitForQuotationSend, setWaitForQuotationSend] = useState(false);
  const [base64FileData, setBase64FileData] = useState();
  const [quotationEditTermsModal, setQuotationEditTermsModal] = useState(false);
  const [emailBodyText, setEmailBodyText] = useState('');
  const [emailCC, setEmailCC] = useState('');
  const [registerClient, setRegisterClient] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [validateQuotationNumber, setValidateQuotationNumber] = useState(false);
  const [sections, setSections] = useState([]);
  const [quotationStatus, setQuotationStatus] = useState('in-process');
  const [latestQuotationRevisionNumber, setLatestQuotationRevisionNumber] = useState("");
  const [quotationTerms, setQuotationTerms] = useState({
    1: '100% (Variable) Deposit along with the order, for all stocked items',
    2: 'Quoted Price includes / does not include (Variable) cost of installation',
    3: 'Delivery of Goods 5-7 (Variable) working days from the date of Purchase order &amp; deposit',
    4: 'Client to supply forkLift and scissorLift for the duration of installation'
  });
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colors.greenAccent[600],
      '&:hover': {
        backgroundColor: alpha(colors.greenAccent[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.greenAccent[600],
    },
  }));
  const [sectionTotalsEnabled, setSectionTotalsEnabled] = useState(true);
  const [marginEnabled, setMarginEnabled] = useState({ '1': false });
  const [discountEnabled, setDiscountEnabled] = useState({ '1': false });
  const [onlyQuotaionTotal, setOnlyQuotationTotal] = useState(0);
  const [makeQuotationOnlyEnabled, setMakeQuotationOnlyEnabled] = useState(false);
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (selectedClientFromStore) {
      const clone = Object.assign({}, selectedClientFromStore);
      const jsonObj = selectedClientFromStore.parseObject;
      const clientParseObject = Parse.Object.fromJSON(Object.assign({ ...jsonObj }, { className: 'ClientDetails', objectId: selectedClientFromStore.id }));
      setLocalSelectedClient(Object.assign(clone, { parseObject: clientParseObject }));
    }
  }, [selectedClientFromStore]);

  useEffect(() => {
    if (cartItemsGlobal[0]?.part.className === "PalletRackingParts") {
      dispatch(setSelectedQuotationType(['Pallet Racks']));
    }
    if (!quotationNumber.length > 0) {
      getLatestQuotationNumber();
    }
  }, []);

  useEffect(() => {
    if (selectedClient && selectedQuotationType.length > 0) {
      setEmailBodyText(`Hi ${selectedClient?.parseObject?.get('firstName')?.trim() + ' ' + selectedClient?.parseObject?.get('lastName')?.trim()},\n\nHope you are doing well.\n\nFurther to your enquiry, Please, find attached our quote for ${formatTypes(selectedQuotationType)}.\n\nI hope the above quote meets your requirements. Please do not hesitate to contact us should you have any questions.\n\nWe look forward to your kind response and hope to work with you on this project.\n\nKind Regards,\n\n${Parse.User.current().get('firstName') + ' ' + Parse.User.current().get('lastName')}\n${(Parse.User.current()).get('title')}\n${(Parse.User.current()).get('designation')}\nPh: ${(Parse.User.current()).get('phone')}\nEmail: ${Parse.User.current().get('email')}\nWebsite: www.storemax.com.au`)
    };
    if (selectedClient && selectedClient?.parseObject?.get('discount')?.length > 0) {
      setDiscountEnabled({ 1: true });
      setDiscountPercentage({ 1: selectedClient?.parseObject?.get('discount') })
    } else if (selectedClient && selectedClient?.parseObject?.get('margin')?.length > 0) {
      setMarginEnabled({ 1: true });
      setMarginPercentage({ 1: selectedClient?.parseObject?.get('margin') })
    }
  }, [selectedClient, selectedQuotationType]);

  const handleTabClick = (tabIndex, scroll=false) => {
    setActiveTab(tabIndex);
    makeCurrentActiveSection(tabIndex);
    const sectionTotal = document.getElementById('section-total');
    if (sectionTotal && scroll) {
      sectionTotal.scrollIntoView({ behavior: 'smooth', block: 'end' });
      setTimeout(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 250);
    }
  };

  useEffect(() => {
    const discountPercentageDictCopy = Object.assign({}, discountPercentage);
    const marginPercentageDictCopy = Object.assign({}, marginPercentage);
    cartSectionsCount.map((section) => {
      if (discountPercentageDictCopy[section + 1] === undefined) return discountPercentageDictCopy[section + 1] = '';
      if (marginPercentageDictCopy[section + 1] === undefined) return marginPercentageDictCopy[section + 1] = '';
    })
    setDiscountPercentage(discountPercentageDictCopy);
    setMarginPercentage(marginPercentageDictCopy);
  }, [cartSections]);

  useEffect(() => {
    const totalPricePerSection = {};
    const totalPartsInfo = [];
    const productsRelativeToSection = {};
    cartItemsGlobal.map((part) => {
      totalPartsInfo.push({
        id: part.part.id,
        partID: part.part.get('partID'),
        className: part.part.className,
        name: part.part.get('name'),
        description: part.part.get('description'),
        finish: part.part.get('finish'),
        brand: part.part?.get('brand'),
        sides: part.part.get('sides'),
        duty: part.part.get('duty'),
        loadingcap: part.part.get('loadingCap'),
        layout: part.part.get('layout'),
        armToSuitBase: part.part.get('armToSuitBase'),
        bayCenter: part.part.get('bayCenter'),
        dims: part.part.get('dimensions'),
        width: part.part.get('width'),
        height: part.part.get('height'),
        depth: part.part.get('depth'),
        quantity: part.quantity,
        inventoryQuantity: part.part.get('quantity'),
        price: part.part.get('price'),
        section: part.section
      });
      productsRelativeToSection[part.section]?.length > 0 ? productsRelativeToSection[part.section].push(part) : productsRelativeToSection[part.section] = [part]
      return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + part.price : part.price;
    });
    miscCartItems.map((part) => {
      productsRelativeToSection[part.section]?.length > 0 ? productsRelativeToSection[part.section].push(part) : productsRelativeToSection[part.section] = [part]
      return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + part.price : part.price;
    })
    const sectionsArr = [];
    Object.keys(productsRelativeToSection).map((k) => {
      return sectionsArr.push({ products: productsRelativeToSection[k], marginPercentage: selectedClient?.parseObject?.get('margin')?.length > 0 ? selectedClient?.parseObject?.get('margin') : 0, discountPercentage: selectedClient?.parseObject?.get('discount')?.length > 0 ? selectedClient?.parseObject?.get('discount') : 0 })
    })
    if (sections.length === 0) {
      setSections(sectionsArr);
    } else {
      const newSectionsArr = sections.map((section, index) => {
        const updatedProducts = productsRelativeToSection[index + 1] || [];
        return { ...section, products: updatedProducts, discountPercentage: section.discountPercentage ? section.discountPercentage : 0, marginPercentage: section.marginPercentage ? section.marginPercentage : 0 };
      });
      setSections(newSectionsArr);
    }
    setTotalPriceRelativeToSection(totalPricePerSection);
    setAllQuotationPartsToSave(totalPartsInfo);
    calculateTotal();
  }, [cartItemsGlobal, miscCartItems, selectedClient])

  const calculateTotal = () => {
    let total = 0;
    cartItemsGlobal.map((item) => {
      return total = total + (item.price)
    })
    setTotal(total);
    setOriginalTotalPrice(total);
  }

  useEffect(() => {
    if (marginPercentage === undefined || marginPercentage === null || marginPercentage === '') {
    }
    else {
      const totalMarginPricePerSection = {};
      Object.keys(totalPriceRelativeToSection).map((k) => {
        return k === activeTab ? totalMarginPricePerSection[k] = (totalPriceRelativeToSection[k] + (totalPriceRelativeToSection[k] / 100) * (marginPercentage)) : totalMarginPricePerSection[k] = totalPriceRelativeToSection[k]
      });
      totalMarginPricePerSection[activeTab] = (totalPriceRelativeToSection[activeTab] + (totalPriceRelativeToSection[activeTab] / 100) * (marginPercentage[activeTab]))
      setTotalMarginalizedPriceRelativeToSection(totalMarginPricePerSection);
    }
  }, [marginPercentage]);

  useEffect(() => {
    if (discountPercentage === undefined || discountPercentage === null || discountPercentage === '') {
    }
    else {
      const totalDiscountedPricePerSection = {};
      Object.keys(totalPriceRelativeToSection).map((k) => {
        return k === activeTab ? totalDiscountedPricePerSection[k] = (totalPriceRelativeToSection[k] - (totalPriceRelativeToSection[k] / 100) * (discountPercentage)) : totalDiscountedPricePerSection[k] = totalPriceRelativeToSection[k]
      });
      totalDiscountedPricePerSection[activeTab] = (totalPriceRelativeToSection[activeTab] - (totalPriceRelativeToSection[activeTab] / 100) * (discountPercentage[activeTab]))
      setTotalDiscountedPriceRelativeToSection(totalDiscountedPricePerSection);
    }
  }, [discountPercentage]);

  useEffect(() => {
    setActiveTab(cartSection.currentActive);
  }, [cartSection]);

  const saveAndSendQuotation = async () => {
    setWaitForQuotationSend(true);
    const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations'));
    quotationsQuery.equalTo('quotationNumber', quotationNumber);
    const existingQuote = await quotationsQuery.find();
    const quotation = existingQuote.length > 0 ? existingQuote[0] : new Parse.Object("Quotations");
    if (!existingQuote.length > 0) {
      const relCol = quotation.relation('cantileverColumn')
      const relBase = quotation.relation('cantileverBase')
      const relArm = quotation.relation('cantileverArm')
      const relDiagonal = quotation.relation('cantileverBraceDiagonal')
      const relHorizontal = quotation.relation('cantileverBraceHorizontal')
      const quotationQuantities = {};
      cartItemsGlobal.forEach(element => {
        if (element.part.get('name') === COLUMN_NAME) {
          relCol.add(element.part);
          quotation.set('cantileverColumnQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === BASE_NAME) {
          relBase.add(element.part);
          quotation.set('cantileverBaseQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === ARM_NAME) {
          relArm.add(element.part);
          quotation.set('cantileverArmQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === BRACE_NAME) {
          if (element.part.get('bayCenter').split(' ')[0] === 'Diagonal') {
            relDiagonal.add(element.part);
            quotation.set('cantileverBraceDiagonalQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
          else {
            relHorizontal.add(element.part);
            quotation.set('cantileverBraceHorizontalQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
        }
      });
      quotation.set('quotationQuantities', quotationQuantities);
    }
    quotation.set('createdBy', Parse.User.current());
    quotation.set('updatedBy', Parse.User.current());
    quotation.set('cart', props.cartItems);
    quotation.set('state', [cartSection]);
    quotation.set('status', "open");
    quotation.set('cartItemsState', [JSON.stringify(cartItemsGlobal)]);
    quotation.set('quotationStatus', "open");
    quotation.set('winProbability', selectedPercentageToWin);
    quotation.set('quotationType', selectedQuotationType);
    quotation.set('selectedClient', { data: selectedClient?.parseObject.toJSON() });
    quotation.set('totalPrice', ((overallTotal + (overallTotal / 100 * 10)).toFixed(2)).toString());
    quotation.set('file', file);
    quotation.set('miscParts', miscCartItems);
    quotation.set('quotationParts', allQuotationPartsToSave);
    quotation.set('quotationNumber', quotationNumber);
    quotation.set('quotationMargin', marginPercentage);
    quotation.set('quotationDiscount', discountPercentage);
    quotation.set('marginEnabled', marginEnabled);
    quotation.set('discountEnabled', discountEnabled);
    quotation.set('sections', sections);
    try {
      await quotation.save();
      await Parse.Cloud.run('sendMail', {
        quotationId: quotation.id,
        fileName: fileName.split('.')[0].replace(/ *\([^)]*\) */g, "").trim(),
        sender: Parse.User.current().get('email'),
        salesPerson: JSON.stringify(Parse.User.current()),
        quotationTypes: selectedQuotationType,
        salesRep: Parse.User.current().get('firstName') + ' ' + Parse.User.current().get('lastName'),
        recepient: selectedClient.parseObject.get('email'),
        clientName: selectedClient.parseObject.get('firstName').trim() + ' ' + selectedClient.parseObject.get('lastName').trim(),
        base64FileData: base64FileData,
        emailCCList: parseEmails(emailCC),
        emailBodyText: emailBodyText
      });
      setSelectedQuotationType([])
      setWaitForQuotationSend(false);
      setQuotationFile(undefined);
      toast.success('Quoatation successfully sent!');
      dispatch(emptyCart());
      dispatch(resetState());
      setLocalSelectedClient();
    } catch (error) {
      setWaitForQuotationSend(false);
      console.log('error: ', error);
      toast.error('Something went wrong, ', error);
    }
  }

  const makeQuotationOnly = async () => {
    setWaitForQuotationSend(true);
    const quotation = new Parse.Object("Quotations");
    quotation.set('createdBy', Parse.User.current());
    quotation.set('updatedBy', Parse.User.current());
    quotation.set('cart', props.cartItems);
    quotation.set('state', [cartSection]);
    quotation.set('status', 'open');
    quotation.set('winProbability', selectedPercentageToWin);
    quotation.set('emptyQuotation', true);
    quotation.set('emptyQuotationTotal', onlyQuotaionTotal.toString());
    quotation.set('cartItemsState', [JSON.stringify(cartItemsGlobal)]);
    quotation.set('quotationType', selectedQuotationType);
    quotation.set('client', { data: selectedClient?.parseObject.toJSON() });
    quotation.set('totalPrice', ((Math.round(total * 100) / 100) +
      (Math.round(total * 100) / 1000))?.toFixed(2))
    quotation.set('file', file);
    quotation.set('miscParts', miscCartItems);
    quotation.set('quotationParts', allQuotationPartsToSave);
    quotation.set('quotationNumber', quotationNumber);
    try {
      await quotation.save();
      setWaitForQuotationSend(false);
      setQuotationFile(undefined);
      toast.success('Quoatation successfully saved!');
      dispatch(emptyCart());
      dispatch(resetState());
      setLocalSelectedClient();
      setMakeQuotationOnlyEnabled(false);
    } catch (error) {
      setWaitForQuotationSend(false);
      console.log('error: ', error);
      toast.error('Something went wrong, ', error);
    }
  };

  const addSection = () => {
    dispatch(addAnotherSection());
  }

  const makeCurrentActiveSection = (sectionNumber) => {
    dispatch(currentActiveSection(sectionNumber));
  }

  const getLatestQuotationNumber = async () => {
    const quotationQ = new Parse.Query(Parse.Object.extend('Quotations'));
    quotationQ.limit(5);
    quotationQ.descending('createdAt')
    const res = await quotationQ.find();
    if (res.length > 0) {
      const numberr = (parseInt(res[0]?.get('quotationNumber')) + 1).toString();
      dispatch(patchQuotationNumber(numberr));
    } else if (res.length === 0) {
      dispatch(patchQuotationNumber('7000'));
    }
  }

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    return reader.readAsDataURL(file);
  })

  const handleOnChange = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    var reader = new FileReader();
    reader.readAsBinaryString(file);
    let fileDATA = "";
    reader.onload = function () {
      fileDATA = btoa(reader.result);
    };
    reader.onerror = function () {
      console.log('there are some problems');
    };
    const fileURI = await fileToDataUri(file);
    var base64 = fileURI;
    var parseFile = new Parse.File(`${file.name.split('.')[0].replace(/ *\([^)]*\) */g, "").trim()}`, { uri: base64 });
    setBase64FileData(fileDATA);
    setQuotationFile(parseFile);
  };

  const parseEmails = (input) => {
    // Split the input string into an array based on commas
    const emailsArray = input.split(',');

    // Trim white spaces from each email address and store them in a new array
    const trimmedEmailsArray = emailsArray.map(function (email) {
      return email.trim();
    });

    return trimmedEmailsArray;
  }

  useEffect(() => {
    if (revisionInProgress) {
      getQuotationRevisions();
    }
  }, [revisionInProgress]);

  const saveCurrentQuotationProgress = async () => {
    setWaitForQuotationSend(true);
    const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations'));
    quotationsQuery.equalTo('quotationNumber', quotationNumber);
    const existingQuote = await quotationsQuery.find();
    const quotation = existingQuote.length > 0 ? existingQuote[0] : new Parse.Object("Quotations");
    if (!existingQuote.length > 0) {
      const relCol = quotation.relation('cantileverColumn')
      const relBase = quotation.relation('cantileverBase')
      const relArm = quotation.relation('cantileverArm')
      const relDiagonal = quotation.relation('cantileverBraceDiagonal')
      const relHorizontal = quotation.relation('cantileverBraceHorizontal')
      const quotationQuantities = {};
      cartItemsGlobal.forEach(element => {
        if (element.part.get('name') === COLUMN_NAME) {
          relCol.add(element.part);
          quotation.set('cantileverColumnQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === BASE_NAME) {
          relBase.add(element.part);
          quotation.set('cantileverBaseQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === ARM_NAME) {
          relArm.add(element.part);
          quotation.set('cantileverArmQuantity', element.quantity);
          quotationQuantities[element.part.id] = element.quantity;
        }
        else if (element.part.get('name') === BRACE_NAME) {
          if (element.part.get('bayCenter').split(' ')[0] === 'Diagonal') {
            relDiagonal.add(element.part);
            quotation.set('cantileverBraceDiagonalQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
          else {
            relHorizontal.add(element.part);
            quotation.set('cantileverBraceHorizontalQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
        }
      });
      quotation.set('quotationQuantities', quotationQuantities);
    }
    quotation.set('createdBy', Parse.User.current());
    quotation.set('updatedBy', Parse.User.current());
    quotation.set('cart', props.cartItems);
    quotation.set('state', [cartSection]);
    quotation.set('status', quotationStatus);
    quotation.set('cartItemsState', [JSON.stringify(cartItemsGlobal)]);
    quotation.set('quotationStatus', quotationStatus);
    quotation.set('quotationType', selectedQuotationType);
    quotation.set('selectedClient', { data: selectedClient?.parseObject.toJSON() });
    quotation.set('totalPrice', ((overallTotal + (overallTotal / 100 * 10)).toFixed(2)).toString());
    quotation.set('miscParts', miscCartItems);
    quotation.set('quotationParts', allQuotationPartsToSave);
    quotation.set('quotationNumber', quotationNumber);
    quotation.set('quotationMargin', marginPercentage);
    quotation.set('quotationDiscount', discountPercentage);
    quotation.set('marginEnabled', marginEnabled);
    quotation.set('discountEnabled', discountEnabled);
    quotation.set('winProbability', selectedPercentageToWin);
    quotation.set('sections', sections);
    try {
      await quotation.save();
      setSelectedQuotationType([])
      setWaitForQuotationSend(false);
      dispatch(emptyCart());
      dispatch(resetState());
      setLocalSelectedClient();
      toast.success("Quotation Successfully Saved");
    } catch (error) {
      setWaitForQuotationSend(false);
      console.log('error: ', error);
      toast.error('Something went wrong, ', error);
    }
  }

  const downloadQuotationPDFSavesQuotation = async () => {
    if (!revisionInProgress) {
      const quotationsQuery = new Parse.Query(Parse.Object.extend('Quotations'));
      quotationsQuery.equalTo('quotationNumber', quotationNumber);
      const existingQuote = await quotationsQuery.find();
      const quotation = existingQuote.length > 0 ? existingQuote[0] : new Parse.Object("Quotations");
      if (!existingQuote.length > 0) {
        const relCol = quotation.relation('cantileverColumn')
        const relBase = quotation.relation('cantileverBase')
        const relArm = quotation.relation('cantileverArm')
        const relDiagonal = quotation.relation('cantileverBraceDiagonal')
        const relHorizontal = quotation.relation('cantileverBraceHorizontal')
        const quotationQuantities = {};
        cartItemsGlobal.forEach(element => {
          if (element.part.get('name') === COLUMN_NAME) {
            relCol.add(element.part);
            quotation.set('cantileverColumnQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
          else if (element.part.get('name') === BASE_NAME) {
            relBase.add(element.part);
            quotation.set('cantileverBaseQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
          else if (element.part.get('name') === ARM_NAME) {
            relArm.add(element.part);
            quotation.set('cantileverArmQuantity', element.quantity);
            quotationQuantities[element.part.id] = element.quantity;
          }
          else if (element.part.get('name') === BRACE_NAME) {
            if (element.part.get('bayCenter').split(' ')[0] === 'Diagonal') {
              relDiagonal.add(element.part);
              quotation.set('cantileverBraceDiagonalQuantity', element.quantity);
              quotationQuantities[element.part.id] = element.quantity;
            }
            else {
              relHorizontal.add(element.part);
              quotation.set('cantileverBraceHorizontalQuantity', element.quantity);
              quotationQuantities[element.part.id] = element.quantity;
            }
          }
        });
        quotation.set('quotationQuantities', quotationQuantities);
      }
      quotation.set('createdBy', Parse.User.current());
      quotation.set('updatedBy', Parse.User.current());
      quotation.set('cart', props.cartItems);
      quotation.set('state', [cartSection]);
      quotation.set('status', quotationStatus);
      quotation.set('cartItemsState', [JSON.stringify(cartItemsGlobal)]);
      quotation.set('quotationStatus', quotationStatus);
      quotation.set('quotationType', selectedQuotationType);
      quotation.set('selectedClient', { data: selectedClient?.parseObject.toJSON() });
      quotation.set('totalPrice', ((overallTotal + (overallTotal / 100 * 10)).toFixed(2)).toString());
      quotation.set('miscParts', miscCartItems);
      quotation.set('quotationParts', allQuotationPartsToSave);
      quotation.set('quotationNumber', quotationNumber);
      quotation.set('quotationMargin', marginPercentage);
      quotation.set('quotationDiscount', discountPercentage);
      quotation.set('marginEnabled', marginEnabled);
      quotation.set('discountEnabled', discountEnabled);
      quotation.set('winProbability', selectedPercentageToWin);
      quotation.set('sections', sections);
      try {
        await quotation.save();
        toast.success("Quotation Successfully Saved");
      } catch (error) {
        console.log('error: ', error);
      }
    }
  }

  const handleAccordionClick = () => {
    setExpanded(!expanded);
  };

  const overallTotal = calculateOverallTotal(sections);
  const sectionTotal = sections && sections[activeTab - 1] && calculateSectionTotal(sections[activeTab - 1]?.products, sections[activeTab - 1]?.marginPercentage, sections[activeTab - 1]?.discountPercentage)

  const handleMarginChange = (index, checked) => {
    setMarginEnabled((prevMarginEnabled) => ({
      ...prevMarginEnabled,
      [index]: checked,
    }));

    if (checked) {
      setDiscountEnabled((prevDiscEnabled) => ({
        ...prevDiscEnabled,
        [index]: false,
      }))
    } else {
      setDiscountEnabled(discountEnabled);
      handleSectionMarginChange(index - 1, 0)
    }
  };

  const handleDiscountChange = (index, checked) => {
    setDiscountEnabled((prevDiscountEnabled) => ({
      ...prevDiscountEnabled,
      [index]: checked,
    }));

    if (checked) {
      setMarginEnabled((prevMarginEnabled) => ({
        ...prevMarginEnabled,
        [index]: false,
      }));
    } else {
      setMarginEnabled(marginEnabled);
      handleSectionDiscountChange(index - 1, 0)
    }
  };

  const handleSectionMarginChange = (index, value) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, marginPercentage: parseFloat(value), discountPercentage: null } : section
      )
    );
  };

  useEffect(() => {
    console.log('sections: ',sections);
  }, [sections]);

  const handleSectionDiscountChange = (index, value) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === index ? { ...section, discountPercentage: parseFloat(value), marginPercentage: null } : section
      )
    );
  };

  const getQuotationRevisions = async () => {
    if (quotation) {
      const quotationRevisions = await quotation
        .relation("revisions")
        .query()
        .descending("createdAt")
        .find();
      if (quotationRevisions.length > 0) {
        setLatestQuotationRevisionNumber(
          quotationRevisions[0]?.get("quotationNumber")
        );
        return quotationRevisions[0]?.get("quotationNumber")
      } else {
        return ''
      }
    }
  };

  const getRevisionQuotationNumber = () => {
    if (latestQuotationRevisionNumber === "") {
      return quotationNumber + "-A"
    } else {
      const concatenatedRevisionAlphabet = latestQuotationRevisionNumber.split("-")[1];
      const nextAlphabet = getNextStringId(concatenatedRevisionAlphabet);
      return quotationNumber + "-" + nextAlphabet
    }
  }

  const createRevision = async (e) => {
    setWaitForQuotationSend(true)
    const quotationRevisionObj = new Parse.Object("QuotationRevisions");
    const latestRevisionNo = await getQuotationRevisions()
    const quotationQuantities = {};
    quotationRevisionObj.set("createdBy", quotation?.get("createdBy"));
    quotationRevisionObj.set("updatedBy", Parse.User.current());
    quotationRevisionObj.set("quotationQuantities", quotationQuantities);
    quotationRevisionObj.set('client', quotation.get('client'));
    quotationRevisionObj.set('miscParts', miscCartItems);
    quotationRevisionObj.set('selectedClient', quotation.get('selectedClient'));
    quotationRevisionObj.set('quotationParts', allQuotationPartsToSave);
    quotationRevisionObj.set('quotationType', quotation?.get("quotationType"));
    quotationRevisionObj.set('state', [cartSection]);
    quotationRevisionObj.set('quotationMargin', marginPercentage);
    quotationRevisionObj.set('quotationDiscount', discountPercentage);
    quotationRevisionObj.set('marginEnabled', marginEnabled);
    quotationRevisionObj.set('discountEnabled', discountEnabled);
    quotationRevisionObj.set('sections', sections);
    quotationRevisionObj.set('totalPrice', ((overallTotal + (overallTotal / 100 * 10)).toFixed(2)).toString());

    if (latestRevisionNo === "") {
      quotationRevisionObj.set(
        "quotationNumber",
        quotation.get("quotationNumber") + "-A"
      );
    } else {
      const concatenatedRevisionAlphabet =
        latestRevisionNo?.split("-")[1];
      const nextAlphabet = getNextStringId(concatenatedRevisionAlphabet);
      quotationRevisionObj.set(
        "quotationNumber",
        quotation.get("quotationNumber") + "-" + nextAlphabet
      );
    }

    try {
      await quotationRevisionObj.save();
      const quotationRelationRevision = quotation.relation("revisions");
      quotationRelationRevision.add(quotationRevisionObj);
      quotation.set("updatedBy", Parse.User.current());
      await quotation.save();
      await Parse.Cloud.run('sendMail', {
        quotationId: quotation.id,
        fileName: fileName.split('.')[0].replace(/ *\([^)]*\) */g, "").trim(),
        sender: Parse.User.current().get('email'),
        salesPerson: JSON.stringify(Parse.User.current()),
        quotationTypes: selectedQuotationType,
        salesRep: Parse.User.current().get('firstName') + ' ' + Parse.User.current().get('lastName'),
        recepient: selectedClient.parseObject.get('email'),
        clientName: selectedClient.parseObject.get('firstName').trim() + ' ' + selectedClient.parseObject.get('lastName').trim(),
        base64FileData: base64FileData,
        emailCCList: parseEmails(emailCC),
        emailBodyText: emailBodyText
      });
      setSelectedQuotationType([])
      setWaitForQuotationSend(false);
      setQuotationFile(undefined);
      toast.success('Revision successfully sent!');
      dispatch(emptyCart());
      dispatch(resetState());
      setLocalSelectedClient();
      setWaitForQuotationSend(false)
    } catch (error) {
      toast.error(error);
      setWaitForQuotationSend(false)
    }
  };

  return (
    <Box
      gridColumn="span 6"
      pb="0.5em"
      height="90vh"
    >
      <ToastContainer />

      {!waitForQuotationSend ? (
        <>
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={handleAccordionClick}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box>
                  Quotation Metadata - client
                  <Typography
                    variant="p"
                    fontWeight="400"
                    color={colors.greenAccent[400]}
                    mx={1}
                  >
                    {selectedClient?.parseObject?.get("companyName")}
                  </Typography>
                  - Quote No.
                  <Typography
                    variant="p"
                    fontWeight="400"
                    color={colors.greenAccent[400]}
                    ml={1}
                  >
                    {quotationNumber}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "5px 20px",
                    }}
                    disabled={selectedQuotationType.length === 0}
                    onClick={saveCurrentQuotationProgress}
                  >
                    Save For Later
                  </Button>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {!registerClient ? (
                <>
                  <CreateQuotationNewOrExisting
                    emailCC={emailCC}
                    setEmailCC={setEmailCC}
                    quotationStatus={quotationStatus}
                    setQuotationStatus={setQuotationStatus}
                    selectedClient={selectedClient}
                    setValidateQuotationNumber={setValidateQuotationNumber}
                  />
                  <Box display={"flex"} justifyContent={"space-around"}>
                    <Box>
                      <Typography
                        variant="h6"
                        fontWeight="400"
                        sx={{ padding: "10px 10px 0 10px" }}
                        my="0.25em"
                        color={colors.greenAccent[400]}
                      >
                        Create a new client?
                      </Typography>
                      <Box>
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "5px 20px",
                          }}
                          onClick={() => setRegisterClient(true)}
                        >
                          Create Client
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <Typography
                          variant="h6"
                          fontWeight="400"
                          sx={{ padding: "10px 10px 0 10px" }}
                          my="0.25em"
                          color={colors.greenAccent[400]}
                        >
                          Start/ Continue Quotation?
                        </Typography>
                        <Button
                          sx={{
                            backgroundColor: colors.greenAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "5px 20px",
                          }}
                          onClick={() => navigate("/create-quotation")}
                        >
                          Go to Products Page
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <RegisterClient
                  setRegisterClient={setRegisterClient}
                  setSelectClient={setSelectClient}
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel2-header"
            >
              Quotation Products
            </AccordionSummary>
            <AccordionDetails>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                // backgroundColor={colors.primary[400]}
                alignItems="center"
                width="98%"
                mx="0.5em"
                position="relative"
              >
                <div className="w-100 d-flex justify-content-start flex-wrap align-items-start">
                  {cartSectionsCount.map((section, index) => (
                    <Tab
                      tabIndex={index}
                      key={index}
                      label={`Section ${index + 1}`}
                      active={activeTab === index + 1}
                      onClick={() => handleTabClick(index + 1)}
                      enableDeletion={true}
                    />
                  ))}
                  <IconButton
                    className="p-0"
                    onClick={() => addSection()}
                    data-tip="msg to show"
                    data-for="section"
                    data-place="bottom"
                  >
                    <AddCircleOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                  <ReactTooltip id="section" effect="solid" place="bottom">
                    Add Another Section
                  </ReactTooltip>
                </div>

                <Box className="quotation-cart-items w-100">
                  <p
                    className={`cursor-pointer m-2 text-${cartSection.currentActive === activeTab
                      ? "success"
                      : "primary"
                      }`}
                  >
                    Section {activeTab}
                  </p>
                  {cartItemsGlobal?.map(
                    (item, i) =>
                      item.section === activeTab && (
                        <QuotationCartItem key={i} item={item} />
                      )
                  )}

                  {/* MISC PARTS HERE */}

                  {miscCartItems.length > 0 && (
                    <>
                      <Typography
                        variant="h5"
                        fontWeight="700"
                        my="0.25em"
                        mb="2em"
                        color={colors.greenAccent[400]}
                      >
                        Misc Cart Items
                      </Typography>
                      {miscCartItems.map((miscPart, i) => {
                        return (
                          miscPart.section === activeTab && (
                            <MiscCartItem key={i} part={miscPart} />
                          )
                        );
                      })}
                    </>
                  )}
                </Box>
                <div className="w-100 quotation-cart-items-total mt-2 py-2">
                  <Box ml={3} my={2} display={'flex'}>
                    {cartSectionsCount.map((section, index) => (

                      <Tab
                        tabIndex={index}
                        key={index}
                        label={`Section ${index + 1}`}
                        active={activeTab === index + 1}
                        onClick={() => handleTabClick(index + 1, true)}
                        enableDeletion={true}
                      />
                    ))}
                  </Box>
                  <div className="d-flex justify-content-between align-items-center px-3">
                    <div className="d-flex align-items-center">
                      <GreenSwitch
                        checked={sectionTotalsEnabled}
                        onChange={(e) => {
                          setSectionTotalsEnabled(e.target.checked);
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={false}
                      />
                      <label>Section Totals</label>
                    </div>
                  </div>
                  {sections?.map((section, index) => {
                    return activeTab === index + 1 && <>
                      <div key={index} className="d-flex justify-content-between align-items-center px-3">
                        <div className="d-flex align-items-center">
                          <GreenSwitch
                            checked={marginEnabled[index + 1] || false}
                            onChange={(e) => handleMarginChange(index + 1, e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={false}
                          />
                          <label>MARGIN</label>
                        </div>
                        {marginEnabled[index + 1] && (
                          <InputBase
                            type="number"
                            placeholder="margin %"
                            className="outlined border rounded px-2 my-2"
                            name="title"
                            value={section.marginPercentage || 0}
                            onChange={(e) => handleSectionMarginChange(index, e.target.value)}
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center px-3">
                        <div className="d-flex align-items-center">
                          <GreenSwitch
                            checked={discountEnabled[index + 1] || false}
                            onChange={(e) => handleDiscountChange(index + 1, e.target.checked)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            disabled={false}
                          />
                          <label>DISCOUNT</label>
                        </div>
                        {discountEnabled[index + 1] && (
                          <InputBase
                            type="number"
                            placeholder="discount %"
                            className="outlined border rounded px-2 my-2"
                            name="title"
                            value={section.discountPercentage || 0}
                            onChange={(e) => handleSectionDiscountChange(index, e.target.value)}
                          />
                        )}
                      </div>
                      <div id="section-total" className="d-flex justify-content-between px-3" ref={bottomRef}>
                        <p className="q-color">SECTION TOTAL: </p>
                        <p className="q-color">
                          $
                          {sectionTotal?.toFixed(2)}
                        </p>
                      </div>
                    </>
                  }
                  )}
                  <div className="d-flex justify-content-between px-3">
                    <p className="q-color">TOTAL: </p>
                    <p className="q-color">
                      $
                      {overallTotal.toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between px-3">
                    <p className="q-color">GST: </p>
                    <p className="q-color">
                      $
                      {(overallTotal / 100 * 10).toFixed(2)}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between px-3">
                    <p className="q-color">NET TOTAL: </p>
                    <p className="q-color">
                      $
                      {(overallTotal + (overallTotal / 100 * 10)).toFixed(2)}
                    </p>
                  </div>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel3-header"
            >
              Quotation Config
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div className="text-center">
                  <div>
                    <Button
                      sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "5px 20px",
                        mt: "1em",
                      }}
                      onClick={() => setQuotationEditTermsModal(true)}
                    >
                      Edit Terms For Quotation
                    </Button>
                  </div>
                  <DownloadQuotationBeforeSending
                    sectionTotalsEnabled={sectionTotalsEnabled}
                    quotationNumber={!revisionInProgress ? quotationNumber : getRevisionQuotationNumber()}
                    quotationTerms={quotationTerms}
                    client={selectedClient?.parseObject}
                    quotationParts={cartItemsGlobal}
                    miscCantileverItems={miscCartItems}
                    salesRep={Parse.User.current()}
                    quotationType={selectedQuotationType}
                    overallTotal={overallTotal}
                    sections={sections}
                    quotationSections={cartSection.sectionCount}
                    downloadQuotationPDFSavesQuotation={downloadQuotationPDFSavesQuotation}
                  />
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={quotationEditTermsModal}
                    onClose={() => setQuotationEditTermsModal(false)}
                    closeAfterTransition
                  >
                    <Fade in={quotationEditTermsModal}>
                      <Box sx={modalStyle}>
                        <Typography
                          variant="h5"
                          fontWeight="600"
                          className="text-center"
                          color={colors.greenAccent[400]}
                        >
                          Edit Quotation Terms
                        </Typography>
                        <Typography
                          id="transition-modal-description"
                          className="secondary-color text-center"
                          sx={{ mt: 2 }}
                        >
                          <div>
                            <label className="mt-3">Term 1: </label>
                          </div>
                          <div>
                            <InputBase
                              className="w-100 border rounded p-2"
                              value={quotationTerms[1]}
                              onChange={(e) => {
                                const copy = Object.assign({}, quotationTerms);
                                copy[1] = e.target.value;
                                setQuotationTerms(copy);
                              }}
                            ></InputBase>
                          </div>
                          <div>
                            <label className="mt-3">Term 2: </label>
                          </div>
                          <div>
                            <InputBase
                              className="w-100 border rounded p-2"
                              value={quotationTerms[2]}
                              onChange={(e) => {
                                const copy = Object.assign({}, quotationTerms);
                                copy[2] = e.target.value;
                                setQuotationTerms(copy);
                              }}
                            ></InputBase>
                          </div>
                          <div>
                            <label className="mt-3">Term 3: </label>
                          </div>
                          <div>
                            <TextField
                              multiline
                              rows={2}
                              maxRows={4}
                              className="w-100 p-2"
                              value={quotationTerms[3]}
                              onChange={(e) => {
                                const copy = Object.assign({}, quotationTerms);
                                copy[3] = e.target.value;
                                setQuotationTerms(copy);
                              }}
                            ></TextField>
                          </div>
                          <div>
                            <label className="mt-3">Term 4: </label>
                          </div>
                          <div>
                            <TextField
                              multiline
                              rows={2}
                              maxRows={4}
                              className="w-100 p-2"
                              value={quotationTerms[4]}
                              onChange={(e) => {
                                const copy = Object.assign({}, quotationTerms);
                                copy[4] = e.target.value;
                                setQuotationTerms(copy);
                              }}
                            ></TextField>
                          </div>
                        </Typography>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          mt="1em"
                        >
                          <Button
                            sx={{
                              backgroundColor: colors.blueAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px",
                            }}
                            onClick={() => setQuotationEditTermsModal(false)}
                          >
                            Cancel
                          </Button>

                          <Button
                            sx={{
                              backgroundColor: colors.blueAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "10px 20px",
                            }}
                            onClick={() => setQuotationEditTermsModal(false)}
                          >
                            Confirm
                          </Button>
                        </Box>
                      </Box>
                    </Fade>
                  </Modal>
                  <Box>
                    {
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={makeQuotationOnlyEnabled}
                        onClose={() => setMakeQuotationOnlyEnabled(false)}
                        closeAfterTransition
                      >
                        <Fade in={makeQuotationOnlyEnabled}>
                          <Box sx={modalStyle}>
                            <Typography
                              variant="h5"
                              fontWeight="600"
                              className="text-center"
                              color={colors.greenAccent[400]}
                            >
                              Add Quotation Total
                            </Typography>
                            <Box
                              display="flex"
                              justifyContent="space-around"
                              alignItems="center"
                              flexDirection="column"
                            >
                              <Typography fontSize={30}>Total</Typography>
                              <InputBase
                                placeholder="Total"
                                className="outlined border rounded px-2 mx-1"
                                name="total"
                                value={onlyQuotaionTotal}
                                onChange={(e) =>
                                  setOnlyQuotationTotal(
                                    parseInt(e.target.value)
                                  )
                                }
                                type="number"
                                sx={{
                                  flex: 1,
                                  width: "150px",
                                  height: "200px",
                                }}
                              />
                              <Typography className="mt-3" fontSize={30}>
                                GST
                              </Typography>
                              <Typography fontSize={35}>
                                {Math.round(onlyQuotaionTotal * 10) / 100}
                              </Typography>

                              <Typography fontSize={30}>Net Total</Typography>
                              <Typography
                                sx={{ textDecoration: "underline" }}
                                fontSize={35}
                              >
                                {parseFloat(
                                  onlyQuotaionTotal +
                                  parseFloat(onlyQuotaionTotal * 10) / 100
                                )}
                              </Typography>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              mt="1em"
                            >
                              <Button
                                sx={{
                                  backgroundColor: colors.blueAccent[700],
                                  color: colors.grey[100],
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  padding: "10px 20px",
                                }}
                                onClick={() =>
                                  setMakeQuotationOnlyEnabled(false)
                                }
                              >
                                Cancel
                              </Button>

                              <Button
                                sx={{
                                  backgroundColor: colors.blueAccent[700],
                                  color: colors.grey[100],
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  padding: "10px 20px",
                                }}
                                onClick={() => makeQuotationOnly()}
                              >
                                Confirm
                              </Button>
                            </Box>
                          </Box>
                        </Fade>
                      </Modal>
                    }
                    <Button
                      sx={{
                        backgroundColor: colors.greenAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "5px 20px",
                        mt: "1em",
                      }}
                      disabled={
                        cartItemsGlobal.length > 0 ||
                        selectedQuotationType.length === 0
                      }
                      onClick={() => setMakeQuotationOnlyEnabled(true)}
                    >
                      {!waitForQuotationSend ? (
                        "Make Quotation"
                      ) : (
                        <Spinner
                          animation="border"
                          role="status"
                          size="sm"
                          variant="primary"
                        />
                      )}
                    </Button>
                  </Box>
                  <Box
                    p={"5px"}
                    border="1px solid grey"
                    borderRadius="4px"
                    mt="10px"
                  >
                    <input
                      className="btn btn-secondary my-3"
                      type={"file"}
                      id={"pdfFileInput"}
                      accept={".pdf"}
                      onChange={handleOnChange}
                    />
                  </Box>

                  {selectedClient && selectedQuotationType.length > 0 && (
                    <Box
                      p={"5px"}
                      border="1px solid grey"
                      borderRadius="4px"
                      mt="10px"
                    >
                      <EmailBody
                        emailBodyText={emailBodyText}
                        setEmailBodyText={setEmailBodyText}
                      />
                    </Box>
                  )}

                  <Button
                    sx={{
                      backgroundColor: colors.greenAccent[700],
                      color: colors.grey[100],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "5px 20px",
                      mt: "1em",
                    }}
                    disabled={
                      (cartItemsGlobal.length === 0 &&
                        miscCartItems.length === 0) ||
                      file === undefined ||
                      waitForQuotationSend ||
                      selectedQuotationType.length === 0
                    }
                    onClick={() => { revisionInProgress ? createRevision() : saveAndSendQuotation() }}
                  >
                    {!waitForQuotationSend ? (
                      "Send Quotation"
                    ) : (
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        variant="primary"
                      />
                    )}
                  </Button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <div className="coffee">
            <ul>
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div className="cup">
              <span></span>
            </div>
          </div>
        </Box>
      )}
    </Box>
  );
};

export default Calendar;
