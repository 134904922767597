import React, { useEffect, useState } from "react";
import Parse from "parse";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { quotationTypes } from "../scenes/calendar/calendar";
import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedQuotationType } from "../features/cart/cartSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectQuotationType = (props) => {
  const selectedQuotationType = useSelector((state) => state.cart.quotationType);
  const dispatch = useDispatch();

  return (
    <FormControl style={{width: '100%'}} className="text-center">
        <InputLabel id="demo-simple-select-label">Type</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={selectedQuotationType}
            label="Finish"
            onChange={(event) => dispatch(setSelectedQuotationType(event.target.value))}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            sx={{ width: 300 }}
        >
            {quotationTypes.map((type, index) => 
              <MenuItem key={index} value={type}>
                <Checkbox checked={selectedQuotationType?.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>)
            }
        </Select>
    </FormControl>  
  );
};

export default SelectQuotationType;
