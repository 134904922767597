import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Backdrop, Box, Button, Fade, IconButton, InputBase, Modal, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { BRACE_NAME } from "./CantileverPart";
import { updatePickListQuotationParts } from "../features/cart/cartSlice";

const PicklistPart = (props) => {
	const theme = useTheme();
    const dispatch = useDispatch();
    const pickListQuotationParts = useSelector((state) => state.cart.pickListQuotationParts);
	const colors = tokens(theme.palette.mode);
    const part = props.part;
    const picklistDocType = props.picklistDocType;
    const quotationParts = props.quotationParts;
    const setQuotationPartsUpdated = props.setQuotationPartsUpdated;
    const [partQuantity, setPartQuantity] = useState(part.quantity);
    
    const handleQuantityChange = (e) => {
        setPartQuantity(e.target.value);
        const quotationPartsCopy = [...pickListQuotationParts];
        const targetIndex = pickListQuotationParts.findIndex(f=>f.partID === part.partID); 
        const updatedObj = {...quotationPartsCopy[targetIndex]}
        updatedObj["quantity"] = parseInt(e.target.value)
        quotationPartsCopy[targetIndex] = updatedObj;
        dispatch(updatePickListQuotationParts([...quotationPartsCopy]));
    }

	return (
        <Box display='flex' justifyContent='space-between' alignItems='center' mt='0.5em'>
            {picklistDocType === "internal" && part.inventoryQuantity - part.quantity > 0 && <>
                <Typography>{part?.className === 'CantileverParts' ?
                <Typography>{part?.name} - {" "}{part?.name === BRACE_NAME && part?.layout}{" "}{part?.width} wide -{" "}{part?.height} high -{" "}{part?.depth} deep</Typography> :
                <Typography>{part?.name} {part?.description}</Typography>}</Typography>
                    <InputBase
                        data-tip
                        data-for="title"
                        placeholder="Quote Number"
                        className="outlined border rounded px-2 mx-1"
                        name="title"
                        value={partQuantity}
                        onChange={handleQuantityChange}
                        onBlur={() => {}}
                        sx={{ width: "50px" }}
                    />
            </>}

            {picklistDocType === "external" && part.inventoryQuantity - part.quantity < 0 && <>
                <Typography>{part?.className === 'CantileverParts' ?
                <Typography>{part?.name} - {" "}{part?.name === BRACE_NAME && part?.layout}{" "}{part?.width} wide -{" "}{part?.height} high -{" "}{part?.depth} deep</Typography> :
                <Typography>{part?.name} {part?.description}</Typography>}</Typography>
                    <InputBase
                        data-tip
                        data-for="title"
                        placeholder="Quote Number"
                        className="outlined border rounded px-2 mx-1"
                        name="title"
                        value={partQuantity}
                        onChange={handleQuantityChange}
                        onBlur={() => {}}
                        sx={{ width: "50px" }}
                    />
            </>}
        </Box>
    );
};
export default PicklistPart;