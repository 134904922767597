import { InputBase } from "@mui/material";
import React, { useEffect, useState } from "react";

const EmailBody = (props) => {
  const emailBodyText = props.emailBodyText
  const setEmailBodyText = props.setEmailBodyText

  return (

    <InputBase
        fullWidth
        multiline
        rows={5}
        placeholder="Email Body"
        name="email"
        value={emailBodyText}
        onChange={(e) => setEmailBodyText(e.target.value)}
    />

  );
};

export default EmailBody;
