import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, useTheme } from "@mui/material";
import QuotationItemDetailComponent from "./QuotationItemDetailComponent";
import { tokens } from "../theme";
import Tab from "./TabComponent";
import RevisionAccordion from "./revisionAccordionComponent";

export default function SimpleAccordion(props) {
  const data = props.data;
  const quotationPartClass = props.quotationPartClass;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeSection, setActiveSection] = React.useState(1);
  const [totalPriceRelativeToSection, setTotalPriceRelativeToSection] = React.useState({});

  const handleTabClick = (tabIndex) => {
    setActiveSection(tabIndex);
  };

  const finalizeRevision = async (id) => {
    data.map(async (rev) => {
      if (rev.id === id) {
        rev.set('finalized', true);
        await rev.save();
      } else {
        rev.set('finalized', false);
        await rev.save();
      }
      window.location.reload();
    })
  };

  return (
    <div>
      {data.map((revision, index) => (
        <RevisionAccordion key={index} revision={revision} finalizeRevision={finalizeRevision}/>
      ))}
    </div>
  );
}
