import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import cantliverSet from '../../assets/images/cantileverRacking.png';
import palletRackingSet from '../../assets/images/palletRacking.png';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import Header from "../../components/Header";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  useEffect(() => {
    AuthService.getCurrentUser()
      .then((user) => {
        if (!user) {
          navigate("/register");
          AuthService.logout();
        }
      })
      .catch((err) => {
        console.log(err);
        navigate("/register");
        AuthService.logout();
      });
  }, []);


  return (
    <Box m="20px">

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Cantilever 
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent='space-around'
            mt="25px"
          >
            <Box
            display="flex"
            flexDirection='column'
            alignItems="center"
            >
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                mt="15px"
                className="cursor-pointer"
                onClick={() => navigate('/build-cantilever')}
              >
                Build Your Own Cantilever Set
              </Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
                mt="15px"
                className="cursor-pointer"
                onClick={() => navigate('/automate-cantilever')}
              >
                Automate Your Cantilever Set
              </Box>
            </Box>
            <img
                  alt="profile-user"
                  width="200px"
                  height="200px"
                  src={cantliverSet}
                  style={{ cursor: "pointer", borderRadius: '4px' }}
                />
          </Box>
        </Box>

        {/*  Pallet Racking */}

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
        >
          <Typography variant="h5" fontWeight="600">
            Pallet Racking
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent='space-around'
            mt="25px"
          >
            {/* <ProgressCircle size="125" /> */}
            <Box
              backgroundColor={colors.greenAccent[500]}
              p="5px 10px"
              borderRadius="4px"
              mt="15px"
              className="cursor-pointer"
              onClick={() => navigate('/build-palletracking')}
            >
              Build Your Own Pallet Racking Set
            </Box>
            <img
                  alt="profile-user"
                  width="200px"
                  height="200px"
                  src={palletRackingSet}
                  style={{ cursor: "pointer", borderRadius: '4px' }}
                />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
